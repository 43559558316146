import axios from 'axios';
import React, { useState } from "react";
import FileSaver from 'file-saver';
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "../../shared/components/DataTable";
import {
  GearIcon,
  PDFIcon,
  DownloadIcon,
  PDFDisableIcon,
  InfoIcon,
} from "../../shared/icons";
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  useToast,
  Link,
  ModalBody,
  ModalFooter,
  HStack,
  Checkbox,
  Select,
  Tooltip,

} from "@chakra-ui/react";
import ModalSkeleton from "@/shared/components/ModalSkeleton";
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import { useTranslation } from "react-i18next";
import { useIsMobile } from "@/hooks";
import ResponsiveText from '@/shared/components/ResponsiveText';
import { TOAST_POSITION, WEEK_DAY, STANDARD_ERROR_MSG, MONDAY } from "../../consts";


function dateFormatter(cell, row, type) {
  let result = ''
  let number_week = ''
  if (row.day_week > 4) {
    number_week = row.number_week - 1
  } else {
    number_week = row.number_week
  }

  // Importa la libreria moment.js
  const moment = require('moment');

  if (type === 'year') {
    result = row.year
  } else if (type === 'start') {
    const start_date = moment().year(row.year).isoWeek(number_week).startOf('isoWeek').day(row.day_week)
    return <ResponsiveText>{new Date(start_date).toDateString()}</ResponsiveText>
  } else if (type === 'end') {
    const end_date = moment().year(row.year).isoWeek(number_week).startOf('isoWeek').day(row.day_week).add(6, 'days')
    return <ResponsiveText>{new Date(end_date).toDateString()}</ResponsiveText>
  } else {
    result = row.number_week
  }
  return <ResponsiveText>{result}</ResponsiveText>;
}


function nameFormatter(cell, row, machine) {
  let name = `report_${machine}_${row.year}_${row.number_week}`
  return <ResponsiveText>{name}</ResponsiveText>;
}


export const MachinesTable = (props) => {
  const { onEditClick, user, mind_related, data, refetch } = props;
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const [reportInfo, setReportInfo] = useState()
  const { t } = useTranslation(['general', 'reports'])
  const isMobile = useIsMobile();
  const toast = useToast()
  const columnsProps = {
    id: { text: '', hidden: true, isKey: true },
    serial: {
      text: isMobile ? t('general:serial-no', 'Serial No.') : t('general:serial-number', 'Serial number'),
      sort: true, headerAlign: 'left', align: 'left',
      formatter: (cell, row) =>
        <Link to={`/reportfiles/${row.id}`} >
          <Text color='#5588D7' onClick={() => onEditClick(row)}>
            {row.serial}
          </Text>
        </Link>
    },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    mind: {
      text: 'mind'.toCapitalCase(),
      hidden: mind_related.length > 0 ? false : true,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => { return row.mind.mind_serial }
    },
    report_available: {
      text: 'report available'.toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    edit: {
      text: ''.toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => {
        return (
          <GearIcon
            cursor="pointer"
            fontSize={{ base: "xs", md: "md" }}
            onClick={() => {
              setReportInfo(row)
              refetch()
              onOpenEdit()
            }}
          />
        )
      }
    }
  };

  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <>
      <DataTable
        data={data}
        columns={columns}
        toolbar={(props) => (
          <Flex justify="space-between">
            <Box width="30%">
              <SearchBar searchProps={props.searchProps} />
            </Box>
          </Flex>
        )}
        dataTableProps={{
          sizePerPage: 10,
          noPagination: true,
        }}
      />
      <ModalSkeleton
        isOpen={isOpenEdit}
        onClose={() => onCloseEdit()}
        title={t('reports:edit-report', "Edit report")}
      >
        <ModalBody my={2}>
          <HStack w='full' >
            <Text fontSize="sm">{t('reports:start-day', 'Start day of week').toCapitalCase()}</Text>
            <Select w='50%' py={2} size="sm" value={reportInfo?.start_weekday ? reportInfo?.start_weekday : MONDAY} onChange={(e) => setReportInfo({ ...reportInfo, 'start_weekday': +e.target.value })}>
              {WEEK_DAY.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
            </Select>
          </HStack>
          <HStack>
            <Checkbox
              isChecked={reportInfo?.send_weekly}
              onChange={(e) => reportInfo?.send_weekly ? setReportInfo({ ...reportInfo, 'send_weekly': false }) : setReportInfo({ ...reportInfo, 'send_weekly': true })
              }
            >
              <Text fontSize="sm">{t("reports:enable_send_weekly", "Enable send email with report every week",).toCapitalCase()}</Text>
            </Checkbox>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onCloseEdit()} variant='secondary' mr={2} >
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end" variant='primary' onClick={() => {

              return axios
                .put(
                  `/api/minds/${reportInfo.mind.mind_id}/users/${user}/reports/${reportInfo.report}/`,
                  {
                    user: user,
                    machine: reportInfo.id,
                    start_weekday: reportInfo.start_weekday,
                    send_weekly: reportInfo.send_weekly,
                  }
                )
                .then((resp) => {
                  toast({
                    status: "success",
                    description: t(
                      'reports:reports-change-successfully',
                      'Reports has been change successfully!'
                    ),
                    position: TOAST_POSITION,
                    isClosable: true,
                  });
                  refetch();
                  onCloseEdit()
                })
            }
            }>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};




export const ReportsTable = (props) => {
  const { reports, machine } = props;
  const isMobile = useIsMobile()
  const { t } = useTranslation(['general'])
  const toast = useToast()
  const columnsProps = {
    id: { hidden: true, isKey: true },
    icon: {
      text: '',
      isDummyField: true,
      formatter: (cell, row) => row['available'] ? <PDFIcon fontSize={{ base: "xs", md: "md" }} /> : <PDFDisableIcon fontSize={{ base: "xs", md: "md" }} />
    },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => nameFormatter(cell, row, machine)
    },
    year: {
      text: 'Year',
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => dateFormatter(cell, row, 'year')
    },
    number_week: {
      text: 'Number of week',
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => dateFormatter(cell, row, 'week')
    },
    start: {
      text: 'Start date',
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => dateFormatter(cell, row, 'start')
    },
    end: {
      text: 'End date',
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => dateFormatter(cell, row, 'end')
    },
    download: {
      text: '',
      isDummyField: true,
      formatter: (cell, row) => row['available'] ? <DownloadIcon
        cursor='pointer'
        fontSize={{ base: "xs", md: "md" }}
        onClick={() => {
          axios.get('/api/get_report_pdf/', { params: { 'id': row.report_id, 'title': `report_${machine}_${row.year}_${row.number_week}.pdf` } })
            .then((resp) => {
              const blob = new Blob([resp.data], { type: 'pdf/plain' });
              FileSaver.saveAs(blob, `report_${machine}_${row.year}_${row.number_week}.pdf`);
            })
            .catch((reason) => {
              toast({
                status: 'error',
                description: STANDARD_ERROR_MSG,
                position: TOAST_POSITION
              });
              console.error(reason);
            })
        }}
      /> :
        <Tooltip label={t("general:no-data", "No data available")}>
          <InfoIcon fontSize="sm" ml={2} mb={1} />
        </Tooltip>
    },
  }
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <>
      {
        (!machine || reports.length === 0 || !(machine in reports)) ? <FullPageSpinner />
          :
          <DataTable
            data={reports[machine]}
            columns={columns}
            toolbar={(props) => (
              <Flex justify="space-between">
                <Box width="30%">
                  <SearchBar searchProps={props.searchProps} />
                </Box>
              </Flex>
            )}
            dataTableProps={{
              sizePerPage: 10,
            }}
          />
      }
    </>
  );
};
