import React from 'react'
import { useData } from "@/hooks";

const ReportsContext = React.createContext()

function ReportsProvider({ children, ...props }) {

  const { data, isFetching, isError, refetch } = useData({
    url: '/api/report_file/'
  })

  return (<ReportsContext.Provider value={{
    reports: data, isFetching, isError, refetch
  }} {...props}>
    {children}
  </ReportsContext.Provider>
  )
}


function useReports() {
  const context = React.useContext(ReportsContext)
  if (context === undefined) {
    throw new Error(`useReports must be used within a ReportsProvider`)
  }
  return context
}

export { ReportsProvider, useReports }