import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory, {
  PaginationProvider
} from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Stack, useColorMode} from '@chakra-ui/react';

import './DataTable.css';

import { SizePerPageRenderer, PaginationListRenderer } from './elements'

let totalData = [];

const DataTable = ({
  keyField = 'id',
  data,
  columns,
  selectRow,
  hiddenRows = [],
  toolbar = null,
  sizePerPage = 10,
  cellEditOptions = {},
  exportCSV = {},
  paginationProviderProps = {},
  toolkitProviderProps = {},
  tableProps = {},
  noDataIndication,
  customSizePerPage = 10,
  noPagination = false,
  _afterSearch=() => {},
  topPadding = true,
}) => {
  const sizePerPageList = [
    { text: '5', value: 5 },
    { text: '10', value: 10 },
    { text: '15', value: 15 },
    { text: '20', value: 20 },
    { text: '25', value: 25 },
    { text: '30', value: 30 },
    { text: '40', value: 40 },
    { text: '50', value: 50 }
  ];

  totalData = data;
  const [filteredDataSize, setFilteredDataSize] = useState(0)
  const {colorMode} = useColorMode()
  const [_sizePerPage, set_SizePerPage] = useState(sizePerPage)

  const pagination = !noPagination ? paginationFactory({
    sizePerPage,
    custom: false,
    totalSize: filteredDataSize,
    onSizePerPageChange: (s, page) => set_SizePerPage(s),
    sizePerPageRenderer: (...rest)  => <SizePerPageRenderer {...rest[0]} colorMode={colorMode} customSizePerPage={customSizePerPage}/>,
    sizePerPageList,
    // pageListRenderer: (...rest) => console.log(rest),
    pageListRenderer: (...rest) => <PaginationListRenderer paginationProps={
      {...rest[0], totalSize: filteredDataSize, sizePerPage: _sizePerPage}

    }
    showFirstPage={true}
    showLastPage={true}/>,
    ...paginationProviderProps
  }): paginationFactory({
    sizePerPage,
    hidePageListOnlyOnePage: true, 
    showTotal: false, 
    hideSizePerPage: true,
    custom: false,
    totalSize: filteredDataSize,
    pageListRenderer: (...rest) => null,
    ...paginationProviderProps
  });

  useEffect(() => {
    setFilteredDataSize(data.length)
    return () => {
      // cleanup global var
      totalData = []
    }
  }, [])


  const afterSearch = (newResult) => {
    setFilteredDataSize(newResult.length)
    _afterSearch(newResult, totalData)
  };

  const cellEdit = cellEditFactory({ ...cellEditOptions });

  return (
    <PaginationProvider pagination={pagination}>
      {({ paginationProps, paginationTableProps }) => (
        <>
          <ToolkitProvider
            search={{afterSearch}}
            data={data}
            keyField={keyField}
            columns={columns}
            exportCSV={exportCSV}
            {...toolkitProviderProps}
          >
            {(props) => (
              <div className={topPadding ? 'custom-container': ''}>
                {toolbar && toolbar(props)}
                <BootstrapTable
                  bordered={false}
                  cellEdit={cellEdit}
                  selectRow={selectRow}
                  hiddenRows={hiddenRows}
                  noDataIndication={noDataIndication}
                  classes={`custom-table ${colorMode}`}
                  ignoreSinglePage
                  {...tableProps}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      )}
    </PaginationProvider>
  );
};

export default DataTable;
