import React, { useEffect, useState } from 'react'
import { MainLayout } from "@/layouts";

import {
  Box, Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  CloseButton,
} from '@chakra-ui/react'

import { useUser } from '../../contexts/auth'
import { useHistory } from "react-router-dom";
import { MindsTable, VpnsTable } from "./elements";

import { useIsMobile, useData } from '../../hooks'
import { useTranslation } from 'react-i18next'

const ACCORDION_INDEX ={
  vpns: 0,
  machines: 1,
}

Object.freeze(ACCORDION_INDEX)

const Vpn = (props) => {

  const [selectedVpn, setSelectedVpn] = useState()
  const [index, setIndex] = useState(ACCORDION_INDEX.vpns);
  const history = useHistory()
  const user = useUser()
  const { t } = useTranslation(['general', 'minds'])

  const {data, isFetching, refetch} = useData({
    url:'/api/get_vpn_related_minds/',
    params: {selected:selectedVpn?.id}
  })

  useEffect(()=>{
    if (isFetching === false){
      const timerId = setTimeout(() => refetch(), 5000)
      return () => clearTimeout(timerId)
    }
  },[isFetching])

  return (
    <MainLayout heading="VPNs">
      <Accordion index={index}>
        <AccordionItem border='none'>
          <Flex>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {selectedVpn ? (
                  <Box alignSelf="center">
                    <b>Vpn</b>
                    {` - ${selectedVpn.name} (${selectedVpn.udp} - ${selectedVpn.tcp})`}
                  </Box>
                ) : (
                  <b>Vpns</b>
                )}
              </Box>
            </AccordionButton>
            {selectedVpn && (
              <CloseButton
                alignSelf="center"
                onClick={() => {
                  setIndex(ACCORDION_INDEX.vpns);
                  setSelectedVpn();
                  history.push(`/manage/vpn`);
                  refetch()
                }}
              />
            )}
          </Flex>
          <AccordionPanel py={6}>
            <VpnsTable
              onEditClick={(vpn) => {
                setSelectedVpn(vpn);
                setIndex(ACCORDION_INDEX.machines);
                history.push(`/manage/vpn`)
                refetch()
              }}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <b>MiNDs</b>
            </Box>
          </AccordionButton>
          <AccordionPanel py={6}>
            {selectedVpn &&
              <MindsTable 
                minds={data['vpn_related_minds']}
                minds_not_vpn={data['minds_without_vpn']}
                vpn = {selectedVpn.id}
                user = {user}
                isFetching = {isFetching}
                refetch = {refetch} />
            }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </MainLayout>

  )
}
export default Vpn;