import React from 'react';
import ReactSelect from 'react-select';
import { useColorMode } from '@chakra-ui/react';
import { reactSelectDarkColors } from '../../shared/theme';

/**
 * Create custom Multiselect for filter groups
 *
 * @param {Object[]} groups
 * @param {boolean} hidden - Check if display or not the multiselect
 * @param {function} onChange - Function to apply when values change
 */
const GroupFilter = ({options=[], hidden, onChange, ...rest}) => {
  if (hidden){
    return null
  }

  const {colorMode}  = useColorMode()

  return (
      <ReactSelect
        closeMenuOnSelect={false}
        styles={{
          container: (provided, state) => ({
              ...provided,
              padding: 0,
              height: 'fit-content',
              zIndex: 4,
              fontSize: 'var(--chakra-fontSizes-sm)'
          }),
          control: (provided, state) => ({
              ...provided,
              width: '240px',
              borderWidth: 0,
              minHeight: 'fit-content',
              height: 'fit-content',
              fontSize: 'var(--chakra-fontSizes-sm)'
          }),
          indicatorsContainer: (provided, state) => ({
              ...provided,
              height: '30px',
              fontSize: 'var(--chakra-fontSizes-sm)'
          }),
          input: (provided, state) => ({
              ...provided,
              height: '23px'
          }),
          margin: 'auto'

        }}
      color='white'
      borderRadius='md' borderWidth={2}      
      theme={colorMode === 'dark' && { colors: reactSelectDarkColors }}
      options={options}
      onChange={onChange}
      isMulti
      {...rest}
    />


  );
}

export default GroupFilter;