import { Text, Tooltip } from "@chakra-ui/react";
import React from "react";

const ResponsiveText = ({ compress = false, children, label=null, tooltipProps={}, ...rest }) => {
  if (compress === false) {
    return (
      <Text fontSize={{ base: "xs", md: "md" }} {...rest}>
        {children}
      </Text>
    );
  }
  return (
    <Tooltip label={label? label : children} {...tooltipProps}>
      <Text
        fontSize={{ base: "xs", md: "md" }}
        css={
          compress && {
            display: "block",
            width: compress,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }
        }
        {...rest}
      >
        {children}
      </Text>
    </Tooltip>
  );
};

export default ResponsiveText;
