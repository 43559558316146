import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  CloseButton,
  Flex
} from "@chakra-ui/react";
import { MainLayout } from "../../layouts";
import { MachinesTable, MindsTable, UsersTable } from "./elements";
import { useHistory } from "react-router-dom";
import { useUser } from "../../contexts/auth";
import { ArrowLoop } from "../../shared/icons";
import { useUsers } from "../../contexts/users";
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import { INSTALLER } from "../../consts";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import {Button} from '@/shared/components/core/Button'
import { ACCORDION_NOTIF_REPO_INDEX} from "../../consts";

const Notifications = () => {
  const [selectedMind, setSelectedMind] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [index, setIndex] = useState(ACCORDION_NOTIF_REPO_INDEX.minds);
  const [simpleMode, setSimpleMode] = useState(true)
  const history = useHistory();
  const user = useUser()
  const {isFetching: isFetchingUsers } = useUsers()
  const canView = user.level.id >= INSTALLER && user.frigel_branch !== 'EXT'
  const {t} = useTranslation(['notifications'])

  useEffect(() => {
    setSelectedUser(user)
  }, [])

  return (
    <MainLayout heading={t('general:Notifications', 'Notifications')}>
      {canView &&
        <Flex justifyContent='flex-end'>
          <Button alignSelf='flex-end' size='xs' variant='secondary'
            leftIcon={<ArrowLoop fontSize='lg' mr={-1} />}
              onClick={() => {
                const nextSimpleMode =  !simpleMode
                setSimpleMode(nextSimpleMode)

                if (nextSimpleMode === true ){
                  setSelectedUser(user)

                  if (index === ACCORDION_NOTIF_REPO_INDEX.users){
                    // it's in users go to machines
                    setIndex(ACCORDION_NOTIF_REPO_INDEX.machines)
                  }
                  if (index === ACCORDION_NOTIF_REPO_INDEX.machines){
                    history.replace(`/manage/notifications/${selectedMind.id}/${user.profile_id}`)
                  }
                } else {

                  setSelectedUser(undefined)
                  if (index === ACCORDION_NOTIF_REPO_INDEX.machines){
                    // it's in machines go back to users
                    setIndex(ACCORDION_NOTIF_REPO_INDEX.users)
                  }
                }

              }}
            >
              {startCase(t('general:switch-to', 'switch to'))} {simpleMode
               ? startCase(t('notifications:advance-mode', 'advance mode'))
               : startCase(t('notifications:simple-mode', 'simple mode'))}
          </Button>
        </Flex>
      }
      <Accordion index={index}>
        <AccordionItem border='none'>
          <Flex>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {selectedMind ? (
                  <Box alignSelf="center">
                    <b>MiND</b>
                    {` - [${selectedMind.serial}]  ${selectedMind.company_name}`}
                  </Box>
                ) : (
                  <b>MiNDs</b>
                )}
              </Box>
            </AccordionButton>
            {selectedMind && (
              <CloseButton
                alignSelf="center"
                onClick={() => {
                  setIndex(ACCORDION_NOTIF_REPO_INDEX.minds);
                  setSelectedMind();
                  setSelectedUser();
                  history.push("/manage/notifications");
                }}
              />
            )}
          </Flex>
          <AccordionPanel py={6}>
            <MindsTable
              simpleMode={simpleMode}
              onEditClick={(mind) => {
                setSelectedMind(mind);
                if (simpleMode === true){
                  history.push(`/manage/notifications/${mind.id}/${user.profile_id}`)
                } else {
                  history.push(`/manage/notifications/${mind.id}`)
                }
                setIndex(ACCORDION_NOTIF_REPO_INDEX.users);
              }}
            />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem display={simpleMode === false && canView ? 'block' : 'none' }>
          <Flex>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {selectedUser ? (
                  <Box>
                    <b>{t('general:user', 'user').toCapitalCase()}</b>
                    {` - [${selectedUser.email}]  ${selectedUser.fullname}`}
                  </Box>
                ) : (
                  <b>{t('general:users', 'users').toCapitalCase()}</b>
                )}
              </Box>
            </AccordionButton>
            {selectedUser && (
              <CloseButton
                alignSelf="center"
                onClick={() => {
                  setIndex(ACCORDION_NOTIF_REPO_INDEX.users);
                  setSelectedUser();
                  history.push(`/manage/notifications/${selectedMind.id}`);
                }}
              />
            )}
          </Flex>
          <AccordionPanel py={6}>
            {selectedMind && (
              <UsersTable
                mind={selectedMind}
                onEditClick={(user) => {
                  setSelectedUser(user);
                  setIndex(ACCORDION_NOTIF_REPO_INDEX.machines);
                }}
              />
            )}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <b>{t('general:machines', 'machines').toCapitalCase()}</b> {(index === ACCORDION_NOTIF_REPO_INDEX.users && isFetchingUsers) && <FullPageSpinner/> }
            </Box>
          </AccordionButton>
          <AccordionPanel py={6}>
            <MachinesTable  />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </MainLayout>
  );
};

export default Notifications;
