import React from 'react';
import { formatDistance } from "date-fns";

import { CircleIcon, LoginIcon, TriangleIcon} from "@/shared/icons";
import { mode } from "@/shared/theme";
import { Box, Tooltip, HStack } from "@chakra-ui/react";
import {Button} from '@/shared/components/core/Button'
import { USER } from "../../../frontend/src/consts";

// Fixme: Improve ColorMode move formatters in the main component

export const statusFormatter = (cell, row, user, colorMode, tooltip=true, t=() => '') => {
  const falseStateColor = mode(
    "frigel.neutral.gray3",
    "frigel.dark.blue1"
  )({ colorMode });
  const color =
    cell === "offline" ? falseStateColor : `frigel.${colorMode}.status.online`;
  var colorTriangle = "frigel.neutral.gray3" 
  var status = 'offline'
  if (cell === 'online'){
    colorTriangle = `frigel.${colorMode}.status.online`
    status = 'online'
  }else if (cell['warning']){
    status = 'online'
    if (cell['warning'].length === cell['num_vpn']){
      colorTriangle =`frigel.${colorMode}.status.alarm`
    }else{
      colorTriangle = `frigel.${colorMode}.status.warning`
    }
  }
  // Color check
  // const color = [falseStateColor,`frigel.${colorMode}.status.online` ][Math.floor(Math.random() * Math.floor(2))]
  if (!tooltip){
    return (
      <>
      <CircleIcon fontSize="xl" color={color} /> 
      {user.level.id != USER && cell['warning'] && <TriangleIcon fontSize="sm" color={colorTriangle}/>}
      </>
    )}
  return (
    <Tooltip label={
      <Box>
        <Box>{t('general:status', 'status').toCapitalCase()}: {status} </Box>
        {user.level.id != USER && cell['warning'] &&
          <Box>{'VPN'}: {`Mind are not connected at => ${cell['warning'] ? cell['warning'].join(', \n\r'): ''}`}</Box>}
        <Box>{t('general:last-seen', 'last seen').toCapitalCase()}: {formatDistance(new Date(row.last_seen), new Date())} </Box>
      </Box>
      }>
      <HStack justifyContent='center'>
      <CircleIcon fontSize="xl" color={color} />
      {user.level.id != USER && cell['warning'] &&
        <TriangleIcon fontSize="lg" color={colorTriangle}/>}
      </HStack>
    </Tooltip>
  );
};

export const fromNowFormatter = (cell, row) => {
  return (
    <Tooltip label={cell}>{formatDistance(new Date(cell), new Date())}</Tooltip>
  );
};

export const goOnMindFormatter = (cell, row, onClick, colorMode) => {
  return (
    <Button
      fontSize={['xs', 'sm']}
      rightIcon={<LoginIcon/>}
      size='sm' variant='secondary' onClick={onClick} isDisabled={row.status['warning'] ? row.status['warning'].length === row.status['num_vpn'] : row.status === 'offline'}>Login</Button>
  );
};