import React, {  useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/auth';
import { useMediaQuery } from '@chakra-ui/react';

/**
 * Util hook to fetch data and a function to refetch data.
 * Most likely will be removed in favor of react-query
 * @param {obj} params request params
 * @param {*} onError on error function
 */
export const useData = (
  {
    method='GET',
    onError=console.error,
    onSuccess=()=>{},
    skipFetch=false,
    refetchInterval=0,
    skipFetchIfHidden=false,
    ...rest
  }
) => {
  const {isAuthenticated, logout} = useAuth()
  const [doFetch, setDoFetch] = useState(false)
  const [data, setData] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)
  const [controller, setController] = useState(null)

  let firstUpdate = useRef(true);

  let startTime = useRef(0);
  let endTime = useRef(0);
  let timerId = useRef(null);
  useEffect(() => {
    if(timerId.current != null){
      clearTimeout(timerId.current)
      timerId.current = null;
    }

    if (!isAuthenticated || skipFetch) {
      return;
    }
    if (!firstUpdate.current && skipFetchIfHidden && document.hidden){
      if(refetchInterval>0){
        const current_time = performance.now()
        timerId.current = setTimeout(() => setDoFetch((state) => !state), Math.max(refetchInterval-(current_time - startTime.current), 100))
        return () => clearTimeout(timerId.current)
      }
    }
    firstUpdate.current = false
    setIsFetching(true)
    setIsError(false)
    if (controller) {
      controller.abort()
    }
    const newController = new AbortController()
    setController(newController)
    startTime.current = performance.now()
    axios({method, signal: newController.signal, ...rest})
      .then(resp => {
        setData(resp.data)
        onSuccess(resp.data)
      })
      .catch(reason => {
          setIsError(true)
          onError(reason)
          console.debug(`
            Reason: ${reason.message}
            Request params: ${method} ${rest}
          `)
          if (reason.request.status === 401){
            logout('Session Expired')
          }
        })
      .finally(() => {
        setIsFetching(false)
        setController(null)
        endTime.current = performance.now()
        if (refetchInterval>0){
          timerId.current = setTimeout(() => setDoFetch((state) => !state), Math.max(refetchInterval-(endTime.current - startTime.current), 5000))
          return () => clearTimeout(timerId.current)
        }
      })

      if(timerId.current != null){
        return () => clearTimeout(timerId.current)
      }
  }, [doFetch, isAuthenticated, skipFetch])

  return {
    data,
    refetch: () => setDoFetch((state) => !state),
    isFetching,
    isError
  }
}

export function useIsMobile(){
  const [isMobile] = useMediaQuery("(max-width: 768px)")
  return isMobile
}

export function useIsTablet(){
  const [isTablet] = useMediaQuery("(max-width: 1000px)")
  return isTablet
}