import axios from 'axios';
export const TOKEN_REFRESH_TIMEOUT = 600000; // (10min)
export const TOKEN_TIMEOUT = 7200000; // (2 hours)
export const API_BASE_URL = process.env.API_BASE_URL || ''; // no trailing slash

export function setupAxios(){
  axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = sessionStorage.getItem('token');
    if (token) {
      const headers = {
        Authorization: ` JWT ${token}`
      };

      config.headers = { ...config.headers, ...headers }
    }
    authRefreshToken();

    if (config.url.startsWith('/')){
      config.url = `${API_BASE_URL}${config.url}`;
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
}

export function authRefreshToken() {
  const init_time = sessionStorage.getItem('init_time');

  if (init_time) {
    const now = Date.now();
    const exec_time = now - init_time;

    if (exec_time >= TOKEN_REFRESH_TIMEOUT) {
      sessionStorage.setItem('init_time', now);

      axios.post('/api-token-refresh/', {
        token: sessionStorage.getItem("token")
      }).then(({ data }) => {
        sessionStorage.setItem('token', data.token);
      });
    }
  }
}
