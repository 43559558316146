import React, {useEffect}from "react";
import { 
  Box, Flex, HStack, Image, useColorMode,
  Breadcrumb, BreadcrumbItem, BreadcrumbLink
} from "@chakra-ui/react";
import { NavLink, MobileNavigation } from "./elements";

const Navbar = ({
  config,
  currentPathname,
  onClick,
  widgets = <div> 🤪</div>,
  selectedBg = "frigel.600",
  primaryBarProps = { h: 12 },
  secondaryBarProps = { h: 10 },
  imageProps = {},
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isChild = currentPathname.split("/").length >= 3;
  // pathname is defined like this: '/overview' which -> splitted becomes
  // ['', 'overview']
  const selectedPage = config.filter((a) => {
    if (isChild) {
      return a.url.split("/")[1] === currentPathname.split("/")[1];
    }
    return a.url === currentPathname;
  });

  if (selectedPage.length > 0 && selectedPage[0].hidden) {
    return null;
  }

  const isSelected = (el) => {
    const currPathname = currentPathname.split("/")[1];
    if (el.selectedOtherUrls) {
      const filteredOthersUrls = el.selectedOtherUrls.filter(
        (u) => u.split("/")[1] === currPathname
      );
      if (filteredOthersUrls.length > 0) {
        return true;
      }
    }
    return el.url.split("/")[1] === currPathname;
  };

  return (
    <>
      <Flex
        bg={`frigel.${colorMode}.blue`}
        w="100%"
        px={4}
        justifyContent="space-between"
        alignItems="center"
        {...primaryBarProps}
      >
        <Image {...imageProps} />

        <Box
          as="nav"
          h="full"
          display={{ base: "none", md: "flex" }}
          lineHeight={`${primaryBarProps.h / 4}rem`}
        >
          {config.map((el) => {
            if (el.hidden) return;
            return (
              <NavLink
                key={el.name + el.url}
                borderX="1px solid #ffffff26"
                px={[4, 16]}
                bgHover={selectedBg}
                bg={isSelected(el) ? selectedBg : ""}
                onClick={(e) => onClick(el.url)}
              >
                {el.name}
              </NavLink>
            );
          })}
        </Box>

        <Box display={{ base: "none", md: "block" }} >
          <HStack>{widgets}</HStack>
        </Box>
        <MobileNavigation
          widgets={widgets}
          imageProps={imageProps}
          config={config}
          selectedBg={selectedBg}
          isSelected={isSelected}
          onClick={onClick}
          currentPathname={currentPathname}
        />
      </Flex>
      {selectedPage.length > 0 && selectedPage[0].children && (
        <Flex
          bg={`frigel.${colorMode}.darkblue`}
          w="100%"
          display={{ base: "none", md: "block" }}
          alignItems="center"
          {...secondaryBarProps}
        >
          <Box
            as="nav"
            h="full"
            display="flex"
            justifyContent="center"
            lineHeight={`${secondaryBarProps.h / 4}rem`}
          >
            {selectedPage.length > 0 &&
              selectedPage[0].children.map((el) => (
                <NavLink
                  key={el.name + el.url}
                  bgHover={selectedBg}
                  bg={
                    el.url.split("/")[2] === currentPathname.split("/")[2]
                      ? selectedBg
                      : ""
                  }
                  onClick={(e) => onClick(el.url)}
                >
                  {el.name}
                </NavLink>
              ))}
          </Box>
        </Flex>
      )}
       {/* Breadcrumb that kicks in only on mobile with childrens  */}
       {selectedPage.length > 0 && selectedPage[0].children && (
        <Breadcrumb display={{ base: "block", md: "none" }} bg={`frigel.${colorMode}.darkblue`} color='white' px={5} py='1' fontSize='sm' mb={4}>
          <BreadcrumbItem>
            <BreadcrumbLink>{selectedPage[0].name}</BreadcrumbLink>
          </BreadcrumbItem>

          {selectedPage.length > 0 &&
              selectedPage[0].children.filter(el => el.url.split("/")[2] === currentPathname.split("/")[2]).map((el) => (
                  <BreadcrumbItem isCurrentPage key={el.name + el.url}>
                      <BreadcrumbLink href="#">{el.name}</BreadcrumbLink>
                    </BreadcrumbItem>

              ))}
        </Breadcrumb>

      )}
    </>
  );
};

export default Navbar;
