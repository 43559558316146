import React, { useEffect } from 'react';

import Navbar from '../../shared/components/Navbar';
import { useUser } from '../../contexts/auth';
import { useMinds } from "../../contexts/minds";
import { useReports } from "../../contexts/reports"
import { useTranslation } from 'react-i18next';
import { ColorModeToggleButton, NotificationsWidget, UserMenuWidget } from './elements';
import { useHistory, useLocation } from 'react-router-dom';
import { UNAUTHORIZED_URL, MANAGER_NOTIFICATIONS_URL, MANAGER_USERS_URL, MANAGER_REPORTS_URL, MANAGER_VPNS_URL, MANAGER_UPDATES_URL } from '../../api/urls';
import { MANAGER, USER, MANUFACTURER, ADMIN, CDN_PATH } from '@/consts'

//FIXME: should be in BE
export const URL_PERMISSIONS = {
  '/users': MANAGER,
  '/manage': MANUFACTURER,
  '/notifications': MANAGER,
  '/update': MANUFACTURER,
  '/vpn': ADMIN,
  '/stats': ADMIN
};

// TODO: Move into a better place maybe useUser?
function canViewPage(pathname, userPermissionLevel, reports = null) {

  const path = "/".concat(pathname.split('/').pop())
  const min_level = URL_PERMISSIONS[path] ? URL_PERMISSIONS[path] : USER;

  if (userPermissionLevel && userPermissionLevel.id < min_level) {
    return false
  }
  if (userPermissionLevel.id === USER) {
    if (reports && Object.keys(reports).length === 0 && path === '/reportfiles') {
      return false
    }
  }
  return true
}

function canViewReportPage(reports) {

  if (Object.keys(reports).length === 0) {
    return false
  }
  return true

}

function canViewMindPage(reports, minds, user_level) {

  if (user_level.id === ADMIN)
    return true
  else if (Object.keys(reports).length > 0 && minds.length === 0) {
    return false
  }
  return true
}


const NewNavbar = (props) => {

  const user = useUser()
  const { pathname } = useLocation()
  const history = useHistory()
  const { t } = useTranslation(['general'])
  const { currentUserMinds } = useMinds()
  const { reports } = useReports()

  const managerUrls =
    user.level.id == ADMIN ?
      [
        { name: t('general:users', 'users').toCapitalCase(), url: MANAGER_USERS_URL, hidden: !canViewPage('/users', user.level) },
        { name: t('general:Notifications', 'Notifications'), url: MANAGER_NOTIFICATIONS_URL, hidden: !canViewPage('/notifications', user.level) },
        { name: t('general:MindsUpdate', 'MiNDs update'), url: MANAGER_UPDATES_URL, hidden: !canViewPage('/update', user.level) },
        { name: 'Vpns', url: MANAGER_VPNS_URL, hidden: !canViewPage('/vpn', user.level) },
        { name: t('general:Reports', 'Reports'), url: MANAGER_REPORTS_URL, hidden: !canViewPage('/reports', user.level, reports) },
      ]
      : user.level.id == MANAGER ?
        [
          { name: t('general:users', 'users').toCapitalCase(), url: MANAGER_USERS_URL, hidden: !canViewPage('/users', user.level) },
          { name: t('general:Notifications', 'Notifications'), url: MANAGER_NOTIFICATIONS_URL, hidden: !canViewPage('/notifications', user.level) },
          { name: t('general:MindsUpdate', 'MiNDs update'), url: MANAGER_UPDATES_URL, hidden: !canViewPage('/update', user.level) },
          { name: t('general:Reports', 'Reports'), url: MANAGER_REPORTS_URL, hidden: !canViewPage('/reports', user.level, reports) }
        ]
        :
        [{ name: t('general:MindsUpdate', 'MiNDs update'), url: MANAGER_UPDATES_URL, hidden: !canViewPage('/update', user.level) }]

  const navigationConfig = [
    { name: 'MiNDs', url: '/', selectedOtherUrls: ['/minds'], hidden: !canViewMindPage(reports, currentUserMinds, user.level) },
    { name: t('general:Reports', 'Reports'), url: '/reportfiles', hidden: !canViewReportPage(reports) },
    { name: t('general:manage', 'Manage'), url: user.level.id > MANUFACTURER ? MANAGER_USERS_URL : MANAGER_UPDATES_URL, hidden: !canViewPage('/manage', user.level), children: managerUrls },
    { name: t('general:stats', 'Stats'), url: '/stats', hidden: !canViewPage('/stats', user.level) },
    { name: 'Login', url: '/login', hidden: true },
    { name: 'Token Login', url: '/token-login', hidden: true },
    { name: 'Unauthorized', url: UNAUTHORIZED_URL, hidden: true },
  ];

  useEffect(() => {
    if (!canViewMindPage(reports, currentUserMinds, user.level)) {
      history.push('/reportfiles')
    } else if (!canViewPage(pathname, user.level, reports, currentUserMinds)) {
      history.push(UNAUTHORIZED_URL)
    }
  }, [pathname])

  const Widgets = (
    <>
      <ColorModeToggleButton />
      <NotificationsWidget />
      <UserMenuWidget />
    </>
  );

  return (
    <Navbar
      config={navigationConfig}
      currentPathname={pathname}
      onClick={history.push}
      widgets={Widgets}
      imageProps={{
        src: `${CDN_PATH}/statics/images/hub-logo-light.svg`,
        width: '70px',
        mt: 2,
        cursor: 'pointer',
        onClick: () => !canViewMindPage(reports, currentUserMinds, user.level) ? history.push('/reportfiles') : history.push('/')
      }}
    />
  );
};

export default NewNavbar;
