import React, { useCallback, useEffect, useState } from 'react'
import { useData } from '../../hooks';
import axios from 'axios'

import {
  Box,
  Center,
  SimpleGrid,
  useColorMode,
  useToast,
  HStack
} from '@chakra-ui/react'

import { MindUpdatingSection, QueueMinds, LastUpdateLogSection, LastReleaseSection } from './elements';
import SelectionDataTable from "../../shared/components/SelectionDataTable";
import GroupFilter from '../../shared/components/GroupFilter';
import { useMinds, useLatestRelease } from "../../contexts/minds";
import { statusFormatter } from "../../utils/formatters";
import { useIsMobile } from '../../hooks';
import { useTranslation } from 'react-i18next'
import { getUniformedColumns } from "../../shared/components/DataTable";
import { OpenWindowIcon } from "../../shared/icons";
import { useHistory } from "react-router-dom";
import { Button } from '@/shared/components/core/Button'
import { TOAST_POSITION, STANDARD_ERROR_MSG } from '../../consts'
import { useUser } from '../../contexts/auth';
import FullPageSpinner from '@/shared/components/FullPageSpinner'

const Updates = (props) => {

  const [selected, setSelected] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState([])
  const { isFetching: isFetchingUserMind, currentUserMinds } = useMinds();
  const user = useUser()
  const toast = useToast()
  const { t } = useTranslation(['general'])
  const { colorMode } = useColorMode()
  const isMobile = useIsMobile()
  const history = useHistory()
  const { version: mindLatestVersion, changelogs } = useLatestRelease()

  const { data, isFetching, isError, refetch } = useData({
    url: '/api/get_updated_minds/'
  })

  useEffect(() => {

    const timerId = setTimeout(() => refetch(), 15000)
    return () => clearTimeout(timerId)
  }, [isFetching]);

  const { data: dataQueue, isFetching: isFetchingQueue, refetch: refetchQueue } = useData({
    url: '/api/minds_update_queue/'
  })

  useEffect(() => {

    const timerId = setTimeout(() => refetchQueue(), 15000)
    return () => clearTimeout(timerId)
  }, [isFetchingQueue]);

  const sortOptionsArray = (a, b) => {
    const splitA = a.value.split('.')
    const splitB = b.value.split('.')

    if (splitA.length < 3) {
      return -1
    } else if (splitB.length < 3) {
      return 1
    }
    for (let i = 0; i < 3; i++) {
      if (splitA[i] < splitB[i]) {
        return -1
      } else if (splitA[i] > splitB[i]) {
        return 1
      }
    }
    return 0
  }

  const removeMind = (minds, key) => {
    return minds.filter(function (m) {
      return dataQueue[key]?.every(function (m_queue) {
        return m.id != m_queue.id
      })
    })
  }

  const connectedMind = (minds) => {
    return minds.filter(function (m) {
      if (m.vpn[0].includes('mindsvpn.frigel.com')) {
        return m.vpn_info.some(function (vpn) {
          return vpn.status
        })
      }
      return true
    })
  }

  const applyFilters = useCallback((currentUserMinds) => {
    const mind_online = currentUserMinds.filter(function (m) {
      return m.status != 'offline'
    }).filter(function (m) {
      return m.app_version != mindLatestVersion
    })

    const mind_without_queue = removeMind(mind_online, 'queued')
    const mind_without_progress = removeMind(mind_without_queue, 'in_progress')
    const mind_connected_to_vpn = connectedMind(mind_without_progress)
    return mind_connected_to_vpn.filter(m => {
      if (selectedVersion.length > 0) {
        return selectedVersion.some(r => {
          return m.app_version.startsWith(r.value)
        })
      } else {
        return [...mind_connected_to_vpn]
      }
    })
  }, [dataQueue, selectedVersion, isFetching])

  const minds = applyFilters(currentUserMinds)
  const columnsProps = {
    id: { hidden: true },
    mid: { hidden: true, isKey: true },
    serial: {
      sort: true,
      align: 'left',
      text: t('general:serial', 'serial').toCapitalCase(),
      headerAlign: 'left',
      formatter: (col, row) => <Box>{col}</Box>
    },
    app_version: { hidden: isMobile },
    status: {
      text: t('general:status', 'status').toCapitalCase(),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row, props.user, colorMode, true, t),
    },
    update: {
      text: `Update`,
      formatter: (cell, row) => <Button variant='secondary' padding='10px' onClick={() => {
        {
          axios.post('/api/update/', {
            'mind': +row.id,
            'user_request': +user.profile_id,
          })
            .then((resp) => {
              toast({
                status: 'success',
                description: t('general:MindScheduledUpdate', 'MiND scheduled for update'),
                position: TOAST_POSITION
              })
              refetchQueue()
            })
            .catch((reason) => {
              toast({
                status: 'error',
                title: "Action is not permitted",
                position: TOAST_POSITION
              });
              console.error(reason);
            })
        }
      }}>{t('general:Update_btn', 'Update')}</Button>
    },
    info: {
      text: t('general:info', 'info').toCapitalCase(),
      formatter: (cell, row) => (
        <OpenWindowIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => history.push(`/minds/${row.id}`)}
        />
      ),
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);
  return (
    <>
      <Box alignContent='center' py='70px' px={{ lg: '150px', md: '80px', sm: '30px' }}>
        <SimpleGrid columns={2} gap={6} spacingX={24} spacingY={20}>
          <MindUpdatingSection data={dataQueue['in_progress']} user={user} mindLatestVersion={mindLatestVersion} />
          <LastReleaseSection mindLatestVersion={mindLatestVersion} changelogs={changelogs} />
          <QueueMinds data={dataQueue['queued']} refetch={refetchQueue} user={user} />
          <LastUpdateLogSection data={data} />
        </SimpleGrid>
      </Box>
      <Box alignContent='center' py='90px' px={{ lg: '175px', md: '100px', sm: '50px' }}>
        <SelectionDataTable
          keyField='id'
          rawData={minds}
          columns={columns}
          canSelect={true}
          clickToSelect={false}
          viewSelection={false}
          afterSelected={(selection) => setSelected(selection)}
          dataTableProps={{
            sizePerPage: 10,
            noDataIndication: isFetchingUserMind ? <FullPageSpinner /> : <Center>{t('general:NoData', "No data to display")}</Center>,
            paginationProviderProps: {
              withFirstAndLast: false
            }
          }}
          leftSlot={
            <>
              <GroupFilter
                value={selectedVersion}
                placeholder={t('minds:select-major-version', 'Select major mind version')}
                options={[...new Map(currentUserMinds.map((v) => (v.app_version == undefined || v.app_version == '' ? 'None' : v.app_version))
                  .map((v) => ({ label: v, value: v.split('.').slice(0, 3).join('.') }))
                  .map(item => [item['value'], item])).values()]
                  .sort((a, b) => sortOptionsArray(a, b))}
                onChange={values => { values ? setSelectedVersion(values) : setSelectedVersion([])}}
              />
              {selected.length > 0 &&
                <HStack alignItems='flex-start' ml={2}>
                  <Button variant='secondary' onClick={() => (
                    axios.post('/api/update/selected_update/', {
                      user_request: +user.profile_id,
                      minds: selected
                    })
                      .then((resp) => {
                        toast({
                          status: 'success',
                          description: t('general:MindScheduledUpdate', 'MiND scheduled for update'),
                          position: TOAST_POSITION
                        })
                        setSelected([])
                        refetchQueue()
                      })
                      .catch((reason) => {
                        toast({
                          status: 'error',
                          title: "Action is not permitted",
                          position: TOAST_POSITION
                        });
                        console.error(reason);
                      }))}>{t('general:UpdateSelected', 'Update selected')}</Button>
                </HStack>
              }
            </>}
        />
      </Box>
    </>

  )
}
export default Updates;