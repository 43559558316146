import React, { useEffect, useState } from "react";
import { useMinds } from "../../contexts/minds";
import FullPageSpinner from "@/shared/components/FullPageSpinner";
import ConfirmAlert from "../../components/ConfirmDeleteAlert";
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "../../shared/components/DataTable";
import {
  EditIcon,
} from "../../shared/icons";
import {
  Box,
  ModalBody,
  useColorMode,
  Flex,
  ModalFooter,
  HStack,
  useToast,
  useDisclosure,
  Checkbox,
  Text,
  VStack,
  Switch,
  Select,
  useBoolean,
  Center
} from "@chakra-ui/react";
import ModalSkeleton from "@/shared/components/ModalSkeleton";
import { useUsers } from "../../contexts/users";
import { useUser } from "../../contexts/auth";
import { TOAST_POSITION, WEEK_DAY, MONDAY } from "../../consts";
import { useHistory, useParams } from "react-router-dom";
import { useData, useIsMobile, useIsTablet } from "../../hooks";
import { Button } from '@/shared/components/core/Button'
import axios from "axios";
import {
  mindsTableCommonColumnProps,
  usersTableCommonColumnProps,
} from "../../components/elements";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

export const MindsTable = ({ onEditClick }) => {
  const { currentUserMinds } = useMinds();

  const showUserMinds = currentUserMinds.filter((m) => m.has_report)
  const isTablet = useIsTablet()
  const isMobile = useIsMobile();
  const { t } = useTranslation(['general', 'reports'])

  let mindsColumnsProps = mindsTableCommonColumnProps(isMobile, isTablet, t);
  delete mindsColumnsProps.status;
  delete mindsColumnsProps["company_country.name"];

  const columnsProps = {
    ...mindsColumnsProps,
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => (
        <EditIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            onEditClick(row);
          }}
        />
      ),
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={showUserMinds}
      columns={columns}
      noDataIndication={<Center>{t('general:NoData', "No data to display")}</Center>}
      toolbar={(props) => (
        <Box width={{ base: "100%", md: "30%" }}>
          <SearchBar searchProps={props.searchProps} />
        </Box>
      )}
    />
  );
};

export const UsersTable = ({ simpleMode, mind, onEditClick }) => {
  const { users, isFetching } = useUsers();
  const user = useUser();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet()
  const { t } = useTranslation(['general'])
  const history = useHistory();
  const { id } = mind;

  const { userId } = useParams();
  useEffect(() => {
    if (!isFetching && userId) {
      const selectedUser = users.filter(
        (u) => u.profile_id == userId
      );
      if (selectedUser.length !== 0) {
        if (!simpleMode && selectedUser[0].profile_id != user.profile_id) {
          onEditClick(selectedUser[0]);
        } else if (simpleMode) {
          onEditClick(selectedUser[0]);
        }
      }
    }
  }, [isFetching]);

  if (isFetching) {
    return <FullPageSpinner />;
  }

  let userTableProps = usersTableCommonColumnProps(isMobile, isTablet, t);
  delete userTableProps["level.name"];
  const columnsProps = {
    ...userTableProps,
    "mind_permission.name": {
      text: t('general:permission', "permission").toCapitalCase(),
    },
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => (
        <EditIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            onEditClick(row);
            history.push(`/manage/reports/${id}/${row.profile_id}`);
          }}
        />
      ),
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={users}
      columns={columns}
      toolbar={(props) => (
        <Box width={{ base: "100%", md: "30%" }}>
          <SearchBar searchProps={props.searchProps} />
        </Box>
      )}
    />
  );
};

export const MachinesTable = (props) => {
  const { mindId, userId } = useParams();
  if (!mindId || !userId) {
    return <Box></Box>;
  }

  const { canEdit, userName } = props;

  const { data, isFetching } = useData({
    url: `/api/minds/${mindId}/machines/`,
    params: { 'sender': 'report' }
  });
  const { t } = useTranslation(['general', 'reports'])
  const isMobile = useIsMobile();
  const isTablet = useIsTablet()
  const {
    data: reports,
    isFetching: reportsIsFetching,
    refetch: refetchReports,
  } = useData({
    url: `/api/minds/${mindId}/users/${userId}/reports/`,
  });

  if (isFetching || reportsIsFetching) {
    return <FullPageSpinner />;
  }

  const columnsProps = {
    id: { hidden: true, isKey: true },
    serial: mindsTableCommonColumnProps(isMobile, isTablet, t).serial,
    machine_model: {
      hidden: isMobile ? true : false,
      text: t('general:model', 'model').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    add_rm_rep: {
      text: '',
      formatter: (cell, row) => (
        <AddRemoveReport
          machine={row}
          report={reports.filter((n) => n.machine === row.id)[0]}
          mindId={mindId}
          userId={userId}
          userName={userName}
          refetchReports={refetchReports}
          canEdit={canEdit}
        />
      ),
    },
  };

  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={data}
      columns={columns}
      noDataIndication={<Center>{t('general:NoData', "No data to display")}</Center>}
      toolbar={(props) => (
        <Flex justify="space-between">
          <Box width="30%">
            <SearchBar searchProps={props.searchProps} />
          </Box>
          <HStack>
            <EnableAllReports
              machine={data}
              mindId={mindId}
              userId={userId}
              refetchReports={refetchReports}
            />
            <DisableAllReports
              reports={reports}
              mindId={mindId}
              userId={userId}
              refetchReports={refetchReports}
            />
          </HStack>
        </Flex>
      )}
    />
  );
};

const AddRemoveReport = ({ machine, report, mindId, userId, userName, refetchReports, canEdit }) => {
  const toast = useToast();
  const [weekday, setWeekday] = useState(report ? report.start_weekday : MONDAY);
  const [sendWeekly, setSendWeekly] = useState(report ? report.send_weekly : true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { t } = useTranslation(['general', 'reports'])
  const [reportEnabled, setReportEnabled] = useBoolean(machine.id == report?.machine)
  return (
    <>
      {machine.id == report?.machine && canEdit && <EditIcon disabled={!canEdit} mr={8} cursor="pointer" fontSize={{ base: "xs", md: "md" }} onClick={() => { onOpenEdit() }} />}
      <Switch disabled={!canEdit} cursor="pointer" marginLeft={reportEnabled ? 0 : 12} id={machine.id} isChecked={reportEnabled} onChange={() => { setReportEnabled.toggle, onOpen() }} />
      <ModalSkeleton
        isOpen={isOpen}
        onClose={onClose}
        title={reportEnabled ? t('reports:remove-report', "Remove report") : t('reports:edit-report', "Edit report")}
      >
        <ModalBody my={2}>
        {reportEnabled &&
            <HStack w='full' >
              <Text fontSize="sm">{t('reports:are-you-sure-single', `Are you sure to remove {{machine}} reports for {{username}}?`, {
                machine: machine.serial,
                username: userName
              })}</Text>
            </HStack>
          }
          {!reportEnabled &&
            <>
              <HStack w='full' >
                <Text fontSize="sm">{t('reports:start-day', 'Start day of week').toCapitalCase()}</Text>
                <Select w='50%' py={2} size="sm" value={weekday} onChange={(e) => setWeekday(+e.target.value)}>
                  {WEEK_DAY.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
                </Select>
              </HStack>
              <HStack>
                <Checkbox
                  isChecked={sendWeekly}
                  onChange={(e) => sendWeekly ? setSendWeekly(false) : setSendWeekly(true)
                  }
                >
                  <Text fontSize="sm">{t("reports:enable_send_weekly", "Enable send email with report every week",).toCapitalCase()}</Text>
                </Checkbox>
              </HStack>
            </>
          }
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant='secondary' mr={2} >
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end" variant='primary' onClick={() => {
              if (reportEnabled) {
              return axios
                .delete(
                  `/api/minds/${mindId}/users/${userId}/reports/${report.id}/`,
                )
                .then((resp) => {
                  toast({
                    status: "success",
                    description: t(
                      'reports:reports-disabled-successfully',
                      'Reports has been disabled successfully!'
                    ),
                    position: TOAST_POSITION,
                    isClosable: true,
                  });
                  refetchReports();
                  onClose
                })
              }
              else {
                return axios
                  .post(
                    `/api/minds/${mindId}/users/${userId}/reports/`,
                    {
                      user: userId,
                      machine: machine.id,
                      start_weekday: weekday,
                      send_weekly: sendWeekly,
                    }
                  )
                  .then((resp) => {
                    toast({
                      status: "success",
                      description: t(
                        'reports:reports-enabled-successfully',
                        'Reports has been enabled successfully!'
                      ),
                      position: TOAST_POSITION,
                      isClosable: true,
                    });
                    refetchReports();
                    onClose
                  })
              }
            }
            }>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>

      <ModalSkeleton
        isOpen={isOpenEdit}
        onClose={() => onCloseEdit()}
        title={t('reports:edit-report', "Edit report")}
      >
        <ModalBody my={2}>
          <HStack w='full' >
            <Text fontSize="sm">{t('reports:start-day', 'Start day of week').toCapitalCase()}</Text>
            <Select w='50%' py={2} size="sm" value={weekday} onChange={(e) => setWeekday(+e.target.value)}>
              {WEEK_DAY.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
            </Select>
          </HStack>
          <HStack>
            <Checkbox
              isChecked={sendWeekly}
              onChange={(e) => sendWeekly ? setSendWeekly(false) : setSendWeekly(true)
              }
            >
              <Text fontSize="sm">{t("reports:enable_send_weekly", "Enable send email with report every week",).toCapitalCase()}</Text>
            </Checkbox>
          </HStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => onCloseEdit()} variant='secondary' mr={2} >
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end" variant='primary' onClick={() => {

              const rId = report.id;
              return axios
                .put(
                  `/api/minds/${mindId}/users/${userId}/reports/${rId}/`,
                  {
                    user: userId,
                    machine: machine.id,
                    start_weekday: weekday,
                    send_weekly: sendWeekly,
                  }
                )
                .then((resp) => {
                  toast({
                    status: "success",
                    description: t(
                      'reports:reports-change-successfully',
                      'Reports has been change successfully!'
                    ),
                    position: TOAST_POSITION,
                    isClosable: true,
                  });
                  refetchReports();
                  onCloseEdit()
                })
            }
            }>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};



const EnableAllReports = ({ machine, mindId, userId, refetchReports }) => {
  const toast = useToast();
  const [weekday, setWeekday] = useState(MONDAY);
  const [sendWeekly, setSendWeekly] = useState(true);
  const { t } = useTranslation(['general', 'reports'])

  return (
    <ConfirmAlert
      title={t('reports:enable-all-reports', "Enable all reports")}
      confirmBtnText={t('general:Submit', 'Submit')}
      customBtn={(props) => (
        <Button size='sm'
          variant='secondary' {...props}>
          {startCase(t('general:enable-all', 'enable all'))}
        </Button>
      )}
      skeletonProps={{ size: "xl" }}
      message={
        <>
          <Text as="strong">{t('reports:select-report-info', 'Select reports information')} </Text>
          <VStack spacing={2} mt={3} align="flex-start">
            <HStack w='full'>
              <Text fontSize="sm">{t('reports:start-day', 'Start day of week').toCapitalCase()}</Text>
              <Select w='50%' size="sm" value={weekday} onChange={(e) => setWeekday(+e.target.value)}>
                {WEEK_DAY.map(p => <option key={p.id} value={p.id}>{p.name}</option>)}
              </Select>
            </HStack>
            <HStack>
              <Checkbox
                isChecked={sendWeekly}
                onChange={(e) => sendWeekly ? setSendWeekly(false) : setSendWeekly(true)
                }
              >
                <Text fontSize="sm">{t("reports:enable_send_weekly", "Enable send email with report every week",).toCapitalCase()}</Text>
              </Checkbox>
            </HStack>
          </VStack>
        </>
      }
      beforeClose={() => {
        return Promise.resolve(true);
      }}
      onConfirm={() => {
        if (machine.length === 0) {
          toast({
            status: "info",
            description: t('reports:no-machines', "There are no machines to enable"),
            position: TOAST_POSITION,
            isClosable: true,
          });
          return Promise.resolve(true);
        }
        return axios
          .post(
            `/api/minds/${mindId}/users/${userId}/reports/enable_all/`,
            {
              start_weekday: weekday,
              send_weekly: sendWeekly,
            }
          )
          .then((resp) => {
            toast({
              status: "success",
              description: t(
                'reports:reports-enabled-successfully',
                'Reports has been enabled successfully!'
              ),
              position: TOAST_POSITION,
              isClosable: true,
            });
            refetchReports();
          });
      }}
    />
  );
};

const DisableAllReports = ({ reports, mindId, userId, refetchReports }) => {
  const toast = useToast();
  const { t } = useTranslation(['general', 'reports'])
  const { colorMode } = useColorMode();

  return (
    <ConfirmAlert
      title={t('reports:disable-all-reports', 'Disable all reports')}
      confirmBtnText={t('general:Submit', 'Submit')}
      customBtn={(props) => (
        <Button size='sm'
          variant='secondary' {...props}>
          {t('general:disable-all', 'Disable all')}
        </Button>
      )}
      skeletonProps={{ size: "xl" }}
      message={
        <Text fontSize="sm">{t('reports:are-you-sure', `Are you sure to remove all machine reports for {{username}}?`, {
          username: userId.fullname
        })}
        </Text>

      }
      beforeClose={() => {
        return Promise.resolve(true);
      }}
      onConfirm={() => {
        if (reports.length === 0) {
          toast({
            status: "info",
            description: t('reports:no-to-remove', "There are no reports to remove"),
            position: TOAST_POSITION,
            isClosable: true,
          });
          return Promise.resolve(true);
        }
        return axios
          .post(
            `/api/minds/${mindId}/users/${userId}/reports/remove_all/`,
          )
          .then((resp) => {
            toast({
              status: "success",
              description: t(
                'reports:reports-disabled-successfully',
                'Reports has been disabled successfully!'
              ),
              position: TOAST_POSITION,
              isClosable: true,
            });
            refetchReports();
          });
      }}
    />
  );
};
