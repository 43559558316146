import React, { useState, useEffect, useRef } from 'react'
import {
  Badge,
  useColorMode,
  HStack,
  Alert,
  AlertIcon,
  Stack
} from "@chakra-ui/react";
import InlineBar from '@/shared/components/InlineBar';
import DataTable, { SearchBar } from "@/shared/components/DataTable";
import { getHiddenRows } from '../utils';
import { useTranslation } from 'react-i18next'
import {  badgeStyle } from '@/shared/theme';
import {Button} from '@/shared/components/core/Button'
export * from './DataTable/utils'

const SelectionDataTable = ({
  rawData, columns, initialSelected=[], keyField='id', dataTableProps,
  afterSelected=()=>{}, resetSelection=false,
  canSelect=true, canNotSelectMsg, hideSelectAll=false,
  leftSlot=null, hasCsvExport=false, hideSelectColumn=false, csvBtnDisabled=false,
  CSVFileName='data', clickToSelect=true, hasSearchBar=true, cellEditOptions=false,
  addUnitCSV = false, viewSelection=true
}) => {
  const [selected, setSelected] = useState(initialSelected)
  const selectOnlyMode = useRef(false)
  const [prevSelected, setPrevSelected] = useState([])
  const [showWarning, setShowWarning] = useState(false)
  const [csvFileName, setCSVFileName] = useState(CSVFileName)

  const [hiddenRows, setHiddenRows] = useState([])
  const {t} = useTranslation(['machines'])

  useEffect(() => {
      setSelected(initialSelected)
  }, [initialSelected.length])

  useEffect(() => {
    if (resetSelection){
      setSelected([])
    }
  }, [resetSelection])

  const selectRow = {
    mode: "checkbox",
    clickToSelect,
    hideSelectColumn,
    selected,
    // Hide select all in selectOnly mode if is not hidden
    hideSelectAll: hideSelectAll ? true : selectOnlyMode.current,
    onSelect: (row, isSelected) => {
      let newSelected = []
      if (!canSelect){
        setShowWarning(true)
      } else {
        setShowWarning(false)
      }
      if (isSelected && canSelect){
         newSelected = [...selected, row[keyField]]
      } else {
        newSelected = selected.filter(c => c !== row[keyField])
      }
      setSelected(newSelected)
      afterSelected(newSelected)
    },
    onSelectAll: (isSelect, rows, e) => {
      let selected = [];
      if (isSelect && canSelect) {
        selected = rawData.map( d => d[keyField])
      }

      if (!canSelect){
        setShowWarning(true)
      } else {
        setShowWarning(false)
      }

      setSelected(selected)
      afterSelected(selected)
    }
  };

  const onSelectOnly = () => {
    const newHiddenRows = getHiddenRows(selected, hiddenRows, rawData, keyField);
    setHiddenRows(newHiddenRows)
    selectOnlyMode.current = !selectOnlyMode.current
    setPrevSelected(selected)
  }
  let data = [...rawData]
  if (addUnitCSV){
      data = [...rawData].map(obj => { if (obj.unit && typeof(obj.value)!='string') obj.value = `${Math.round((obj.value+ Number.EPSILON)*100)/100} ${obj.unit}`; return obj })
  }
  if(hiddenRows.length > 0) {
    // Filters data if there are selected alarms,
    // So all filtered data will be shown in the first page.
    data = rawData.filter(a => prevSelected.includes(a[keyField]))
  }
  return (
    <>
    <DataTable
      toolbar={(props) => (
        <SelectionToolbar
          leftSlot={leftSlot}
          csvFileName = {csvFileName}
          setCSVFileName={setCSVFileName}
          hasCsvExport={hasCsvExport}
          hasSearchBar={hasSearchBar}
          csvBtnDisabled={csvBtnDisabled}
          toolbarProps={props}
          dataProps={{hiddenRows, selected, onSelectOnly, selectOnlyMode }}
          viewSelection={viewSelection}
        />
      )}
      keyField={keyField}
      data={data} columns={columns} selectRow={selectRow} hiddenRows={hiddenRows}
      exportCSV={{
        fileName: `${csvFileName}.csv`,
        noAutoBOM: false,
        separator: ','
      }}
      cellEditOptions={cellEditOptions}
      {...dataTableProps}
    />

    </>
  )
}

export default SelectionDataTable


const SelectionToolbar = ({toolbarProps, dataProps, leftSlot, csvFileName, setCSVFileName, hasCsvExport, hasSearchBar, csvBtnDisabled, viewSelection}) => {
  const { selected, hiddenRows, onSelectOnly, selectOnlyMode } = dataProps;
  const { t } = useTranslation(['machines'])
  const {colorMode} = useColorMode()

  return (
    <InlineBar
      barStyle={{ paddingTop: '1em'}}
      leftSlot={leftSlot}
      rightSlot={(
        <Stack direction={{base: 'column', md: 'row'}}>
          {viewSelection &&
          <Button
            role='group'
            size='sm'
            hidden={!selectOnlyMode.current && selected.length === 0}
            onClick={onSelectOnly}
            variant='secondary'
            rightIcon={
              hiddenRows.length == 0 && (
              <Badge {...badgeStyle({ colorMode })}>
                {selected.length}
              </Badge>
              )
            }
          >
            {
              hiddenRows.length > 0
              ? t('machines:ShowAll', 'Show All')
              : t('machines:ShowSelectedOnly', 'Show Selected Only')
            }
          </Button>}
          <HStack justifyContent={{base: 'start', md: 'end'}}>
            {hasCsvExport && (
              <MyExportCSV csvProps={toolbarProps.csvProps} disabled={csvBtnDisabled} csvName={csvFileName} setCSVName={setCSVFileName}>
                <Tooltip label={t('machines:ExportCSV', 'Export to CSV')} borderRadius={5}>
                  <IconButton variant='secondary' disabled={csvBtnDisabled}
                    icon={<DownloadIcon />}
                  />
                </Tooltip>
              </MyExportCSV>)
              }

            {hasSearchBar && 
              <SearchBar w={{ base: '100%', sm: '200px',  md: '300px'}} searchProps={toolbarProps.searchProps} style={{margin: 'auto'}}/>}
          </HStack>
        </Stack>
      )}
    />
  )
}