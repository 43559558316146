import React from 'react'
import {
   AlertDialog, AlertDialogOverlay, AlertDialogContent,
   AlertDialogHeader, useColorMode, AlertDialogCloseButton
} from '@chakra-ui/react'

const AlertDialogSkeleton = ({
  isOpen, onClose, title, children, leastDestructiveRef, ...rest
}) => {
const {colorMode} = useColorMode()
return (
  <AlertDialog isOpen={isOpen} onClose={onClose}
    leastDestructiveRef={leastDestructiveRef} {...rest}>
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader color='white' bgColor={`frigel.${colorMode}.blue`}
          borderTopRadius='md'
         >
          {title}
        </AlertDialogHeader>
        <AlertDialogCloseButton color='white'/>
          {children}
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
  )
}

export default AlertDialogSkeleton;