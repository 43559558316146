import React from 'react';
import { Box, useColorMode, useCheckbox } from '@chakra-ui/react';
import { secondaryBtnStyle } from '@/shared/theme'

function RadioCard(props) {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()
  const {colorMode} = useColorMode()

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        {...secondaryBtnStyle({colorMode})}
        cursor="pointer"
        borderRadius="md"
        px={2}
        py={1}
        {...props}>
        {props.children}
      </Box>
    </Box>
  )
}

export default RadioCard;
