import './changelog-markdown.css'
import React, { useEffect } from 'react'
import { Button } from '@/shared/components/core/Button'
import { FileIcon, LogIcon, UpdateIcon, TriangleIcon } from '@/shared/icons'
import axios from 'axios'
import {
  Text,
  Modal, ModalBody, ModalOverlay, ModalCloseButton, ModalFooter, ModalContent, ModalHeader,
  Tooltip,
  useDisclosure,
  useToast,
  HStack,
  VStack,
  Box,
  Divider,
} from '@chakra-ui/react'
import { TOAST_POSITION } from '../consts'
import { useTranslation } from 'react-i18next'
import { useLatestRelease } from '../contexts/minds'
import ReactMarkdown from 'react-markdown'
import { useUser } from '../contexts/auth';
import { useHistory } from 'react-router-dom';


export const getUpdate = (id, toast, t, refetchUpdateData, user, onClose) => {
  return axios.post('/api/update/', {'mind':id, 'user_request':user})
    .then(({ data }) => { 
      if (data.error) {
        let description = data.error
        toast({
          status: 'error',
          description,
          position: TOAST_POSITION
        })
      refetchUpdateData()
      onClose()
      } else {
        toast({
          status: 'success',
          description: t('general:Update_start', 'Update started successfully'),
          position: TOAST_POSITION
        })
        refetchUpdateData()
        onClose()
      }
    })
    .catch((error) => {
      toast({
        description:t(
          'errors:CommandNotSent',
          'Command has not been sent'
      ),
        status: 'error',
        position: TOAST_POSITION
      })
      refetchUpdateData()
      onClose()
  })
}

export const getNotifiedWhenUpdateFinished = (toast, t, serial) => {
  return axios.get('/api/notify_update_finished/', {params:{'serial':serial}})
    .then(({ data }) => { 
      if (data.error) {
        switch(data.error) {
          case 'update_finished':
            toast({
              status: 'success',
              description: t('errors:Update_finished', 'Update has already been completed'),
              position: TOAST_POSITION
            })
            break;
          case 'subscribed':
            toast({
              status: 'success',
              description: t('errors:Notify_userSubscribed', 'Already subscribed to the notification'),
              position: TOAST_POSITION
            })
            break;
          case 'retry':
            toast({
              status: 'error',
              description: t('errors:Notify_retry', 'An error occurred, please retry later'),
              position: TOAST_POSITION
            })
            break;
          default:
            toast({
              status: 'error',
              description: data.error,
              position: TOAST_POSITION
            })
            break;
          }
      } else {
        toast({
          status: 'success',
          description: t('general:Notify_whenUpdateFinished', 'You will receive an email when the update is completed'),
          position: TOAST_POSITION
        })
      }
    })
    .catch((error) => {
      toast({
        description:t(
          'errors:CommandNotSent',
          'Command has not been sent'
      ),
        status: 'error',
        position: TOAST_POSITION
      })
  })
} 



const UpdateMind = ({ mind, colorMode, logOnly, updateData, refetchUpdateData, ...rest }) => {
  const toast = useToast()
  const { t } = useTranslation(['general', 'errors', 'minds'])
  const { version:mindLatestVersion, changelogs } = useLatestRelease()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen:isOpenUpdating, onOpen:onOpenUpdating, onClose:onCloseUpdating } = useDisclosure();
  const { isOpen:isOpenChangelog, onOpen:onOpenChangelog, onClose:onCloseChangelog } = useDisclosure();
  const user = useUser()
  const history = useHistory()

  return (
    <>
      <Modal isCentered
        isOpen={isOpen}
        size='md'
        rounded='lg'
        onClose={() => onClose()}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader textColor='frigel.neutral.gray0' bgColor={`frigel.${colorMode}.blue`} borderTopRadius='md'>
              {t('general:Update_mind', 'MiND update')}
            </ModalHeader>
            <ModalCloseButton textColor='frigel.neutral.gray0' />
            <ModalBody>
              {t('machines:Areyousure', 'Are you sure to update:')+ ' '}<strong>{mind.serial}</strong>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => onClose()}
                right={1}
                variant='secondary'
              >
                {t('general:Cancel', 'Cancel')}
              </Button>
              <Button
                variant='primary'
                onClick={() => {getUpdate(mind.id, toast, t, refetchUpdateData, user.profile_id, onClose)}}
                left={4}
              >
                {t('general:Update_btn', 'Update')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalOverlay>
      </Modal>
      
      {
        logOnly ?
        <HStack spacing={3}>
          <Tooltip placement='top' label={
              t('general:Update_log', 'Update log')
            }>
            <LogIcon
              cursor='pointer'
              fontSize={{base: 'xs', md: 'md'}}
              onClick={() => {
                onOpenUpdating();
                }
              }
            >
            </LogIcon>
          </Tooltip>
          {updateData?.successful == false &&
            <Tooltip placement='top' label={
              t('general:Update_error', 'The last software update attempt was not successful.')
            }>
              <TriangleIcon color={`frigel.${colorMode}.status.warning`}></TriangleIcon>
              </Tooltip>
          }
        </HStack>
          :
          (
            updateData?.in_progress  || updateData?.queued ? (
            <HStack spacing={3}>
                <div>{updateData?.in_progress ?  t('general:Update_progress','Update in progress') : t('general:Update_queued', 'Update queued')}</div>
                <UpdateIcon className='rotate' />
                {updateData?.queued &&
                  <Button justifyContent='center' alignItems='center'
                    variant='secondary'
                    px={5}
                    size='xs'
                    onClick={() => { history.push('/manage/update') }}
                    left={4}
                  >
                    {t('general:GoToUpdate', 'Go to update page')}
                  </Button> }
                  <Button
                    justifyContent='center' alignItems='center'
                    variant='secondary'
                    px={5}
                    size='xs'
                    onClick={() => {getNotifiedWhenUpdateFinished(toast, t, mind.serial)}}
                    left={4}
                  >
                    {t('general:Notify_me', 'Notify me')}
                  </Button>
              </HStack>)
              :
              mindLatestVersion && mindLatestVersion != mind.app_version ? (
                <VStack spacing={3} align='stretch'>
                    <HStack spacing={3}>
                      <Tooltip placement='top' label={
                          t('general:Changelog', 'Changelog')
                        }>
                        <FileIcon
                          cursor='pointer'
                          fontSize={{base: 'xs', md: 'md'}}
                          onClick={() => {
                            onOpenChangelog();
                            }
                          }
                        ></FileIcon>
                      </Tooltip>
                      <Text color='grey' fontSize='sm'>{t('general:Update_newVersion', 'New version avaliable') + ` ${mindLatestVersion}`}</Text>
                      <Button
                          justifyContent='center' alignItems='center'
                          px={5}
                          variant='secondary'
                          size='xs' onClick={() => onOpen()}
                          disabled={mind.status === 'offline' || (mind.status.warning && mind.status.warning.length === mind.status.num_vpn)}>
                            {t('general:Update_btn', 'Update')}
                        </Button>
                    </HStack>
                  </VStack>
                )
                :
                <></>
              
          )
      }
      <Modal isCentered
        isOpen={isOpenUpdating}
        size="5xl"
        rounded='lg'
        scrollBehavior='inside'
        onClose={() => {onCloseUpdating()
                }}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader textColor='frigel.neutral.gray0' bgColor={`frigel.${colorMode}.blue`} borderTopRadius='md'>
              {(updateData?.in_progress ? t('general:Updating_mind', 'Updating MiND') : t('general:Update_lastlog', "Last update log")) + `: ${mind.serial}`} 
            </ModalHeader>
            <ModalCloseButton textColor='frigel.neutral.gray0' />
            <ModalBody>
            {updateData?.update_log?.length>0 ? updateData.update_log.map((o, idx) => <Text key={idx} color={o.includes('fatal') || o.includes('MiND update: failed')? 'red.500': 
                                                                o.includes('MiND update: succefully')? 'green.500':'black.500'}>{o}</Text>): t('general:No_log', 'No log data available') }
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>

      <Modal isCentered
        isOpen={isOpenChangelog}
        size="5xl"
        rounded='lg'
        scrollBehavior='inside'
        onClose={() => {onCloseChangelog()
                }}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader textColor='frigel.neutral.gray0' bgColor={`frigel.${colorMode}.blue`} borderTopRadius='md'>
              {t('general:Changelog', 'Changelog') + ` ${mindLatestVersion}`} 
            </ModalHeader>
            <ModalCloseButton textColor='frigel.neutral.gray0' />
            <ModalBody>
              {changelogs?.map((changelogObj, index) => {
                return (
                <div key={changelogObj.version}>
                  <p>{changelogObj.version}</p>
                  <ReactMarkdown children={changelogObj.changelog} className="changelog-markdown" />
                  {index+1<changelogs.length && <Divider mb='6' hr='6' />}
                </div>
                )
              })}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}
export default UpdateMind;