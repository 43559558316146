import React, { useEffect, useState } from "react";
import { Chart, registerables } from 'chart.js';
import { Bar, Pie } from "react-chartjs-2";
import {
    Box,
    Flex,
    HStack,
    Stack,
    SimpleGrid,
    VStack,
    Heading,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    Show,
    Text,
    Tooltip,
    useToast,
    Image,
    useColorMode,
    Avatar,
    Center,
    Grid,
    GridItem
} from '@chakra-ui/react';
import theme from '../../shared/theme';
import { useWindowDimensions } from '../../shared/utils';
import { TOAST_POSITION, CDN_PATH } from "../../consts";
import { useTranslation } from "react-i18next";
import { KingIcon } from "../../shared/icons";
import  ResponsiveText from '../../shared/components/ResponsiveText';


export const BarChart = ({ x_axis, chart_data, cumulativeData, cumulativeName }) => {

    const [chartData, setChartData] = useState({ datasets: [] });
    const { colorMode } = useColorMode();

    Chart.register(...registerables);

    useEffect(() => {
        const datasets = []
        for (const data of chart_data) {
            datasets.push({
                label: data.label,
                data: data.data,
                backgroundColor: data.color
            })
        }
        setChartData({
            labels: x_axis,
            datasets: datasets,
        })
    }, [x_axis, chart_data]);

    return (
        <Bar
            data={chartData}
            options={{
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: true,
                        labels: {
                            boxWidth: 12,
                            useBorderRadius: true,
                            borderRadius: 6,
                            color: colorMode === 'light' ? theme.colors.frigel.neutral.darkGray : theme.colors.frigel.neutral.white
                        },
                    },

                    tooltip: {
                        callbacks: {
                            footer: (context) => {
                                return `${cumulativeName}: ${cumulativeData[context[0].dataIndex]}`;
                            }
                        }
                    },
                },

                scales: {
                    x: {
                        stacked: true,
                        grid: {
                            color: colorMode === 'light' ? Chart.defaults.borderColor : 'white',
                        },
                        ticks: {
                            color: colorMode === 'light' ? theme.colors.frigel.neutral.darkGray : theme.colors.frigel.neutral.white
                        },
                        border: {
                            color: colorMode === 'light' ? Chart.defaults.borderColor : 'white',
                        }
                    },
                    y: {
                        stacked: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            color: colorMode === 'light' ? '#4F4F4F' : '#FCFCFC'
                        },
                        border: {
                            color: colorMode === 'light' ? Chart.defaults.borderColor : 'white',
                        }
                    }
                }
            }}
        />
    );
};

export const PieChart = ({ values, labels, backgroundColors, displayLegend = false }) => {

    const [chartData, setChartData] = useState({ datasets: [] });

    useEffect(() => {
        setChartData({
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: backgroundColors
                }
            ]
        })

    }, [values, labels, backgroundColors])

    return (
        <Pie
            data={chartData}
            options={{
                plugins: {
                    title: {
                        display: false,
                    },
                    legend: {
                        display: displayLegend,
                        position: "right"
                    },
                }
            }
            }
        />
    )
}


export const StatsVerticalInfo = ({ title, value, prev, monthly }) => {
    const percentage = prev > 0 ? (value / prev - 1) * 100.0 : (value > 0 ? null : 0)
    return (
        // <VStack align='space-between'>
        //     <Heading size='md' color='grey'>{title}</Heading>
        //     <Heading size='2xl'>{value}</Heading>
        //     <Heading size='md'>{`${percentage}%`}</Heading>
        //     <Heading size='sm' color='grey'>{`vs ${prev} prev. ${monthly ? 'month' : 'week'}`}</Heading>
        // </VStack>

        <Stat>
            <StatLabel>{title}</StatLabel>
            <StatNumber>{value}</StatNumber>
            <StatHelpText>
                {percentage !== null && percentage !== 0 && <StatArrow type={percentage >= 0 ? 'increase' : 'decrease'} />}
                {`${percentage !== null ? Math.round(percentage) : '-'}%`}
            </StatHelpText>
            <StatHelpText>
                {`vs ${prev} prev. ${monthly ? 'month' : 'week'}`}
            </StatHelpText>
        </Stat>
    )
}

export const MachineCard = ({ machine }) => {
    const toast = useToast();
    const { t } = useTranslation(['general']);
    const onClick = () => {
        navigator.clipboard.writeText(Object.values(machine.machines).map((m) => {
            return `${m.model}, ${m.connected}, ${m.total}`
        }).join('\n'))

        toast({
            title: t('general:copiedClipboard', 'copied to clipboard').toCapitalCase(),
            position: TOAST_POSITION,
            isClosable: true,
        })
    };
    return (
        <Tooltip label={Object.values(machine.machines).map((m) => {
            return <Text key={m.model}>{`${m.model}: ${m.connected}/${m.total}`}</Text>
        })} borderRadius={5}>
            <Box minW={[36, 40]} minH={124} h={124} w={[36, 40]} borderRadius="md" boxShadow="md"
                _hover={{ boxShadow: "lg", cursor: 'pointer' }}
                onClick={onClick}
            >
                <Flex direction='column' h='full'>
                    <Box flex='1' px={2}>
                        <Flex>
                            <VStack isTruncated flex='1' align='flex-start'>
                                <Text width='100%' align='right' size='xs' as='samp'>{machine.connected}/{machine.total}</Text>
                            </VStack>
                        </Flex>
                    </Box>
                    <Box width='100%' align='center'>
                        <Image boxSize='80px' src={`${CDN_PATH}/statics/images/machines/${machine.family}.svg`} ignoreFallback={true} onError={(event) => event.target.style.display = 'none'} />
                    </Box>
                    <Box bgColor={'#5380C5'} borderBottomRadius="md" px={2}>
                        <Text color={'white'}>{machine.family}</Text>
                    </Box>
                </Flex>
            </Box>
        </Tooltip>
    )
}

export const UserCard = ({ user, index }) => {
    const { t } = useTranslation(['general']);
    return (
        <Box minW={index == 1? [44,48] : [36, 40]} minH={index == 1? 170:150} h={index == 1 ? 170 : 150} w={index == 1? [44,48] : [36, 40]} borderRadius="md" boxShadow="md"
            _hover={{ boxShadow: "lg", cursor: 'pointer' }}>
            <Flex direction='column' h='full' w='full'>
                <Center>
                    <HStack>
                        <Avatar name={user.name && user.surname ? `${user.name} ${user.surname}` : user.username} size={index ==1? 'lg':'md'}/>
                        {index === 1 && <KingIcon fontWeight='bold' align='left' color='#cda434 ' fontSize={index ==1? '5xl':'3xl'} />}
                    </HStack>
                </Center>
                <Box mt={index == 1? 2:4}>
                    <Center>
                        <ResponsiveText fontWeight='bold' width='100%' align='center' size='xs' compress={'190ch'} mt={index ==1 ? 4 : ''}>
                            {user.name && user.surname ? `${user.name} ${user.surname}` : user.username}
                        </ResponsiveText>
                    </Center>
                    <HStack  mt={index ==1 ? 8 : 6}>
                        <ResponsiveText width='100%' align='left' size='xs' compress={'190ch'}> {user.branch ? user.branch: user.company} </ResponsiveText>
                        <Text fontWeight='bold' width='100%' align='right' size='xs'>{user.count}</Text>
                    </HStack>
                </Box>
                <Box bgColor={index == 1 ? '#cda434 ' : index == 2 ? '#c0c0c0' : index == 3 ? '#cd7f32' : '#5380C5'} borderBottomRadius="md">
                    <Text fontWeight='bold' align='center' color={'white'}>{index}</Text>
                </Box>
            </Flex>
        </Box>
    )
}

export const StatsCard = ({ title, cardData, monthly, machines, pieData, top_users }) => {
    const { width: windowWidth } = useWindowDimensions()
    const { colorMode } = useColorMode();
    const { t } = useTranslation(['general']);
    return (
        <Box borderWidth='1px' borderColor='#22325F' borderRadius='md' p={4} bgColor={`frigel.${colorMode}.stats`} w={['100%']} maxWidth='1800px'>
            <Heading as='h2' size='lg'>{title}</Heading>
            <SimpleGrid minChildWidth={windowWidth < 600 ? '250px' : '500px'} minWidth='250px' spacing='100px'>
                {cardData && cardData.map((data) => {
                    return (
                        <Box key={data.cumulativeName} maxWidth='800px'>
                            <VStack align='flex-start' >
                                <HStack borderTopRadius='md'
                                    py={2}
                                    px={9}
                                    justifyContent='space-evenly' w='full'>
                                    {data?.cumulativeData && <StatsVerticalInfo
                                        title={data.cumulativeNameAlt || data.cumulativeName}
                                        value={data.cumulativeDataAlt ? data.cumulativeDataAlt[data.cumulativeDataAlt.length - 1] : data.cumulativeData[data.cumulativeData.length - 1]}
                                        prev={data.cumulativeDataAlt ? data.cumulativeDataAlt[data.cumulativeDataAlt.length - 2] : data.cumulativeData[data.cumulativeData.length - 2]}
                                        monthly={monthly}
                                    />
                                    }
                                    {
                                        data?.chart_data[0]?.data && <StatsVerticalInfo
                                            title={data.chart_data[0].label}
                                            value={data.chart_data[0].data[data.chart_data[0].data.length - 1]}
                                            prev={data.chart_data[0].data[data.chart_data[0].data.length - 2]}
                                            monthly={monthly}
                                        />
                                    }
                                    {
                                        data?.chart_data[1]?.data &&
                                        <Show breakpoint='(min-width: 600px)'>
                                            <Box width='150px'>
                                                <PieChart
                                                    values={[data.chart_data[0].data[data.chart_data[0].data.length - 1], data.chart_data[1].data[data.chart_data[1].data.length - 1]]}
                                                    labels={[data.chart_data[0].label, data.chart_data[1].label]}
                                                    backgroundColors={[data.chart_data[0].color, data.chart_data[1].color]}
                                                />
                                            </Box>
                                        </Show>
                                    }
                                </HStack>
                                <BarChart x_axis={monthly ? data?.x_axis?.map((el) => {
                                    return `${new Date(el).toLocaleString('default', { month: 'short', year: '2-digit' })}`;
                                }) :
                                    data.x_axis?.map((el) => {
                                        return `Week ${el.split(':')[1]}`
                                    })}
                                    chart_data={data.chart_data}
                                    cumulativeData={data.cumulativeData}
                                    cumulativeName={data.cumulativeName}>
                                </BarChart>
                            </VStack>
                        </Box>)
                })
                }
                {machines &&
                    <SimpleGrid minChildWidth='150px' spacing='48px' px='24px'>
                        {machines.map((machine) => {
                            return <MachineCard key={machine.family}
                                machine={machine}
                            />
                        })}
                    </SimpleGrid>}
                {pieData && pieData != [] &&
                    <VStack justify='space-between'>
                        {pieData.map((pie) => {
                            return (<VStack width='230px' key={pie.user}>
                                <Text>{pie.user}</Text>
                                <PieChart
                                    values={pie.data.map((pieObj) => { return pieObj.data })}
                                    labels={pie.data.map((pieObj) => { return pieObj.label })}
                                    // backgroundColors={pie.map((pieObj)=>{return pieObj.color})}
                                    displayLegend={true}
                                />
                            </VStack>)
                        })}
                    </VStack>
                }
                {cardData && cardData.length == 1 && !machines && !pieData && windowWidth >= 1276 && <Box /> /*Needed to align single elements*/}
            </SimpleGrid>
            {top_users &&
                <Stack direction={windowWidth < 1200 ? 'column': 'row'} spacing={windowWidth < 1200 ? 8: 16} >
                    <Box w='full'>
                        <Heading as='h4' size='ms' marginBlockEnd={8}>{t('general:internalUser','Internal User')}</Heading>
                        <Grid
                            h='600px'
                            templateRows='repeat(1, 200px)'
                            templateColumns='repeat(2, auto)'
                            gap={windowWidth < 1200 ? '30px':'20px'}
                        >

                            {top_users['int'].map((user) => {
                                if (top_users['int'].indexOf(user) + 1 == 1) {
                                    return(
                                    <GridItem rowSpan={1} colSpan={2}>
                                        <Center>
                                            <UserCard key={user.id}
                                                user={user} index={top_users['int'].indexOf(user) + 1} />
                                        </Center>
                                    </GridItem>)
                                }
                                return(
                                <GridItem rowSpan={2} colSpan={1}>
                                        <Center>
                                            <UserCard key={user.id}
                                                user={user} index={top_users['int'].indexOf(user) + 1} />
                                        </Center>
                                </GridItem>)
                            })}
                        </Grid>
                    </Box>
                    <Box w='full'>
                        <Heading as='h4' size='ms' marginBlockEnd={6} mt={windowWidth < 1200 ? 8:2}>{t('general:externalUser','External User')}</Heading>
                        <Grid
                            h='600px'
                            templateRows='repeat(1, 200px)'
                            templateColumns='repeat(2, auto)'
                            gap={windowWidth < 1200 ? '30px':'20px'}
                        >

                            {top_users['ext'].map((user) => {
                                if (top_users['ext'].indexOf(user) + 1 == 1) {
                                    return(
                                    <GridItem rowSpan={1} colSpan={2}>
                                        <Center>
                                            <UserCard key={user.id}
                                                user={user} index={top_users['ext'].indexOf(user) + 1} />
                                        </Center>
                                    </GridItem>)
                                }
                                return(
                                <GridItem rowSpan={2} colSpan={1}>
                                        <Center>
                                            <UserCard key={user.id}
                                                user={user} index={top_users['ext'].indexOf(user) + 1} />
                                        </Center>
                                </GridItem>)
                            })}
                        </Grid>
                    </Box>
                </Stack>}
        </Box>
    )
}