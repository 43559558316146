import axios from 'axios'

export const fetchCompanies = (searchedCompany) => {
    if (!searchedCompany) {
        return [];
    }
    return axios
        .post("/api/search_company/", { comp_name_key: searchedCompany, offset: 0 })
        .then(({ data }) => {
        const { count, results } = data;
        return [results.map((r) => ({
            value: r.id,
            label: r.ragione_sociale,
            company: r,
        })), data];
        })
        .catch((e) => console.error(e.message));
};
  
export const fetchCompanyCoordinates = ({ cap, localita, country_code, ...rest }) => {
    return axios
        .post("/api/search_company_coordinates/", { cap, localita, country_code })
        .then((resp) => resp.data)
        .catch((reason) => ({ latitude: 0, longitude: 0 }));
};
  
export  const upsertCompany = (company) => {
    return axios.post('/api/companies/', company)
        .then(resp => resp.data)
}