import React, { useRef, useState } from 'react'
import AlertDialogSkeleton from '@/shared/components/AlertDialogSkeleton'
import { AlertDialogBody, AlertDialogFooter, useDisclosure, useColorMode, useToast } from '@chakra-ui/react'
import { TrashIcon } from '../shared/icons'
import {Button} from '@/shared/components/core/Button'
import { TOAST_POSITION, STANDARD_ERROR_MSG } from '../consts'

const ConfirmAlert = ({
  title, message, confirmBtnText, onConfirm, customBtn,
  confirmDisabled=false, beforeClose=() => Promise.resolve(true),
  skeletonProps={}
}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const leastDestructiveRef = useRef()
  const {colorMode} = useColorMode()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)

  return (
    <>
    {customBtn ? customBtn({onClick: onOpen}):
    (<TrashIcon
      cursor='pointer'
      fontSize={{ base: "xs", md: "md" }}
      onClick={onOpen} />)}
    <AlertDialogSkeleton
      {...{isOpen, leastDestructiveRef, onClose, title}}
      {...skeletonProps}
      onClose={() => {
        beforeClose()
          .then(onClose())
      }}
      >
      <AlertDialogBody>
        {message}
      </AlertDialogBody>
      <AlertDialogFooter>
        <Button ref={leastDestructiveRef} onClick={() => {
          beforeClose()
            .then(onClose())
        }}>
          Cancel
        </Button>
        <Button variant= 'primary' disabled={confirmDisabled} isLoading={isLoading}
          ml={3} onClick={() => {
            setIsLoading(true)
            onConfirm()
              .then(() => {
                onClose()
              })
              .catch(reason => {
                toast({
                  status: 'error',
                  description: STANDARD_ERROR_MSG,
                  position: TOAST_POSITION,
                  isClosable: true
                })
                console.error(reason)
              })
              .finally(() => setIsLoading(false))
          }}>
          {confirmBtnText || 'Delete'}
        </Button>
      </AlertDialogFooter>
    </AlertDialogSkeleton>
    </>
  )
}

export default ConfirmAlert;