import React from 'react'
import { useData } from "@/hooks";


const MachinesContext = React.createContext()

function MachinesProvider({ children, ...props }) {

  const { data, isFetching, isError, refetch } = useData({
    url: "/api/get_machines_info/"
  })

  return (<MachinesContext.Provider value={{
    machines: data, isFetching, isError, refetch
  }} {...props}>
    {children}
  </MachinesContext.Provider>
  )
}


function useMachines() {
  const context = React.useContext(MachinesContext)
  if (context === undefined) {
    throw new Error(`useMinds must be used within a MachinesProvider`)
  }
  return context
}

export { MachinesProvider, useMachines }