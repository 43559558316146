import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const ArrowLoop = createIcon({
  displayName: 'ArrowLoop',
  d: "M12 1v3a8 8 0 016.8 12.3l-1.5-1.5A6 6 0 0012 6v3L8 5l4-4zM6 12a6 6 0 006 6v-3l4 4-4 4v-3A8 8 0 015.2 7.7l1.5 1.5C6.2 10 6 11 6 12z"
})

export const ArrowCircle = createIcon({
  displayName: 'ArrowCircle',
  d: "M18.355 5.644A8.96 8.96 0 0011.995 3 8.99 8.99 0 003 12c0 4.973 4.019 9 8.994 9 4.2 0 7.7-2.869 8.702-6.75h-2.341a6.743 6.743 0 01-6.36 4.5c-3.727 0-6.755-3.026-6.755-6.75s3.028-6.75 6.754-6.75c1.869 0 3.535.776 4.75 2.003l-3.624 3.622H21V3l-2.645 2.644z"
})

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  path: <svg> <path d="M12.0517 4.5L13.5 6.03432L7.39666 12.5L13.5 18.9657L12.0517 20.5L4.5 12.5L12.0517 4.5Z"  fill="currentColor"/><rect x="6.5" y="11.5" width="13" height="2" fill="currentColor"/> </svg>
})

export const ArrowDownIcon = createIcon({
  displayName: 'ArrowDownIcon',
  path: <svg> <path d="M4.5 11.9483L6.03432 10.5L12.5 16.6033L18.9657 10.5L20.5 11.9483L12.5 19.5L4.5 11.9483Z"  fill="currentColor"/><rect x="11.5" y="17.5" width="13" height="2" transform="rotate(-90 11.5 17.5)" fill="currentColor"/> </svg>
})