import { capitalize } from 'lodash';
import i18n from './i18n'
import { ArrowLoop, ItalyIcon, UKIcon } from './shared/icons';
import React from 'react'

export const CDN_PATH = process.env.NODE_ENV === "production" ? 'https://frigel.fra1.cdn.digitaloceanspaces.com/hub' : '';

export const FIRST_VPN_HOST = 2;
export const VPN_HOST_MAX = 240;

export const USER = 1;
export const INSTALLER = 2;
export const MANUFACTURER = 3;
export const MANAGER = 50;
export const ADMIN = 99;

export const PERMISSIONS_LIST = [
  { id: USER, name: 'User' },
  { id: INSTALLER, name: 'Installer' },
  { id: MANUFACTURER, name: 'Manufacturer' },
  { id: MANAGER, name: 'Manager' },
  { id: ADMIN, name: 'Admin' },
];

export const FRIGEL_BRANCHES_INTERACTIONS_LIST = [
  'ALL', 'FFI', 'FAP', 'FNA', 'FDE', 'FIN'
]

export const TOAST_POSITION = 'top-right'

export const STANDARD_ERROR_MSG = capitalize(i18n.t(
  'general:request-error',
  'the operation requested was not successful, please try again'
))

export const LANGUAGES = [
  { value: 'en', label: 'English', icon: <UKIcon verticalAlign='text-top' /> },
  { value: 'it', label: 'Italian', icon: <ItalyIcon verticalAlign='text-top' /> }
]


export const PAGEVIEW_MAP = 'Map';
export const PAGEVIEW_WITHOUT_MAP = 'Table';


export const PAGEVIEWS_OPTIONS = {
  [PAGEVIEW_MAP]: { value: 10, text: 'Map' },
  [PAGEVIEW_WITHOUT_MAP]: { value: 25, text: 'Table' }
}

// export const PAGEVIEW_MAP = 'With map';
// export const PAGEVIEW_WITHOUT_MAP = 'Without map';


// export const PAGEVIEWS_OPTIONS = {
//   [PAGEVIEW_MAP]: { value: 10, text: 'With map' },
//   [PAGEVIEW_WITHOUT_MAP]: { value: 25, text: 'Without map' }
// }

export const ACCORDION_NOTIF_REPO_INDEX = {
  minds: 0,
  users: 1,
  machines: 2
}


export const MONDAY = 1;
export const TUESDAY = 2;
export const WEDNESDAY = 3;
export const THURSDAY = 4;
export const FRIDAY = 5;
export const SATURDAY = 6;
export const SUNDAY = 7;

export const WEEK_DAY = [
  { id: MONDAY, name: i18n.t('general:monday', 'Monday') },
  //{id: TUESDAY, name:i18n.t('general:tuesday','Tuesday')},
  //{id: WEDNESDAY, name:i18n.t('general:wednesday','Wednesday')},
  //{id: THURSDAY, name:i18n.t('general:thursday','Thursday')},
  //{id: FRIDAY, name:i18n.t('general:friday','Friday')},
  //{id: SATURDAY, name:i18n.t('general:saturday','Saturday')},
  { id: SUNDAY, name: i18n.t('general:sunday', 'Sunday') }
];




//// 3PR INFO //////
export const ECODRY_TYPE_NA = 0
export const ECODRY_TYPE_STANDARD = 1
export const ECODRY_TYPE_SELF_DRAINING = 2
export const ECODRY_TYPE_SIMPLE_SELF_DRAINING = 3

export const ECODRY_TYPES = [
  { id: ECODRY_TYPE_NA, name: 'NA' },
  { id: ECODRY_TYPE_STANDARD, name: 'STANDARD' },
  { id: ECODRY_TYPE_SELF_DRAINING, name: 'SELF DRAINING' },
  { id: ECODRY_TYPE_SIMPLE_SELF_DRAINING, name: 'SIMPLE SELF DRAINING' }
]


export const ECODRY_MODEL_3_4DK_S = 0
export const ECODRY_MODEL_3_4DK_P = 1
export const ECODRY_MODEL_3_4DK_D = 2
export const ECODRY_MODEL_3_4DK_DS = 3
export const ECODRY_MODEL_3_4DK_DP = 4
export const ECODRY_MODEL_LDK_C_1_3 = 5
export const ECODRY_MODEL_LDK_C_2_4 = 6
export const ECODRY_MODEL_LDK_S = 7
export const ECODRY_MODEL_LDK_D = 8

export const ECODRY_MODELS = [
  { id: ECODRY_MODEL_3_4DK_S, name: '3DK-4DK S' },
  { id: ECODRY_MODEL_3_4DK_P, name: '3DK-4DK P' },
  { id: ECODRY_MODEL_3_4DK_D, name: '3DK-4DK D' },
  { id: ECODRY_MODEL_3_4DK_DS, name: '3DK-4DK DS' },
  { id: ECODRY_MODEL_3_4DK_DP, name: '3DK-4DK DP' },
  { id: ECODRY_MODEL_LDK_C_1_3, name: 'LDK-C 1/3' },
  { id: ECODRY_MODEL_LDK_C_2_4, name: 'LDK-C 2/4' },
  { id: ECODRY_MODEL_LDK_S, name: 'LDK-S' },
  { id: ECODRY_MODEL_LDK_D, name: 'LDK-D' }
]


export const ECODRY_CONTROL_SENSOR_TsA = 0
export const ECODRY_CONTROL_SENSOR_TsE = 1
export const ECODRY_CONTROL_SENSOR_TsR = 2

export const ECODRY_CONTROL_SENSORS = [
  { id: ECODRY_CONTROL_SENSOR_TsA, name: 'TsA' },
  { id: ECODRY_CONTROL_SENSOR_TsE, name: 'TsE' },
  { id: ECODRY_CONTROL_SENSOR_TsR, name: 'TsR' }

]


export const ECODRY_ADIABATIC_MODE_NA = 0
export const ECODRY_ADIABATIC_MODE_ADIABATIC = 1
export const ECODRY_ADIABATIC_MODE_SPRAY = 2

export const ECODRY_ADIABATIC_MODES = [
  { id: ECODRY_ADIABATIC_MODE_NA, name: 'NA' },
  { id: ECODRY_ADIABATIC_MODE_ADIABATIC, name: 'ADIABATIC' },
  { id: ECODRY_ADIABATIC_MODE_SPRAY, name: 'SPRAY' }
]

export const UCP_3DK = 0
export const UCP_4DK = 1
export const UCP_LDKv0 = 2
export const UCP_LDKv1 = 3

export const UCP_MODELS = [
  { id: UCP_3DK, name: '3DK' },
  { id: UCP_4DK, name: '4DK' },
  { id: UCP_LDKv0, name: 'LDKv0' },
  { id: UCP_LDKv1, name: 'LDKv1' }
]

export const FAN_EBM = 0
export const FAN_ZIEHL = 1
export const FAN_VIP = 2
export const FAN_0_10V = 3
export const FAN_AC = 4
export const FAN_DUNLI = 5


export const UCP_FANS_TYPES = [
  { id: FAN_EBM, name: 'EBM' },
  { id: FAN_ZIEHL, name: 'ZIEHL' },
  { id: FAN_VIP, name: 'VIP' },
  { id: FAN_0_10V, name: '0-10V' },
  { id: FAN_AC, name: 'AC' },
  { id: FAN_DUNLI, name: 'DUNLI' }
]


export const UCP_BOOSTER_MODE = [
  { id: 0, name: 'NA' },
  { id: 1, name: '1 STAGE' },
  { id: 2, name: '2 STAGES' },
  { id: 3, name: '3 STAGES' }
]

export const CC_CONTROL_LOGIC_DISABLED = 0
export const CC_CONTROL_LOGIC_SEQUENTIAL = 1
export const CC_CONTROL_LOGIC_PARALLEL = 2
export const CC_CONTROL_LOGIC_SCROLL = 3
export const CC_CONTROL_LOGIC_HYBRID = 4

export const CENTRAL_CHILLER_CONTROL_LOGICS = [
  { id: CC_CONTROL_LOGIC_DISABLED, name: 'DISABLED' },
  { id: CC_CONTROL_LOGIC_SEQUENTIAL, name: 'SEQUENTIAL' },
  { id: CC_CONTROL_LOGIC_PARALLEL, name: 'PARALLEL' },
  { id: CC_CONTROL_LOGIC_SCROLL, name: 'SCROLL' },
  { id: CC_CONTROL_LOGIC_HYBRID, name: 'HYBRID' }

]


export const CC_CONTROL_SENSOR_TsH = 0
export const CC_CONTROL_SENSOR_TsB = 1
export const CC_CONTROL_SENSOR_TsC = 2

export const CENTRAL_CHILLER_CONTROL_SENSORS = [
  { id: CC_CONTROL_SENSOR_TsH, name: 'TsH' },
  { id: CC_CONTROL_SENSOR_TsB, name: 'TsB' },
  { id: CC_CONTROL_SENSOR_TsC, name: 'TsC' }

]

export const FREE_COOLING_DISABLE = 0
export const FREE_COOLING_SET_POINT = 1
export const FREE_COOLING_DELTA = 2

export const CHILLER_BOX_FC_MODE = [
  { id: FREE_COOLING_DISABLE, name: 'DISABLE' },
  { id: FREE_COOLING_SET_POINT, name: 'SET POINT' },
  { id: FREE_COOLING_DELTA, name: 'DELTA' }
]

export const SERIAL_CHILLER_COMPRESSOR_SCREW = 0
export const SERIAL_CHILLER_COMPRESSOR_SCROLL = 1

export const SERIAL_CHILLER_COMPRESSOR_TYPES = [
  { id: SERIAL_CHILLER_COMPRESSOR_SCREW, name: 'SCREW' },
  { id: SERIAL_CHILLER_COMPRESSOR_SCROLL, name: 'SCROLL' }
]


export const SERIAL_CHILLER_PUMP_NA = 0
export const SERIAL_CHILLER_PUMP_BITZER_CSVH = 1
export const SERIAL_CHILLER_PUMP_ANALOG_CONTROL = 2
export const SERIAL_CHILLER_PUMP_ABB_ACS580 = 3
export const SERIAL_CHILLER_PUMP_ABB_ACS580_ANALOG = 4

export const SERIAL_CHILLER_PUMPS = [
  { id: SERIAL_CHILLER_PUMP_NA, name: 'NA' },
  { id: SERIAL_CHILLER_PUMP_BITZER_CSVH, name: 'BITZER CSVH' },
  { id: SERIAL_CHILLER_PUMP_ANALOG_CONTROL, name: 'ANALOG CONTROL' },
  { id: SERIAL_CHILLER_PUMP_ABB_ACS580, name: 'ABB ACS580' },
  { id: SERIAL_CHILLER_PUMP_ABB_ACS580_ANALOG, name: 'ABB ACS580 ANALOG' }

]

export const SERIAL_CHILLER_GAS_R407C = 0
export const SERIAL_CHILLER_GAS_R22 = 1
export const SERIAL_CHILLER_GAS_R134A = 2
export const SERIAL_CHILLER_GAS_R404A = 3
export const SERIAL_CHILLER_GAS_R410A = 4
export const SERIAL_CHILLER_GAS_R513A = 5

export const SERIAL_CHILLER_GAS = [
  { id: SERIAL_CHILLER_GAS_R407C, name: 'R407C' },
  { id: SERIAL_CHILLER_GAS_R22, name: 'R22' },
  { id: SERIAL_CHILLER_GAS_R134A, name: 'R134A' },
  { id: SERIAL_CHILLER_GAS_R404A, name: 'R404A' },
  { id: SERIAL_CHILLER_GAS_R410A, name: 'R410A' },
  { id: SERIAL_CHILLER_GAS_R513A, name: 'R513A' }
]


export const SERIAL_CHILLER_ADAPTIVE_LOAD_NA = 0
export const SERIAL_CHILLER_ADAPTIVE_LOAD_HGB_STANDALONE = 1
export const SERIAL_CHILLER_ADAPTIVE_LOAD_HGB_LINEAR = 2

export const SERIAL_CHILLER_ADAPTIVE_LOAD = [
  { id: SERIAL_CHILLER_ADAPTIVE_LOAD_NA, name: 'NA' },
  { id: SERIAL_CHILLER_ADAPTIVE_LOAD_HGB_STANDALONE, name: 'HGB STANDALONE' },
  { id: SERIAL_CHILLER_ADAPTIVE_LOAD_HGB_LINEAR, name: 'HGB LINEAR' }
]

export const SERIAL_CHILLER_COOLING_MEDIUM_AIR = 0
export const SERIAL_CHILLER_COOLING_MEDIUM_WATER = 1

export const SERIAL_CHILLER_COOLING_MEDIUM = [
  { id: SERIAL_CHILLER_COOLING_MEDIUM_AIR, name: 'AIR' },
  { id: SERIAL_CHILLER_COOLING_MEDIUM_WATER, name: 'WATER' }
]


export const SERIAL_CHILLER_EEV_TYPE_NA = 0
export const SERIAL_CHILLER_EEV_TYPE_ANALOG_OUTPUT = 1
export const SERIAL_CHILLER_EEV_TYPE_PEMS_COM1 = 2
export const SERIAL_CHILLER_EEV_TYPE_MODBUS_COM3 = 3
export const SERIAL_CHILLER_EEV_TYPE_MODBUS_CAREL_EVD_COM3 = 4
export const SERIAL_CHILLER_EEV_TYPE_OB2_STANDALONE_COM3 = 5
export const SERIAL_CHILLER_EEV_TYPE_CAREL_EVD_MONO_COM3 = 6

export const SERIAL_CHILLER_EEV_TYPES = [
  { id: SERIAL_CHILLER_EEV_TYPE_NA, name: 'NA' },
  { id: SERIAL_CHILLER_EEV_TYPE_ANALOG_OUTPUT, name: 'ANALOG OUTPUT' },
  { id: SERIAL_CHILLER_EEV_TYPE_PEMS_COM1, name: 'PEMS COM1' },
  { id: SERIAL_CHILLER_EEV_TYPE_MODBUS_COM3, name: 'MODBUS COM3' },
  { id: SERIAL_CHILLER_EEV_TYPE_MODBUS_CAREL_EVD_COM3, name: 'MODBUS CAREL EVD COM3' },
  { id: SERIAL_CHILLER_EEV_TYPE_OB2_STANDALONE_COM3, name: 'OB2 STANDALONE COM3' },
  { id: SERIAL_CHILLER_EEV_TYPE_CAREL_EVD_MONO_COM3, name: 'CAREL EVD MONO COM3' }
]


export const CIRCUIT_A = 'A'
export const CIRCUIT_B = 'B'
export const CIRCUIT_C = 'C'
export const CIRCUIT_D = 'D'
export const CIRCUIT_R = 'R'

export const CIRCUIT_TYPE_ON_OFF = 0
export const CIRCUIT_TYPE_ABB_ACS310 = 1
export const CIRCUIT_TYPE_SIEMENS_V20 = 2
export const CIRCUIT_TYPE_ABB_ACS580 = 3

export const CIRCUIT_TYPES = [
  { id: CIRCUIT_TYPE_ON_OFF, name: 'ON/OFF' },
  { id: CIRCUIT_TYPE_ABB_ACS310, name: 'ABB ACS310' },
  { id: CIRCUIT_TYPE_SIEMENS_V20, name: 'SIEMENS V20' },
  { id: CIRCUIT_TYPE_ABB_ACS580, name: 'ABB ACS580' }
]

export const CIRCUIT_DUTY_NA = 0
export const CIRCUIT_DUTY_COMPONENT = 1
export const CIRCUIT_DUTY_CIRCUIT = 2

export const CIRCUIT_DUTY = [
  { id: CIRCUIT_DUTY_NA, name: 'NA' },
  { id: CIRCUIT_DUTY_COMPONENT, name: 'COMPONENT' },
  { id: CIRCUIT_DUTY_CIRCUIT, name: 'CIRCUIT' }
]

export const CIRCUIT_CONTROL_TYPE_PRESSURE = 0
export const CIRCUIT_CONTROL_TYPE_FLOW = 1

export const CIRCUIT_CONTROL_TYPES = [
  { id: CIRCUIT_CONTROL_TYPE_PRESSURE, name: 'PRESSURE' },
  { id: CIRCUIT_CONTROL_TYPE_FLOW, name: 'FLOW' }

]

export const OPEN_NO_TANK = 0
export const OPEN_WITH_TANK = 1
export const PRESSURIZED = 2

export const TANK_TYPES = [
  { id: OPEN_NO_TANK, name: 'OPEN NO TANK' },
  { id: OPEN_WITH_TANK, name: 'OPEN WITH TANK' },
  { id: PRESSURIZED, name: 'PRESSURIZED' }

]