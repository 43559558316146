import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import {
  InputGroup,
  Input,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Stack,
  IconButton,
  Box,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react';
import {
  SearchIcon,
  ChevronDownIcon,
  TriangleRightIcon,
  TriangleLeftIcon,
  TriangleDoubleIcon,
  TriangleUpIcon,
  TriangleDownIcon
} from '@/shared/icons';
import { secondaryBtnStyle } from '@/shared/theme'
import { useIsMobile } from '../../../hooks';



export const SizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange, colorMode, customSizePerPage }) => {

  const secondaryBtnStyleValues = secondaryBtnStyle({colorMode})
  useEffect(()=>{ 
    onSizePerPageChange(customSizePerPage)
  },[customSizePerPage])

  return (
    <Menu>
      <MenuButton
        px={4}
        py={2}
        h='full'
        mr={2}
        fontSize="sm"
        transition="all 0.2s"
        borderRadius="md"
        _focus={{ outline: 0, boxShadow: 'outline' }}
        {...secondaryBtnStyleValues}
      >
        {currSizePerPage} <ChevronDownIcon />
      </MenuButton>
      <MenuList>
        {options.map(({ text, page }) => {
          return (
            <MenuItem
              key={text}
              _hover={secondaryBtnStyleValues._hover}
              _focus={secondaryBtnStyleValues._hover}
              onClick={() => onSizePerPageChange(page)}
            >
              {text}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};



export const PaginationListRenderer = ({
  paginationProps,
  showFirstPage = false,
  showLastPage = false
}) => {
  const { totalSize, sizePerPage, onPageChange } = paginationProps;

  const pa = paginationProps.pages

  let p = totalSize / sizePerPage;
  // if it's not a integer add one and make it an integer
  p = p % 1 != 0 ? parseInt(p + 1) : p;
  // Handles not row case
  p = p === 0 ? 1 : p;

  const pages = range(1, p + 1);

  let activePage = pa.filter(p => p.active === true)
  const page = activePage.length > 0 ? activePage[0].page : 1

  const btnStyle = {
    bgColor: 'unset',
    size: 'xs',
    _hover: {
      bgColor: useColorModeValue(
        'frigel.neutral.darkGray',
        'frigel.blueAccent'
      ),
      color: 'whiteAlpha.900'
    }
  };

  const pageIndex = page - 1;
  const prevPage = pages[pageIndex - 1];
  const currPage = pages[pageIndex];
  const nextPage = pages[pageIndex + 1];
  const lastPage = pages[pages.length - 1];
  const thirdLastPage = pages[pages.length - 3];

  return (
    <Stack
      direction="row"
      spacing="2"
      bgColor={useColorModeValue('frigel.neutral.gray3', 'frigel.dark.blue1')}
      p={2}
      borderRadius="md"
      width="fit-content"
    >
      <IconButton
        {...btnStyle}
        fontSize="xl"
        disabled={page === pages[0]}
        onClick={() => onPageChange(page - 1)}
        icon={<TriangleLeftIcon />}
      ></IconButton>

      {/* Handle case of last page */}
      {showFirstPage && page >= pages[3] && (
        <>
          <Button {...btnStyle} onClick={() => onPageChange(1)}>
            {1}
          </Button>
          <Box as="span">...</Box>
        </>
      )}

      {!nextPage && thirdLastPage && (
        <Button {...btnStyle} onClick={() => onPageChange(thirdLastPage)}>
          {thirdLastPage}
        </Button>
      )}

      {prevPage && (
        <Button {...btnStyle} onClick={() => onPageChange(prevPage)}>
          {prevPage}
        </Button>
      )}
      <Button
        {...btnStyle}
        bgColor={useColorModeValue(
          'frigel.neutral.darkGray',
          'frigel.blueAccent'
        )}
        color="whiteAlpha.900"
        onClick={() => onPageChange(currPage)}
      >
        {currPage}
      </Button>

      {nextPage && (
        <Button {...btnStyle} onClick={() => onPageChange(nextPage)}>
          {nextPage}
        </Button>
      )}

      {/* Handle case of fist page */}
      {!prevPage && pages[pageIndex + 2] && (
        <Button
          {...btnStyle}
          onClick={() => onPageChange(pages[pageIndex + 2])}
        >
          {pages[pageIndex + 2]}
        </Button>
      )}


      {showLastPage && pages[pageIndex + 2] != lastPage && page < thirdLastPage && (
        <Box as="span">...</Box>
      )}

      {showLastPage && nextPage != lastPage && page < thirdLastPage && (
        <Button {...btnStyle} onClick={() => onPageChange(lastPage)}>
          {lastPage}
        </Button>
      )}

      <IconButton
        {...btnStyle}
        fontSize="xl"
        disabled={page === pages.length}
        onClick={() => paginationProps.onPageChange(page + 1)}
        icon={<TriangleRightIcon />}
      ></IconButton>
    </Stack>
  );
};


export const SearchBar = ({ searchProps, ...restProps }) => {
  const { t } = useTranslation(['general']);

  return (
    <InputGroup size="sm" >
      <InputRightElement children={<SearchIcon color="gray.300" />} />
      <Input borderRadius='md' borderWidth={2}
        placeholder={t('general:Search', 'Search')}
        onChange={(e) => searchProps.onSearch(e.target.value)}
        {...restProps}
      />
    </InputGroup>
  );
};


export const SorterIcon = ({col, components}) => {

  const isMobile = useIsMobile()
  if (isMobile){
    return null
  }

  if (!col.sort){
    return null
  }
  const {props:sortElementProps={}} = components.sortElement
  const order = sortElementProps.order? sortElementProps.order : ''

  if (!order){
    return <TriangleDoubleIcon />
  }

  return order === 'asc'? <TriangleUpIcon/>  : <TriangleDownIcon/>

}