import React from 'react'
import { Alert, Box,
  Grid,
  FormControl,
  FormLabel,
  Input,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  Image,
  VStack,
  LightMode
 } from '@chakra-ui/react'
import { useAuth } from '../contexts/auth'
import {Button} from '@/shared/components/core/Button'
import { CDN_PATH } from '../consts'


const Login = (props) => {

  const {login, statusText, warningMsg} = useAuth()

  const handleSubmit = (e) => {
    e.preventDefault()
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;
    login({username, password})
  }

  return (
    <LightMode>
    <Grid alignContent='center' justifyContent='center' bgColor='frigel.light.blue'
      w='100vw' h='100vh' color='frigel.neutral.darkGray'  >

      <Box bg='white' p={6} boxShadow='md' borderRadius={6}>
        <Box mt={2}>
          <Image mx='auto' width='200px' src={`${CDN_PATH}/statics/images/hub-logo.svg`}/>
        </Box >
        <Box as='section' mt={4}>
        {(statusText || props.lock) &&
          <>
            {/* {retriesMsg &&
              <Alert status='info' mt='4'>
                <AlertIcon />
                <AlertTitle>{retriesMsg}</AlertTitle>
              </Alert>
            } */}
            {(statusText && !props.lock) &&
              <Alert status='error' mt='4'>
              <AlertIcon />
              <AlertDescription>{statusText}</AlertDescription>
            </Alert>
            }
            {props.lock &&
              <Alert status='error' mt='4'>
                <AlertIcon />
                <AlertTitle>
                  <CountDown
                    preMsg='You can try again after '
                    duration={props.lockDuration}/>
                </AlertTitle>
              </Alert>
            }
          </>
          }
        {warningMsg &&
          <Alert status='warning'>
              {warningMsg}
            </Alert>
          }
      </Box>
      <VStack as='form' onSubmit={handleSubmit} mt='4' id='loginForm' >
        <FormControl>
          <FormLabel htmlFor="username">Username</FormLabel>
          <Input id="username" disabled={props.lock}
            placeholder="Username"
            borderColor='gray.200'
            _placeholder={{color: 'gray.400'}}
          />
        </FormControl>
        <FormControl >
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input type="password" id="password" disabled={props.lock}
            placeholder="****************"
            borderColor='gray.200'
            _placeholder={{color: 'gray.400'}}
          />
        </FormControl>

      </VStack>
        <Button mt={6}
          form='loginForm'
          w='full'
          variant='primary'
          type="submit"
          disabled={props.lock}>
          Login
        </Button>
      </Box>
    </Grid>
  </LightMode>
  )
}

export default Login