import React, { useEffect, useState } from "react";
import {
  Box, useColorMode, useToast, Text,
  Tooltip, HStack, Flex, Spacer, Center,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  Heading, Image,
  Spinner
} from "@chakra-ui/react";
import { formatDistance } from "date-fns";
import { handleMindLogin } from "@/api/minds";
import DataTable, {
  getUniformedColumns,
  SearchBar
} from "@/shared/components/DataTable";
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import { goOnMindFormatter, statusFormatter } from "@/utils/formatters";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import './Minds.css'
import { useHistory } from "react-router-dom";
import { useMinds } from "../../contexts/minds";
import { OpenWindowIcon, LoginIcon, ChevronDownIcon } from "../../shared/icons";
import { Button } from '@/shared/components/core/Button'
import { useIsMobile, useIsTablet } from "../../hooks";
import { mindsTableCommonColumnProps } from "../../components/elements";
import FavoriteMind from "../../components/FavoriteMind";
import { INSTALLER, PAGEVIEWS_OPTIONS, PAGEVIEW_MAP, PAGEVIEW_WITHOUT_MAP, CDN_PATH } from "../../consts";
import { useTranslation } from "react-i18next";

import { useAuth } from '../../contexts/auth'
import { ViewPage } from "./elements"
import Machines from "./Machines";
import Systems from "./Systems";


const Minds = (props) => {
  const [mindsMarkers, setMindsMarkers] = useState([])
  const { colorMode } = useColorMode();
  const toast = useToast()
  const [position, setPosition] = useState([15, 11.154610])
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const [zoom, setZoom] = useState(isMobile === true ? 1 : 1.5)
  const history = useHistory()
  const { t } = useTranslation(['general', 'minds'])
  const { currentUserMinds, isFetching } = useMinds()
  const { user } = useAuth()
  const { preferences: { pageView: userPageView = PAGEVIEW_MAP } } = user
  const [pageView, setPageView] = useState(userPageView)
  const [selectedOption, setSelectedOption] = useState('MiNDs')

  useEffect(() => {
    if (isFetching === false) {
      if (mindsMarkers.length == 0) {
        setMindsMarkers(currentUserMinds)
      }
      if (currentUserMinds.length > 0 && currentUserMinds.length <= 5) {
        setPosition([currentUserMinds[0].company_latitude, currentUserMinds[0].company_longitude])
        setZoom(zoom * 2.5)
      }
    }
  }, [isFetching])

  const columnsProps = {
    ...mindsTableCommonColumnProps(isMobile, isTablet, t),
    status: {
      text: t('general:status', 'status').toCapitalCase(),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row, user, colorMode, true, t),
    },
    goOnMind: {
      isDummyField: true,
      text: `${t('minds:login', 'login').toCapitalCase()}`,
      formatter: (cell, row) =>
        goOnMindFormatter(cell, row, () => handleMindLogin(row.mid, row.serial, row.mind_url, toast, t), colorMode),
    },
    info: {
      isDummyField: true,
      text: t('general:info', 'info').toCapitalCase(),
      formatter: (cell, row) => (
        <OpenWindowIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => history.push(`/minds/${row.id}`)}
        />
      ),
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);
  const afterSearch = (newResult, data) => {
    setMindsMarkers(newResult)
  };

  return (
    <Box as='main' px={{ base: 4, md: 16 }} py={10} mt={0}>
      <Flex justify='space-between'>
        {user.level.id >= INSTALLER && currentUserMinds.length > 0 ?
          <Flex justifyContent='space-between'>
            <Menu >
              <MenuButton borderRadius={6} textAlign={2}>
                <Flex justifyContent='space-between'>
                  <Heading as='h1' mb={12}><strong>{selectedOption}</strong><ChevronDownIcon /></Heading>
                </Flex>
              </MenuButton>
              <MenuList size='xs' marginTop={-50}>
                <MenuItem onClick={() => setSelectedOption('MiNDs')}>
                  {"MiNDs"}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setSelectedOption('Machines')}>
                  {"Machines"}
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => setSelectedOption('Systems')}>
                  {"Systems"}
                </MenuItem>
              </MenuList>
            </Menu>
            {isFetching && <Spinner size='md' mt={3} />}
          </Flex> :
          <Flex justifyContent='space-between'>
            <Heading as='h1' mb={12}><strong>{selectedOption}</strong></Heading>
            {isFetching && <Spinner size='md' ml={2} mt={3} />}
          </Flex>}

        <Image mt={1} alignSelf='start' width='120px' src={`${CDN_PATH}/statics/images/frigel-full-logo-${colorMode}.svg`} />
      </Flex>
      {selectedOption === 'Machines' ? <Machines /> :
        selectedOption === 'Systems' ? <Systems /> :
          <Box>
            <DataTable
              data={currentUserMinds}
              columns={columns}
              customSizePerPage={PAGEVIEWS_OPTIONS[pageView].value}
              noDataIndication={isFetching && currentUserMinds ? <FullPageSpinner /> : <Center>{t('general:NoData', "No data to display")}</Center>}
              _afterSearch={afterSearch}
              toolbar={(props) =>
                <>
                  <Flex >
                    <Box width={{ base: '100%', md: '30%' }} >
                      <SearchBar searchProps={props.searchProps} mb={6} />
                    </Box>
                    <Spacer />
                    <ViewPage
                      props={props}
                      defaultValue={PAGEVIEWS_OPTIONS[pageView].text}
                      onChange={() => pageView == PAGEVIEW_MAP ? setPageView(PAGEVIEW_WITHOUT_MAP) : setPageView(PAGEVIEW_MAP)} />
                  </Flex>
                  {pageView === PAGEVIEW_MAP &&
                    <MapContainer center={position} zoom={zoom} scrollWheelZoom={false}
                      style={{ height: isMobile ? '350px' : '500px' }}>
                      <TileLayer
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors'
                      />
                      {mindsMarkers.map(mind => (
                        <Marker
                          key={mind.id} position={[mind.company_latitude, mind.company_longitude]}>
                          <Popup>
                            <HStack>
                              <FavoriteMind mindId={mind.id} fontSize='sm' />
                              <Tooltip placement='top' label={
                                <Box>
                                  <Box>{t('general:status', 'status').toCapitalCase()}: {mind.status != 'offline' ? 'online' : 'offline'} </Box>
                                  <Box>{t('general:serial', 'serial').toCapitalCase()}: {mind.serial} </Box>
                                  <Box>{t('general:country', 'country').toCapitalCase()}: {mind.company_country.name} </Box>
                                  <Box>{t('general:last-seen', 'last seen').toCapitalCase()}: {formatDistance(new Date(mind.last_seen), new Date())} </Box>
                                </Box>
                              }>
                                <Box>
                                  {statusFormatter(mind.status, mind, user, colorMode, false, t)}
                                  <Text px={2} textDecor='underline' as='strong'>{mind.company_name}</Text>
                                </Box>
                              </Tooltip>
                            </HStack>
                            <br />
                            <Button isFullWidth={true}
                              // TODO: Not sure 100%
                              variant='primary'
                              rightIcon={<LoginIcon />}
                              size='xs' onClick={() => handleMindLogin(mind.mid, mind.serial, mind.mind_url, toast, t)}
                              isDisabled={mind.status != 'online' && mind.status['num_vpn'] === mind.status['warning']?.length}>
                              Login
                            </Button>
                          </Popup>
                        </Marker>
                      ))}
                    </MapContainer>
                  }
                </>
              }
            />
          </Box>
      }
    </Box>
  );
};

export default Minds;