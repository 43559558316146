import React from 'react';
import { Box, Divider, Flex, Heading, HStack, Text, Badge, Tooltip } from '@chakra-ui/react'
import ResponsiveText from '../shared/components/ResponsiveText';
import { useUser } from '../contexts/auth';
import FavoriteMind from './FavoriteMind';
import { useTranslation } from 'react-i18next';
import { startCase } from 'lodash';
import { CircleIcon } from "../shared/icons";
import { mode } from '@/shared/theme'
import { formatDistance } from "date-fns";

export const StaticRow = ({ label, content, labelProps = {}, ...rest }) => (
  <Flex py='2' w='full' {...rest}>
    <Text as='strong' w='30%' {...labelProps}>{label}</Text>
    {content}
  </Flex>
)
export const SectionWithTitle = ({ children, title, rightHeadingChild = '', ...rest }) => (
  <Box as='section' mb='16' {...rest}>
    <Flex justify='space-between' >
      <Heading as='h2' size='md'>{title}</Heading>
      {rightHeadingChild}
    </Flex>
    <Divider mb='4' />
    {children}
  </Box>
)


export function activeFormatter(cell, row, colorMode) {
  if (!row) {
    return cell;
  }

  if (cell) {
    return <CircleIcon fontSize='xl' color={`frigel.${colorMode}.status.online`} />
  } else {
    return <CircleIcon fontSize='xl' color={`frigel.${colorMode}.status.alarm`} />
  }

}

const SerialFormatter = ({ col, row }) => {
  const { t } = useTranslation(['general'])
  const user = useUser()
  const { preferences: { favoriteMinds = [], customizeMinds = {} } } = user
  const nickname = (customizeMinds[row.id] && customizeMinds[row.id].nickname) || ''
  return (
    <HStack>
      {/* XXX: In this way it's used only for minds table (pretty bad way=> improve it) */}
      {row.mid && <FavoriteMind mindId={row.id} />}
      <ResponsiveText compress={'20ch'} label={
        <>
          <Box>{t('general:serial', 'serial').toCapitalCase()}: {col}</Box>
          {nickname && <Box>{t('general:nickname', "nickname").toCapitalCase()}: {nickname}</Box>}
        </>
      }>
        {nickname ? nickname : col}
      </ResponsiveText>
    </HStack>

  )
}

const commonColumnProps = (isMobile, isTablet, t = () => '') => ({
  company: {
    sort: false, text: t('general:company', 'company').toCapitalCase(),
    align: 'left',
    headerAlign: 'left',
    formatter: (col, row) => {
      // FIXME: Look like it does not switch well...
      return (
        !(isMobile || isTablet) ? (
          <ResponsiveText compress={'30ch'} tooltipProps={{ placement: 'bottom-start' }} >
            {col}
          </ResponsiveText>
        ) : (
          <ResponsiveText>
            {col}
          </ResponsiveText>
        )
      )
    }
  },
  frigel_branch: {
    text: t('general:branch', 'branch').toCapitalCase(),
    hidden: isMobile || isTablet
  },
})



export const mindsTableCommonColumnProps = (isMobile, isTablet, t = () => '') => {
  const columnsProps = commonColumnProps(isMobile, isTablet, t);
  return {
    id: { hidden: true },
    mid: { hidden: true, isKey: true },
    nickname: { hidden: true },
    serial: {
      sort: true,
      text: isMobile || isTablet ? t('general:serial-no', 'Serial No.') : t('general:serial-number', 'Serial number'),
      align: 'left',
      headerAlign: 'left',
      // formatter: (col, row) => { return <span style={{display: 'block', width: 100, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{col}</span>}
      formatter: (col, row) => <SerialFormatter col={col} row={row} />
    },
    company_name: columnsProps.company,
    "company_country.name": {
      text: t('general:country', 'country').toCapitalCase(), hidden: isMobile || isTablet,
      align: 'left',
      headerAlign: 'left',
      formatter: (col, row) => (
        <ResponsiveText compress={'10ch'}>
          {col}
        </ResponsiveText>
      )
    },
    app_version: { hidden: isMobile },
    frigel_branch: columnsProps.frigel_branch,
    machines: { hidden: true }
  };
};

export const usersTableCommonColumnProps = (isMobile, isTablet, t) => {
  const columnsProps = commonColumnProps(isMobile, isTablet, t);

  return {
    id: { hidden: true, isKey: true },
    username: { hidden: true },
    fullname: {
      text: t('general:fullname', 'fullname').toCapitalCase(),
      headerAlign: 'left',
      align: 'left',
      sort: true
    },
    email: {
      text: t('general:email', 'Email').toCapitalCase(),
      headerAlign: 'left',
      align: 'left',
      formatter: (col, row) => <ResponsiveText compress={100}>{col}</ResponsiveText>
    },
    company: columnsProps.company,
    frigel_branch: columnsProps.frigel_branch,
    "level.name": {
      text: t('general:permission', 'permission').toCapitalCase(),
    },
  };
};


export const machinesTableCommonColumnProps = (isMobile, isTablet, t, colorMode) => {
  const textColor = mode('frigel.neutral.white', 'frigel.dark.black')({ colorMode })
  return {
    id: { hidden: true, isKey: true },
    serial: { text: t('general:serial-number', 'Serial number'), sort: true, headerAlign: 'left', align: 'left' },
    machine_model: {
      text: t('general:model', 'model').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    status: {
      text: `${t('general:Status', 'status').toCapitalCase()} (${t('general:last-seen', 'last seen')})`,
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) =>
      (cell['general_status'] ?
        <HStack justifyContent="center">
          <Badge bgColor={cell['general_status'] ? `frigel.${colorMode}.status.${cell['general_status']}` : 'frigel.neutral.gray1'} borderRadius="md"
            color={cell['general_status'] == 'offline' ? textColor : 'frigel.neutral.white'} p={1}>
            {/* i18next-extract-disable-next-line */}
            {t(`machines:${cell['general_status'].toLowerCase().toCapitalCase()}`, cell['general_status'])}
          </Badge>
          {row['last_seen'] &&
            <>
              <Tooltip label={row['last_seen'].split('T')[0].concat(' ', row['last_seen'].split('T')[1])}>
                <Text fontSize='sm'>{`(${formatDistance(new Date(row['last_seen']), new Date())})`}</Text>
              </Tooltip>
            </>}
        </HStack> : <Text>{'-'}</Text>)
    },
  }
}