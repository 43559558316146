import React from "react";
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { setupAxios } from "./api";
import App from "./app";
import AppProviders from "./app-providers";
import './prototypes';
import './global.css'

setupAxios()


createRoot(document.getElementById('app')).render(<BrowserRouter>
  <AppProviders>
    <App />
  </AppProviders>
</BrowserRouter>)
