import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import theme from '@/shared/theme'

import { AuthProvider } from './contexts/auth';
import { MindsProvider, LatestReleaseProvider } from './contexts/minds';
import { UsersProvider } from './contexts/users';
import { ReportsProvider } from './contexts/reports';
import { MachinesProvider } from './contexts/machines';


function AppProviders({ children }) {

  return (
    <AuthProvider>
      <MindsProvider>
        <UsersProvider>
          <ReportsProvider>
            <LatestReleaseProvider>
              <MachinesProvider>
                <ChakraProvider theme={theme}>
                  {children}
                </ChakraProvider>
              </MachinesProvider>
            </LatestReleaseProvider>
          </ReportsProvider>
        </UsersProvider>
      </MindsProvider>
    </AuthProvider>
  );
}

export default AppProviders;