import React, { useEffect, useState } from "react";
import { useMinds } from "../../contexts/minds";
import FullPageSpinner from "@/shared/components/FullPageSpinner";
import ModalSkeleton from "@/shared/components/ModalSkeleton";
import ConfirmAlert from "../../components/ConfirmDeleteAlert";
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "../../shared/components/DataTable";
import SelectionDataTable from "../../shared/components/SelectionDataTable";
import ResponsiveText from "@/shared/components/ResponsiveText";
import {
  ArrowLoop,
  EditIcon,
  InfoIcon,
  TimeIcon,
  CheckedIcon,
  UnCheckedIcon
} from "../../shared/icons";
import {
  Box,
  ModalBody,
  useColorMode,
  useDisclosure,
  Flex,
  ModalFooter,
  HStack,
  useToast,
  Badge,
  Tooltip,
  Checkbox,
  Text,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  IconButton,
} from "@chakra-ui/react";
import { useUsers } from "../../contexts/users";
import { ADMIN, MANAGER, STANDARD_ERROR_MSG, TOAST_POSITION } from "../../consts";
import { useHistory, useParams } from "react-router-dom";
import { useData, useIsMobile, useIsTablet } from "../../hooks";
import {Button} from '@/shared/components/core/Button'
import axios from "axios";
import { useUser } from "../../contexts/auth";
import {
  mindsTableCommonColumnProps,
  usersTableCommonColumnProps,
} from "../../components/elements";
import { capitalize, startCase } from "lodash";
import { useTranslation } from "react-i18next";

export const MindsTable = ({ onEditClick, simpleMode }) => {
  const { currentUserMinds } = useMinds();

  const { mindId } = useParams();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const {t} = useTranslation(['general', 'notifications'])

  useEffect(() => {
    if ( mindId) {
      const searchMind = currentUserMinds.filter((m) => m.id == mindId);
      if (searchMind.length !== 0) {
        onEditClick(searchMind[0]);
      }
    }
  }, []);

  let mindsColumnsProps = mindsTableCommonColumnProps(isMobile, isTablet, t);
  delete mindsColumnsProps.status;
  delete mindsColumnsProps["company_country.name"];

  const columnsProps = {
    ...mindsColumnsProps,
    has_notifications: {
      text: t('notifications:machines-enabled', "Machines Enabled") ,
      hidden: !simpleMode,
      headerFormatter: (col, colIndex) => (
        <ResponsiveText>
          {col.text}
          <Tooltip label={t('notifications:machines-enabled-total', "Machine with notifications enabled / Total machines")}>
            <InfoIcon fontSize="sm" ml={2} mb={1} />
          </Tooltip>
        </ResponsiveText>
      ),
      formatter: (cell, row) => {
        return (
          <ResponsiveText>
            {`${cell.active_machines}/${cell.machines}`}
          </ResponsiveText>
        );
      },
    },
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => (
        <EditIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            onEditClick(row);
          }}
        />
      ),
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={currentUserMinds}
      columns={columns}
      toolbar={(props) => (
        <Box width={{ base: "100%", md: "30%" }}>
          <SearchBar searchProps={props.searchProps} />
        </Box>
      )}
    />
  );
};

export const UsersTable = ({ mind, onEditClick }) => {
  const { users, isFetching } = useUsers();
  const history = useHistory();
  const user = useUser();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet()
  const {t} = useTranslation(['general'])

  const mindRelatedUsers = [];
  // const mindNotRelatedUsers = []
  const { id } = mind;

  users.forEach((u) => {
    // `id` is mind id
    if (user.isManager){
      // Ignore other managers and admins
      if (u.id !== user.id && u.perms[+id] && (u.perms[+id].id === ADMIN || u.perms[+id].id === MANAGER)) {
        return;
      }
    } else if (user.isAdmin){
      // Ignore other admins
      if (u.id !== user.id && u.perms[+id] && u.perms[+id].id === ADMIN) {
        return;
      }
    }

    if (u.minds_related.includes(+id)) {
      u.mind_permission = u.perms[+id];
      mindRelatedUsers.push(u);
    }
  });

  const { userId } = useParams();
  useEffect(() => {
    if (!isFetching && userId) {
      const selectedUser = mindRelatedUsers.filter(
        (u) => u.profile_id == userId
      );
      if (selectedUser.length !== 0) {
        onEditClick(selectedUser[0]);
      }
    }
  }, [isFetching]);

  let userTableProps = usersTableCommonColumnProps(isMobile, isTablet, t);
  delete userTableProps["level.name"];
  const columnsProps = {
    ...userTableProps,
    "mind_permission.name": {
      text: t('general:permission', "permission").toCapitalCase(),
    },
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => (
        <EditIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            onEditClick(row);
            history.push(`/manage/notifications/${id}/${row.profile_id}`);
          }}
        />
      ),
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={mindRelatedUsers}
      columns={columns}
      toolbar={(props) => (
        <Box width={{ base: "100%", md: "30%" }}>
          <SearchBar searchProps={props.searchProps} />
        </Box>
      )}
    />
  );
};

export const MachinesTable = (props) => {
  const { mindId, userId } = useParams();
  if (!mindId || !userId) {
    return <Box></Box>;
  }

  const { data, isFetching } = useData({
    url: `/api/minds/${mindId}/machines/`,
    params : {'sender': 'notification'}
  });
  const { currentUserMinds } = useMinds();
  const {t} = useTranslation(['general', 'notifications'])
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const {
    data: notifications,
    isFetching: notificationIsFetching,
    refetch: refetchNotifications,
  } = useData({
    url: `/api/minds/${mindId}/users/${userId}/notifications/`,
  });


  const connectedNotificationEnabled = currentUserMinds?.filter((m) => m.id == mindId).map((m) => {
    if(m.app_version) {
      const capturingRegex = /^[^0-9]*(?<major>[0-9]*)\.+(?<medium>[0-9]*)\.+(?<minor>[0-9]*).*/
      const separate_code = m.app_version.match(capturingRegex)
      return separate_code?.groups['major']>1 && separate_code?.groups['medium']>3
    }
    return false
  })[0];

  if (isFetching || notificationIsFetching) {
    return <FullPageSpinner />;
  }

  const columnsProps = {
    serial: mindsTableCommonColumnProps(isMobile, isTablet, t).serial,
    machine_model: {
      text: t('general:model', 'model').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    alarms: {
      isDummyField: true,
      text: t('general:alarms', 'alarms').toCapitalCase(),
      formatter: (cell, row) => (
        <AddNotifications
          machine={row}
          mindId={mindId}
          userId={userId}
          notifications={notifications.filter((n) => n.machine === row.id)}
          type="alarms"
          refetchNotifications={refetchNotifications}
          columnsProps={{
            id: { hidden: true, isKey: true },
            code: { hidden: true },
            name: {},
            _type: { text: t('general:type', 'type').toCapitalCase()},
            description: {},
          }}
        />
      ),
    },
    setups: {
      isDummyField: true,
      text: t('general:setups', 'setups').toCapitalCase(),
      formatter: (cell, row) => (
        <AddNotifications
          machine={row}
          mindId={mindId}
          userId={userId}
          notifications={notifications.filter((n) => n.machine === row.id)}
          type="setups"
          refetchNotifications={refetchNotifications}
          columnsProps={{
            id: { hidden: true, isKey: true },
            code: { hidden: true },
            name: {},
            group: {},
            description: {},
          }}
        />
      ),
    },
    connected: {
      isDummyField: true,
      text: t('general:connected', 'connected').toCapitalCase(),
      formatter: (cell, row) => (
        <ConnectNotifications
          machine={row}
          mindId={mindId}
          userId={userId}
          notifications={notifications.filter((n) => n.machine === row.id)}
          type="connected"
          refetchNotifications={refetchNotifications}
          enabled={connectedNotificationEnabled}
          columnsProps={{
            id: { hidden: true, isKey: true },
            code: { hidden: true },
            name: {},
            group: {},
            description: {},
          }}
        />
      ),
    },
    delete: {
      text: "",
      formatter: (cell, row) => (
        <RemoveNotification
          machine={row}
          mindId={mindId}
          userId={userId}
          notifications={notifications.filter((n) => n.machine === row.id)}
          refetchNotifications={refetchNotifications}
        />
      ),
    },
  };

  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={data}
      columns={columns}
      toolbar={(props) => (
        <Flex justify="space-between">
          <Box width="30%">
            <SearchBar searchProps={props.searchProps} />
          </Box>
          <HStack>
            <EnableAllNotifications
              mindId={mindId}
              userId={userId}
              refetchNotifications={refetchNotifications}
            />
            <DisableAllNotifications
              mindId={mindId}
              userId={userId}
              refetchNotifications={refetchNotifications}
            />
          </HStack>
        </Flex>
      )}
    />
  );
};

const DEFAULT_FREQUENCY = {
  alarms: { appear: true, once_day: false, once_week: false },
  setups: { appear: true, once_day: false, once_week: false },
  connected: { appear: true, once_day: false, once_week: false },
};

const ConnectNotifications = ({
  machine,
  notifications,
  mindId,
  userId,
  type,
  refetchNotifications,
  enabled,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation(['general', 'notifications'])
  const defaultFreq = () =>
    notifications.length>0 && notifications[0]["frequency"]["connected"] ? 
      notifications[0]["frequency"]["connected"] : { appear: true, once_day: false, once_week: false }


  const [frequency, setFrequency] = useState(() => defaultFreq())
  const onModalClose = React.useCallback(() => {
    onClose();
    setFrequency(() => defaultFreq())
  });
  return (
    <>
      <Tooltip
        label={ enabled ?
          (notifications[0]?.notif_connected ? t('notifications:machine_active_status_notification','Machine status notification is active')
          : t('notifications:machine_disable_status_notification','Machine status notification is disabled'))
        : t('notifications:mind_version_no_support_notification','MiND version does not support status notification')}
      >
        <Box
          cursor={enabled ? "pointer" : 'not-allowed'}
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {if (enabled) onOpen()}}
        >
          {notifications[0]?.notif_connected ? <CheckedIcon fontSize={16}/> : <UnCheckedIcon fontSize={16}/>}
          {enabled && <EditIcon ml={2}/>}
        </Box>
        </Tooltip>
      <ModalSkeleton
        title={`${capitalize(type)} ${t('general:Notifications', 'Notifications')}`}
        isOpen={isOpen}
        onClose={onModalClose}
        size="3xl"
      >
        <ModalBody>
          <Box>
            <>
              <Box my={4}>
                <Text as="strong">{t('notifications:sending-frequency', 'Sending Frequency')}</Text>
                <HStack spacing={6} mt={1}>
                  <Checkbox
                    isChecked={frequency.appear}
                    onChange={(e) =>
                      setFrequency({ ...frequency, appear: !frequency.appear })
                    }
                  >
                    <Text fontSize="sm">{t('notifications:when-appears', 'When notification appears')}</Text>
                  </Checkbox>
                  <Checkbox
                    isChecked={frequency.once_day}
                    onChange={(e) =>
                      setFrequency({
                        ...frequency,
                        once_day: !frequency.once_day,
                      })
                    }
                  >
                    <Text fontSize="sm">{t('notifications:once-a-day', 'Once a day')}</Text>
                  </Checkbox>
                  <Checkbox
                    isChecked={frequency.once_week}
                    onChange={(e) =>
                      setFrequency({
                        ...frequency,
                        once_week: !frequency.once_week,
                      })
                    }
                  >
                    <Text fontSize="sm">{t('notifications:once-a-week', 'Once a week')}</Text>
                  </Checkbox>
                </HStack>
              </Box>
            </>
          
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant='secondary'
              onClick={onModalClose}
            >
              {t('general:Cancel', 'Cancel')}
            </Button>
            <Button
              variant='primary'
              isLoading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                setIsLoading(true);
                //TODO: Can be moved in a special hook
                let method = "post";
                let url = `/api/minds/${mindId}/users/${userId}/notifications/`;
                const dataToSend = {
                  'notif_connected': true,
                  machine: machine.id,
                  user: userId,
                };
                if (notifications.length >= 1) {
                  const machineNotification = notifications[0];
                  method = "put";
                  url = `${url}${machineNotification.id}/`;
                  dataToSend.frequency = machineNotification.frequency;
                } else {
                  dataToSend.frequency = DEFAULT_FREQUENCY;
                }

                dataToSend.frequency[type] = frequency;
                axios({
                  method,
                  url,
                  data: dataToSend,
                })
                  .then((resp) => {
                    toast({
                      status: "success",
                      description: t(
                        'notifications:enabled-notify-type',
                        '{{type}} notification has been updated successfully',
                        {type: type.toCapitalCase()}
                      ),
                      position: TOAST_POSITION,
                      isClosable: true,
                    });
                    refetchNotifications();
                  })
                  .catch((reason) => {
                    toast({
                      status: "error",
                      description: STANDARD_ERROR_MSG,
                      position: TOAST_POSITION,
                      isClosable: true,
                    });
                    console.error(reason);
                  })
                  .finally(() => setIsLoading(false));
              }}
            >
               {t('general:Submit', 'Submit')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};


const AddNotifications = ({
  machine,
  notifications,
  mindId,
  userId,
  type,
  refetchNotifications,
  columnsProps,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [channels, setChannels] = useState([]);
  const [simpleMode, setSimpleMode] = useState(true);
  const [selected, setSelected] = useState([]);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation(['general', 'notifications'])

  const defaultFreq = () =>
    notifications.length >= 1
      ? notifications[0]["frequency"][type]
      : DEFAULT_FREQUENCY[type];

  const [frequency, setFrequency] = useState(() => defaultFreq());

  const onModalClose = React.useCallback(() => {
    onClose();
    setSimpleMode(true);
    setFrequency(() => defaultFreq());
  });
  const options = {
    params: {
      user: userId,
    },
  };

  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);
  const notificationsCount =
    notifications.length >= 1 ? notifications[0][`notif_${type}`].length : 0;

  const noFreqSelected = Object.values(frequency).every((v) => v === false);

  const typeTranslated  = type === 'alarms'? t('general:alarms') : t('general:setups')

  return (
    <>
      <Tooltip
        label={t(
          'notifications:active-notifications',
          'There are {{count}} {{type}} notification active',
          {count: notificationsCount, type: typeTranslated }
        )}
      >
        <Box
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            axios
              .get(`/api/machines/${machine.id}/${type}/`, options)
              .then((resp) => {
                setChannels(resp.data);
                if (notifications.length >= 1) {
                  setSelected(notifications[0][`notif_${type}`] || []);

                  if (
                    notifications[0][`notif_${type}`].length < resp.data.length
                  )
                    setSimpleMode(false);
                }
              })
              .finally(() => {
                onOpen();
              });
          }}
        >
          <Badge>{notificationsCount}</Badge>
          <EditIcon ml={2}/>
        </Box>
      </Tooltip>
      <ModalSkeleton
        title={`${capitalize(type)} ${t('general:Notifications', 'Notifications')}`}
        isOpen={isOpen}
        onClose={onModalClose}
        size="3xl"
      >
        <ModalBody>
          <Box>
            <>
              <Flex justifyContent="flex-end">
                <Button
                  alignSelf="flex-end"
                  size="xs"
                  variant='secondary'
                  leftIcon={<ArrowLoop fontSize="lg" mr={-1} />}
                  onClick={() => {
                    setSimpleMode(!simpleMode);
                  }}
                >
                {startCase(t('general:switch-to', 'switch to'))} {simpleMode
                ? startCase(t('notifications:advance-mode', 'advance mode'))
                : startCase(t('notifications:simple-mode', 'simple mode'))}
                </Button>
              </Flex>
              <Box my={4}>
                <Text as="strong">{t('notifications:sending-frequency', 'Sending Frequency')}</Text>
                <HStack spacing={6} mt={1}>
                  <Checkbox
                    isChecked={frequency.appear}
                    onChange={(e) =>
                      setFrequency({ ...frequency, appear: !frequency.appear })
                    }
                  >
                    <Text fontSize="sm">{t('notifications:when-appears', 'When notification appears')}</Text>
                  </Checkbox>
                  <Checkbox
                    isChecked={frequency.once_day}
                    onChange={(e) =>
                      setFrequency({
                        ...frequency,
                        once_day: !frequency.once_day,
                      })
                    }
                  >
                    <Text fontSize="sm">{t('notifications:once-a-day', 'Once a day')}</Text>
                  </Checkbox>
                  <Checkbox
                    isChecked={frequency.once_week}
                    onChange={(e) =>
                      setFrequency({
                        ...frequency,
                        once_week: !frequency.once_week,
                      })
                    }
                  >
                    <Text fontSize="sm">{t('notifications:once-a-week', 'Once a week')}</Text>
                  </Checkbox>
                </HStack>
                {noFreqSelected && (
                  <Text fontSize="sm" color="frigel.light.status.alarm">
                    {t('notifications:at-least-1-frequency', 'You have to select at least one sending frequency')}
                  </Text>
                )}
              </Box>
            </>
            {simpleMode ? (
              <Box mt={4}>
                <Text as="strong">
                  {t('notifications:enable-all-notifications', 'Do you want to enable notifications for all the alarms?')}
                </Text>
              </Box>
            ) : (
              <SelectionDataTable
                // keyField='code'
                rawData={channels}
                columns={columns}
                initialSelected={
                  notifications.length >= 1
                    ? notifications[0][`notif_${type}`]
                    : []
                }
                afterSelected={(selection) => setSelected(selection)}
                resetSelection={false}
                hideSelectAll={true}
              />
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="flex-end">
            <Button
              variant='secondary'
              onClick={onModalClose}
            >
              {t('general:Cancel', 'Cancel')}
            </Button>
            <Button
              variant='primary'
              disabled={noFreqSelected}
              isLoading={isLoading}
              onClick={(e) => {
                e.preventDefault();
                setIsLoading(true);
                //TODO: Can be moved in a special hook
                let method = "post";
                let url = `/api/minds/${mindId}/users/${userId}/notifications/`;
                const dataToSend = {
                  [`notif_${type}`]: simpleMode
                    ? channels.map((c) => c.id)
                    : selected,
                  machine: machine.id,
                  user: userId,
                };

                if (notifications.length >= 1) {
                  const machineNotification = notifications[0];
                  method = "put";
                  url = `${url}${machineNotification.id}/`;
                  dataToSend.frequency = machineNotification.frequency;
                } else {
                  dataToSend.frequency = DEFAULT_FREQUENCY;
                }

                dataToSend.frequency[type] = frequency;

                axios({
                  method,
                  url,
                  data: dataToSend,
                })
                  .then((resp) => {
                    toast({
                      status: "success",
                      description: t(
                        'notifications:enabled-notify-type',
                        '{{type}} notification has been updated successfully',
                        {type: type.toCapitalCase()}
                      ),
                      position: TOAST_POSITION,
                      isClosable: true,
                    });
                    refetchNotifications();
                  })
                  .catch((reason) => {
                    toast({
                      status: "error",
                      description: STANDARD_ERROR_MSG,
                      position: TOAST_POSITION,
                      isClosable: true,
                    });
                    console.error(reason);
                  })
                  .finally(() => setIsLoading(false));
              }}
            >
               {t('general:Submit', 'Submit')}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};

const RemoveNotification = ({
  machine,
  notifications,
  mindId,
  userId,
  refetchNotifications,
}) => {
  const DEFAULT_STATE = {
    alarms: false,
    setups: false,
    connected : false
  };
  const [notificationsType, setNotificationsType] = useState(DEFAULT_STATE);
  const toast = useToast();
  const {t} = useTranslation(['general', 'notifications'])

  return (
    <ConfirmAlert
      title={t('notifications:remove-notification', 'remove notifications').toCapitalCase()}
      message={
        <>
          <Text as="strong">{t('notifications:select-notification-to-remove', 'Select notifications to remove')} </Text>
          <VStack spacing={2} mt={3} align="flex-start">
            <Checkbox
              isChecked={notificationsType.alarms}
              onChange={(e) =>
                setNotificationsType({
                  ...notificationsType,
                  alarms: !notificationsType.alarms,
                })
              }
            >
              <Text fontSize="sm">{t('general:alarms', 'alarms').toCapitalCase()}</Text>
            </Checkbox>
            <Checkbox
              isChecked={notificationsType.setups}
              onChange={(e) =>
                setNotificationsType({
                  ...notificationsType,
                  setups: !notificationsType.setups,
                })
              }
            >
              <Text fontSize="sm">{t('general:setups', 'setups').toCapitalCase()}</Text>
            </Checkbox>
            <Checkbox
              isChecked={notificationsType.connected}
              onChange={(e) =>
                setNotificationsType({
                  ...notificationsType,
                  connected: !notificationsType.connected,
                })
              }
            >
              <Text fontSize="sm">{t('general:connected', 'connected').toCapitalCase()}</Text>
            </Checkbox>
          </VStack>
        </>
      }
      confirmBtnText={t('general:remove', 'remove').toCapitalCase()}
      confirmDisabled={!notificationsType.alarms && !notificationsType.setups && !notificationsType.connected}
      beforeClose={() => {
        setNotificationsType(DEFAULT_STATE);
        return Promise.resolve(true);
      }}
      onConfirm={() => {
        if (notifications.length === 0) {
          toast({
            status: "info",
            description: t('notifications:no-to-remove', "There is no notifications to remove"),
            position: TOAST_POSITION,
            isClosable: true,
          });
          return Promise.resolve(true);
        }
        const notification = notifications[0];
        const nId = notification.id;
        const url = `/api/minds/${mindId}/users/${userId}/notifications/${nId}/`;
        let method = "put";
        let data = { ...notification };

        if (
          notificationsType.alarms === true &&
          notificationsType.setups === true &&
          notificationsType.connected === true 
        ) {
          method = "delete";
        } else if (notificationsType.alarms === true) {
          data = { ...data, notif_alarms: [] };
        } else if (notificationsType.setups === true) {
          data = { ...data, notif_setups: [] };
        } else if (notificationsType.connected === true) {
          data = { ...data, notif_connected: false };
        } else {
          console.error("Not managed case");
          return Promise.reject(false);
        }

        return axios({ url, method, data }).then(() => {
          refetchNotifications();
          toast({
            status: "success",
            description: t('notifications:notification-removed', "Notifications successfully removed") ,
            position: TOAST_POSITION,
            isClosable: true,
          });
          setNotificationsType(DEFAULT_STATE);
        });
      }}
    />
  );
};

const EnableAllNotifications = ({ mindId, userId, refetchNotifications }) => {
  const DEFAULT_STATE = {
    alarms: false,
    setups: false,
    connected: false,
  };
  const [notificationsType, setNotificationsType] = useState(DEFAULT_STATE);
  const toast = useToast();
  const { colorMode } = useColorMode();
  const {t} = useTranslation(['general', 'notifications'])


  const [alarmsFrequency, setAlarmsFrequency] = useState(
    DEFAULT_FREQUENCY["alarms"]
  );
  const noAlarmsFreqSelected = Object.values(alarmsFrequency).every(
    (v) => v === false
  );
  const [setupsFrequency, setSetupsFrequency] = useState(
    DEFAULT_FREQUENCY["setups"]
  );
  const noSetupsFreqSelected = Object.values(setupsFrequency).every(
    (v) => v === false
    );
  const [connectFrequency, setConnectFrequency] = useState(
    DEFAULT_FREQUENCY["connected"]
  );
  const noConnectFreqSelected = Object.values(connectFrequency).every(
    (v) => v === false
    );

  return (
    <ConfirmAlert
      title={t('notifications:enable-all-notifications', "Enable all notifications")}
      confirmBtnText={t('general:Submit', 'Submit')}
      customBtn={(props) => (
        <Button size='sm'
          variant='secondary' {...props}>
          {startCase(t('general:enable-all', 'enable all'))}
        </Button>
      )}
      skeletonProps={{ size: "xl" }}
      message={
        <>
          <Text as="strong">{t('notifications:select-notification-type-to-enable', 'Select notifications type to enable')} </Text>
          <VStack spacing={2} mt={3} align="flex-start">
            <HStack>
              <Checkbox
                isChecked={notificationsType.alarms}
                disabled={noAlarmsFreqSelected}
                borderColor={noAlarmsFreqSelected && "frigel.neutral.gray3"}
                onChange={(e) =>
                  !noAlarmsFreqSelected &&
                  setNotificationsType({
                    ...notificationsType,
                    alarms: !notificationsType.alarms,
                  })
                }
              >
                <Text
                  fontSize="sm"
                  color={noAlarmsFreqSelected && "frigel.neutral.gray3"}
                >
                  {t('general:alarms', 'alarms').toCapitalCase()}
                </Text>
              </Checkbox>
              <Popover placement="bottom">
                <PopoverTrigger>
                  <IconButton
                    size="xs"
                    icon={<TimeIcon />}
                    variant='secondary'
                  />
                </PopoverTrigger>
                <PopoverContent size="lg">
                  <PopoverArrow bgColor={`frigel.${colorMode}.blue`} />
                  <PopoverCloseButton color="white" />
                  <PopoverHeader
                    color="white"
                    bgColor={`frigel.${colorMode}.blue`}
                    borderTopRadius="md"
                  >
                    {t('notifications:sending-frequency', 'Sending frequency')}
                  </PopoverHeader>
                  <PopoverBody>
                    <VStack spacing={2} mt={1} align="flex-start">
                      <Checkbox
                        isChecked={alarmsFrequency.appear}
                        onChange={(e) =>
                          setAlarmsFrequency({
                            ...alarmsFrequency,
                            appear: !alarmsFrequency.appear,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:when-appears', 'When notification appears')}</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={alarmsFrequency.once_day}
                        onChange={(e) =>
                          setAlarmsFrequency({
                            ...alarmsFrequency,
                            once_day: !alarmsFrequency.once_day,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:once-a-day', 'Once a day')}</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={alarmsFrequency.once_week}
                        onChange={(e) =>
                          setAlarmsFrequency({
                            ...alarmsFrequency,
                            once_week: !alarmsFrequency.once_week,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:once-a-week', 'Once a week')}</Text>
                      </Checkbox>
                    </VStack>
                    {noAlarmsFreqSelected && (
                      <Text fontSize="sm" color="frigel.light.status.alarm">
                        {t('notifications:at-least-1-frequency', 'You have to select at least one sending frequency')}
                      </Text>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            <HStack>
              <Checkbox
                isChecked={notificationsType.setups}
                onChange={(e) =>
                  setNotificationsType({
                    ...notificationsType,
                    setups: !notificationsType.setups,
                  })
                }
              >
                <Text fontSize="sm">{t('general:setups', 'setups').toCapitalCase()}</Text>
              </Checkbox>
              <Popover placement="bottom">
                <PopoverTrigger>
                  <IconButton
                    size="xs"
                    icon={<TimeIcon />}
                    variant='secondary'
                  />
                </PopoverTrigger>
                <PopoverContent size="lg">
                  <PopoverArrow bgColor={`frigel.${colorMode}.blue`} />
                  <PopoverCloseButton color="frigel.neutral.gray3" />
                  <PopoverHeader
                    color="white"
                    bgColor={`frigel.${colorMode}.blue`}
                    borderTopRadius="md"
                  >
                    {t('notifications:sending-frequency', 'Sending Frequency')}
                  </PopoverHeader>
                  <PopoverBody>
                    <VStack spacing={2} mt={1} align="flex-start">
                      <Checkbox
                        isChecked={setupsFrequency.appear}
                        onChange={(e) =>
                          setSetupsFrequency({
                            ...setupsFrequency,
                            appear: !setupsFrequency.appear,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:when-appears', 'When notification appears')}</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={setupsFrequency.once_day}
                        onChange={(e) =>
                          setSetupsFrequency({
                            ...setupsFrequency,
                            once_day: !setupsFrequency.once_day,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:once-a-day', 'Once a day')}</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={setupsFrequency.once_week}
                        onChange={(e) =>
                          setSetupsFrequency({
                            ...setupsFrequency,
                            once_week: !setupsFrequency.once_week,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:once-a-week', 'Once a week')}</Text>
                      </Checkbox>
                    </VStack>
                    {noSetupsFreqSelected && (
                      <Text fontSize="sm" color="frigel.light.status.alarm">
                        {t('notifications:at-least-1-frequency', 'You have to select at least one sending frequency')}
                      </Text>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
            <HStack>
              <Checkbox
                isChecked={notificationsType.connected}
                onChange={(e) =>
                  setNotificationsType({
                    ...notificationsType,
                    connected: !notificationsType.connected,
                  })
                }
              >
                <Text fontSize="sm">{t('general:connected', 'connected').toCapitalCase()}</Text>
              </Checkbox>
              <Popover placement="bottom">
                <PopoverTrigger>
                  <IconButton
                    size="xs"
                    icon={<TimeIcon />}
                    variant='secondary'
                  />
                </PopoverTrigger>
                <PopoverContent size="lg">
                  <PopoverArrow bgColor={`frigel.${colorMode}.blue`} />
                  <PopoverCloseButton color="frigel.neutral.gray3" />
                  <PopoverHeader
                    color="white"
                    bgColor={`frigel.${colorMode}.blue`}
                    borderTopRadius="md"
                  >
                    {t('notifications:sending-frequency', 'Sending Frequency')}
                  </PopoverHeader>
                  <PopoverBody>
                    <VStack spacing={2} mt={1} align="flex-start">
                      <Checkbox
                        isChecked={connectFrequency.appear}
                        onChange={(e) =>
                          setConnectFrequency({
                            ...connectFrequency,
                            appear: !connectFrequency.appear,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:when-appears', 'When notification appears')}</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={connectFrequency.once_day}
                        onChange={(e) =>
                          setConnectFrequency({
                            ...connectFrequency,
                            once_day: !connectFrequency.once_day,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:once-a-day', 'Once a day')}</Text>
                      </Checkbox>
                      <Checkbox
                        isChecked={connectFrequency.once_week}
                        onChange={(e) =>
                          setConnectFrequency({
                            ...connectFrequency,
                            once_week: !connectFrequency.once_week,
                          })
                        }
                      >
                        <Text fontSize="sm">{t('notifications:once-a-week', 'Once a week')}</Text>
                      </Checkbox>
                    </VStack>
                    {noConnectFreqSelected && (
                      <Text fontSize="sm" color="frigel.light.status.alarm">
                        {t('notifications:at-least-1-frequency', 'You have to select at least one sending frequency')}
                      </Text>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </HStack>
          </VStack>
        </>
      }
      beforeClose={() => {
        setNotificationsType(DEFAULT_STATE);
        return Promise.resolve(true);
      }}
      confirmDisabled={
        (!notificationsType.alarms || noAlarmsFreqSelected) &&
        (!notificationsType.setups || noSetupsFreqSelected) &&
        (!notificationsType.connected || noConnectFreqSelected)
      }
      onConfirm={() => {
        return axios
          .post(
            `/api/minds/${mindId}/users/${userId}/notifications/enable_all/`,
            {
              ...notificationsType,
              frequency: { alarms: alarmsFrequency, setups: setupsFrequency, connected: connectFrequency },
            }
          )
          .then((resp) => {
            toast({
              status: "success",
              description: t(
                'notifications:notifications-enabled-successfully',
                'Notifications has been enabled successfully!'
              ),
              position: TOAST_POSITION,
              isClosable: true,
            });
            refetchNotifications();
          });
      }}
    />
  );
};

const DisableAllNotifications = ({ mindId, userId, refetchNotifications }) => {
  const DEFAULT_STATE = {
    alarms: false,
    setups: false,
    connected: false
  };
  const [notificationsType, setNotificationsType] = useState(DEFAULT_STATE);
  const toast = useToast();
  const {t} = useTranslation(['general', 'notifications'])
  const { colorMode } = useColorMode();

  return (
    <ConfirmAlert
      title={t('notifications:disable-all-notifications', 'Disable all notifications')}
      confirmBtnText={t('general:Submit', 'Submit')}
      customBtn={(props) => (
          <Button size='sm'
            variant='secondary' {...props}>
          {startCase(t('general:disable-all', 'disable all'))}
        </Button>
      )}
      skeletonProps={{ size: "xl" }}
      message={
        <>
          <VStack spacing={2} mt={3} align="flex-start">
            <Checkbox
              isChecked={notificationsType.alarms}
              onChange={(e) =>
                setNotificationsType({
                  ...notificationsType,
                  alarms: !notificationsType.alarms,
                })
              }
            >
              <Text fontSize="sm">{t('general:alarms', 'alarms').toCapitalCase()}</Text>
            </Checkbox>
            <Checkbox
              isChecked={notificationsType.setups}
              onChange={(e) =>
                setNotificationsType({
                  ...notificationsType,
                  setups: !notificationsType.setups,
                })
              }
            >
              <Text fontSize="sm">{t('general:setups', 'setups').toCapitalCase()}</Text>
            </Checkbox>
            <Checkbox
              isChecked={notificationsType.connected}
              onChange={(e) =>
                setNotificationsType({
                  ...notificationsType,
                  connected: !notificationsType.connected,
                })
              }
            >
              <Text fontSize="sm">{t('general:connected', 'connected').toCapitalCase()}</Text>
            </Checkbox>
          </VStack>
        </>
      }
      beforeClose={() => {
        setNotificationsType(DEFAULT_STATE);
        return Promise.resolve(true);
      }}
      confirmDisabled={!notificationsType.alarms && !notificationsType.setups && !notificationsType.connected}
      onConfirm={() => {
        return axios
          .post(
            `/api/minds/${mindId}/users/${userId}/notifications/disable_all/`,
            { ...notificationsType }
          )
          .then((resp) => {
            toast({
              status: "success",
              description: t(
                'notifications:notifications-disabled-successfully',
                'Notifications has been disabled successfully!'
              ),
              position: TOAST_POSITION,
              isClosable: true,
            });
            refetchNotifications();
          });
      }}
    />
  );
};
