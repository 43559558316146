import React, { createRef } from 'react'
import { createIcon } from '@chakra-ui/react'
export * from './Chevrons'
export * from './Triangles'
export * from './Arrows';
export * from './Flags';

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  d: "M12 24a12 12 0 100-24 12 12 0 000 24zM10.5 5c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5h-2a.5.5 0 01-.5-.5V5zm0 6c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v8c0 .3-.2.5-.5.5h-2a.5.5 0 01-.5-.5v-8z"
})

export const InfoIconBlue = createIcon({
  displayName: 'InfoIcon',
  d: "M12 24a12 12 0 100-24 12 12 0 000 24zM10.5 5c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5h-2a.5.5 0 01-.5-.5V5zm0 6c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v8c0 .3-.2.5-.5.5h-2a.5.5 0 01-.5-.5v-8z"
})

export const AlfaSortAscIcon = createIcon({
  displayName: 'AlfaSortAscIcon',
  d: 'M6.4 6.3L1.9 17.7h1.8l1-2.4h5l1 2.4h1.8L8 6.3H6.4zm-1.2 7.3l2-5.1 2 5.1h-4zm17 2.5H16l6-8.6V6.3h-8.4v1.6h6l-6 8.5v1.3H22v-1.6z'
})

export const AlfaSortDescIcon = createIcon({
  displayName: 'AlfaSortDescIcon',
  d: "M17 6.3l-4.5 11.4h1.8l1-2.4h5l1 2.4H23L18.6 6.3H17zm2.8 7.3l-2-5.1-2 5.1h4zm-8.4 2.5H5.3l6-8.6V6.3H2.8v1.6h5.9l-6 8.5v1.3h8.6v-1.6z"
})

export const SortDescIcon = createIcon({
  displayName: 'SortDescIcon',
  d: 'M3 18h6v-2H3v2zM3 6v2h18V6H3zM3 13h12v-2H3v2z'
})

export const SortAscIcon = createIcon({
  displayName: 'SortAscIcon',
  d: 'M3 6h6v2H3V6zM3 18v-2h18v2H3zM3 11h12v2H3v-2z'
})

export const GridIcon = createIcon({
  displayName: 'GridIcon',
  // d: 'M4 2h16a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4c0-1.1.9-2 2-2zm0 18h4v-4H4v4zm4-6H4v-4h4v4zM4 8h4V4H4v4zm10 12h-4v-4h4v4zm-4-6h4v-4h-4v4zm4-6h-4V4h4v4zm2 12h4v-4h-4v4zm4-6h-4v-4h4v4zm-4-6h4V4h-4v4z',
  path: <path fillRule="evenodd" clipRule="evenodd" d="M4 2h16a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4c0-1.1.9-2 2-2zm0 18h4v-4H4v4zm4-6H4v-4h4v4zM4 8h4V4H4v4zm10 12h-4v-4h4v4zm-4-6h4v-4h-4v4zm4-6h-4V4h4v4zm2 12h4v-4h-4v4zm4-6h-4v-4h4v4zm-4-6h4V4h-4v4z" fill="currentColor"/>
})

export const ListIcon = createIcon({
  displayName: 'ListIcon',
  d: 'M2.8 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1.5 4.5c-.9 0-1.5.7-1.5 1.5s.6 1.5 1.5 1.5c.8 0 1.5-.7 1.5-1.5s-.7-1.5-1.5-1.5zm17 2.5h-14v-2h14v2zm-14-6h14v-2h-14v2zm0-6V5h14v2h-14z'
})

export const ColorModeIcon = createIcon({
  displayName: 'ColorModeIcon',
  path: (
    <svg fill="none">
    <path d="M12 17a5 5 0 100-10v10zM12 17a5 5 0 010-10v1a4 4 0 100 8v1z"  fill="currentColor"/>
    <rect x="1" y="13" width="2" height="4" rx="1" transform="rotate(-90 1 13)" fill="currentColor"/>
    <rect x="19" y="13" width="2" height="4" rx="1" transform="rotate(-90 19 13)" fill="currentColor"/>
    <rect x="13" y="23" width="2" height="4" rx="1" transform="rotate(180 13 23)" fill="currentColor"/>
    <rect x="13" y="5" width="2" height="4" rx="1" transform="rotate(180 13 5)" fill="currentColor"/>
    <rect x="4.9" y="20.5" width="2" height="4" rx="1" transform="rotate(-135 5 20.5)" fill="currentColor"/>
    <rect x="17.7" y="7.8" width="2" height="4" rx="1" transform="rotate(-135 17.7 7.8)" fill="currentColor"/>
    <rect x="20.5" y="19.1" width="2" height="4" rx="1" transform="rotate(135 20.5 19)" fill="currentColor"/>
    <rect x="7.8" y="6.3" width="2" height="4" rx="1" transform="rotate(135 7.8 6.3)" fill="currentColor"/>
  </svg>
  )
})


export const SearchIcon = createIcon({
  displayName: 'SearchIcon',
  d: "M15 14.3h.8l5 5-1.5 1.4-5-5V15l-.3-.3a6.5 6.5 0 11.7-.7l.3.3zM5.3 9.8a4.5 4.5 0 109 0 4.5 4.5 0 00-9 0z"
})

export const CircleIcon = createIcon({
  displayName: 'CircleIcon',
  path: <circle cx="12" cy="12" r="6" fill="currentColor"/>
})

export const DownloadIcon = createIcon({
  displayName: 'DownloadIcon',
  path: <path fillRule="evenodd" clipRule="evenodd" d="M2.7 20.3h18.6c.4 0 .7-.3.7-.6v-5.4a.7.7 0 10-1.3 0V19H3.3v-4.7a.7.7 0 10-1.3 0v5.4c0 .3.3.6.7.6zm6.5-9.8l2.1 2.7V3.7a.7.7 0 111.4 0v9.5l2.2-2.7c.2-.2.7-.2 1 0 .2.3.2.7 0 1l-3.4 4-.5.2c-.2 0-.4 0-.5-.2l-3.3-4a.7.7 0 010-1c.3-.2.7-.2 1 0z" fill="currentColor" stroke="currentColor"/>
})

export const GearIcon = createIcon({
  displayName: 'GearIcon',
  d: 'M24 10.7a.8.8 0 00-.8-.6 2.6 2.6 0 01-1.9-4.6.7.7 0 00.1-1l-1.9-1.9a.7.7 0 00-1 .1 2.8 2.8 0 01-2.9.7A2.7 2.7 0 0114 .8a.7.7 0 00-.6-.7h-2.7a.7.7 0 00-.6.7 2.7 2.7 0 01-1.6 2.5 2.8 2.8 0 01-3-.7.7.7 0 00-1 0 12 12 0 00-1.9 1.9.7.7 0 00.1.9 2.7 2.7 0 01.7 3A2.8 2.8 0 01.8 10a.7.7 0 00-.7.6v2.7a.8.8 0 00.8.6 2.6 2.6 0 011.8 4.6.7.7 0 00-.1 1c.5.7 1.2 1.3 1.9 1.9a.7.7 0 00.9-.1 2.8 2.8 0 013-.7 2.7 2.7 0 011.6 2.6.7.7 0 00.6.7 12.4 12.4 0 002.7 0 .7.7 0 00.6-.7 2.7 2.7 0 011.6-2.5 2.8 2.8 0 013 .7.7.7 0 001 0l1.9-1.9a.7.7 0 00-.1-.9A2.6 2.6 0 0123 14h.1a.7.7 0 00.7-.6v-2.7zM12 16a4 4 0 110-8 4 4 0 010 8z'
})

export const TrashIcon = createIcon({
  displayName: 'TrashIcon',
  d: 'M4 5.1v-1c0-.5.4-1 .9-1h4l.3-.6c.1-.3.4-.5.8-.5h4c.4 0 .7.2.8.5l.3.7h4c.5 0 .9.4.9.9v1c0 .3-.2.5-.4.5H4.4A.4.4 0 014 5zm14.9 2.1v12c0 1-.8 1.8-1.8 1.8H7c-1 0-1.8-.8-1.8-1.8v-12c0-.2.2-.5.5-.5h12.8c.3 0 .5.3.5.5zM9 9.7c0-.3-.2-.6-.5-.6s-.6.3-.6.6V18c0 .4.3.6.6.6s.5-.2.5-.6V9.7zm3.5 0c0-.3-.3-.6-.6-.6s-.6.3-.6.6V18c0 .4.3.6.6.6s.6-.2.6-.6V9.7zm3.4 0c0-.3-.3-.6-.6-.6s-.5.3-.5.6V18c0 .4.2.6.5.6s.6-.2.6-.6V9.7z'
})

export const FilterIcon = createIcon({
  displayName: 'FilterIcon',
  path: <svg> <path d="M21.374 4.5H2.626c-.999 0-1.503 1.212-.795 1.92L9 13.592v6.659c0 .367.18.711.48.922l3.75 2.624c.74.518 1.77-.007 1.77-.922v-9.283l7.17-7.17c.706-.707.205-1.921-.796-1.921z" fill="currentColor"/><rect x="1.5" y="1.5" width="21" height="1.5" rx=".75" fill="currentColor"/> </svg>
})

export const PlayIcon = createIcon({
  displayName: 'PlayIcon',
  d: 'M20.285 11.571a.5.5 0 010 .858L6.757 20.546a.5.5 0 01-.757-.43V3.884a.5.5 0 01.757-.429l13.528 8.117z'
})

export const StopIcon = createIcon({
  displayName: 'StopIcon',
  path: <rect x="4.5" y="4.5" width="15" height="15" rx="1" fill="currentColor"/>
})

export const EditIcon = createIcon({
  displayName: 'EditIcon',
  d: 'M17.659 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 6.02l.92.92L5.919 19h-.92v-.92l9.06-9.06zm-11.06 8.23l11.06-11.06 3.75 3.75L6.749 21h-3.75v-3.75z'
})

export const KeyIcon = createIcon({
  displayName: 'KeyIcon',
  d: 'M13.7011 1.73665C12.0159 3.48303 11.7131 6.16144 12.4686 8.34283L0.0787095 21.199C0.0787095 21.199 -0.732502 24.799 2.85363 23.8364C2.94073 23.7177 4.75253 22.917 4.75253 22.917L4.79405 19.8078L7.81203 19.8635L8.85617 18.8453L8.56551 15.5553L11.9258 15.6047L11.8812 12.3211L14.0333 13.5484L15.7601 11.948C17.9233 12.8801 20.5007 12.4408 22.2578 10.6178C24.5821 8.20623 24.58 4.29632 22.2558 1.8848C19.9326 -0.527764 16.0254 -0.674871 13.7011 1.73665ZM20.5939 6.89172C21.16 7.477 21.1874 8.39853 20.6536 8.95018C20.124 9.50184 19.2338 9.47557 18.6717 8.88819L15.4005 5.49525C14.8364 4.90997 14.8091 3.98844 15.3408 3.43679C15.8725 2.88513 16.7606 2.9135 17.3268 3.49878L20.5939 6.89172Z'
})


export const AddUserIcon = createIcon({
  displayName: 'AddUserIcon',
  path: <><circle cx="12" cy="6.5" r="4" stroke="currentColor" fill="currentColor" strokeWidth="1.8"/><path d="M6 14v4m0 4v-4m0 0h4m-4 0H2" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square" strokeLinejoin="round"/><path d="M18.5 18S17 14 12 14c-.918 0-1.75.135-2.5.355" stroke="currentColor" strokeWidth="1.8" strokeLinecap="square"/></>
})

export const WarningIcon = createIcon({
  displayName: 'WarningIcon',
  path: <path fillRule="evenodd" clipRule="evenodd" d="M24.68 22.5a1 1 0 00.86-1.512L13.972 1.58a1 1 0 00-1.718 0L.689 20.988a1 1 0 00.859 1.512H24.68zm-10.57-3.193a1 1 0 00-1-1h-.38a1 1 0 00-1 1v.33a1 1 0 001 1h.38a1 1 0 001-1v-.33zM14.04 8.33a1 1 0 00-1-1h-.222a1 1 0 00-1 1v2.839l.533 5.236a.58.58 0 001.155 0l.533-5.236V8.33z" fill="currentColor"/>
})

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  d: 'M22.799 11.023h-4.543c-.41-1.588-1.787-2.779-3.457-2.779-1.641 0-3.048 1.16-3.458 2.779H.938c-.498 0-.909.427-.909.947 0 .518.41.946.909.946H11.37c.41 1.588 1.788 2.779 3.458 2.779 1.67 0 3.047-1.191 3.428-2.779H22.8c.498 0 .908-.428.908-.947 0-.519-.41-.946-.909-.946zm-7.971 2.779c-.967 0-1.73-.825-1.73-1.802 0-1.008.792-1.802 1.73-1.802.967 0 1.729.825 1.729 1.802 0 .977-.762 1.802-1.73 1.802zm7.736 5.526h-9.758c-.41-1.587-1.788-2.778-3.458-2.778-1.641 0-3.048 1.16-3.458 2.778H.908c-.498 0-.908.428-.908.947 0 .519.41.946.908.946H5.89C6.3 22.81 7.678 24 9.348 24c1.641 0 3.048-1.16 3.458-2.779h9.758c.498 0 .909-.427.909-.946 0-.52-.41-.947-.909-.947zM9.348 22.107c-.938 0-1.729-.794-1.729-1.771v-.061c.03-.977.791-1.771 1.729-1.771.967 0 1.729.824 1.729 1.801s-.762 1.802-1.729 1.802zM23.092 2.779H12.806C12.396 1.19 11.018 0 9.348 0 7.707 0 6.3 1.16 5.89 2.779H1.201c-.498 0-.908.427-.908.946 0 .52.41.947.908.947h4.69C6.3 6.26 7.677 7.45 9.347 7.45c1.641 0 3.048-1.16 3.458-2.778h10.286c.498 0 .908-.428.908-.947 0-.55-.41-.946-.908-.946zM9.348 5.527c-.967 0-1.729-.825-1.729-1.802 0-1.007.791-1.801 1.729-1.801.967 0 1.729.824 1.729 1.801.03.977-.762 1.802-1.729 1.802z'
})

export const OpenWindowIcon = createIcon({
  displayName: 'OpenWindowIcon',
  d: 'M19 19H5V5h5V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-5h-2v5zM14 3v2h3.585l-9.83 9.83 1.415 1.415L19 6.415V10h2V3h-7z'
})

export const HamburgerIcon = createIcon({
  displayName: 'HamburgerIcon',
  path: <><rect y="4" width="24" height="2" rx="1" fill="currentColor"/><rect y="11" width="24" height="2" rx="1" fill="currentColor"/><rect y="18" width="24" height="2" rx="1" fill="currentColor"/></>
})

export const TimeIcon = createIcon({
  displayName: 'TimeIcon',
  path: (
    <>
      <path d="M12 0a12 12 0 100 24 12 12 0 000-24zm0 21.6a9.6 9.6 0 110-19.2 9.6 9.6 0 010 19.2z" fill="currentColor"/>
      <path d="M13 7a1 1 0 10-2 0v6l5.8 3.5a1 1 0 001-1.5L13 12V7z" fill="currentColor"/>
    </>
  )
})

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  d: 'M13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3z'
})


export const StarIcon = createIcon({
  displayName: 'StarIcon',
  d: 'M11.5.4c.1-.5.9-.5 1 0l2.6 7.4c0 .2.3.3.5.3l7.8.2c.6 0 .8.7.4 1L17.6 14c-.2.2-.3.4-.2.7l2.2 7.4c.2.6-.4 1-.9.7l-6.4-4.5a.6.6 0 00-.6 0l-6.4 4.5c-.5.3-1-.1-1-.7l2.3-7.4c.1-.3 0-.5-.2-.7L.2 9.3a.6.6 0 01.4-1l7.8-.2c.2 0 .4-.1.5-.3L11.5.4z'
})

export const TriangleIcon = createIcon({
  displayName: 'InfoWarningIcon',
  viewBox: '0 0 26 24',
  path: <svg fill="currentColor">
  <path fillRule="evenodd" clipRule="evenodd" d="M24.6801 22.4997C25.456 22.4997 25.9363 21.6543 25.5391 20.9877L13.9728 1.5798C13.585 0.929049 12.6426 0.929049 12.2548 1.5798L0.688534 20.9877C0.291287 21.6543 0.771592 22.4997 1.54756 22.4997H24.6801ZM14.1102 19.3068C14.1102 18.7545 13.6625 18.3068 13.1102 18.3068H12.7293C12.177 18.3068 11.7293 18.7545 11.7293 19.3068V19.6361C11.7293 20.1884 12.177 20.6361 12.7293 20.6361H13.1102C13.6625 20.6361 14.1102 20.1884 14.1102 19.6361V19.3068ZM14.0392 8.33112C14.0392 7.77884 13.5914 7.33112 13.0392 7.33112H12.8181C12.2658 7.33112 11.8181 7.77884 11.8181 8.33112V11.1698L12.3514 16.4064C12.3815 16.7025 12.6309 16.9278 12.9286 16.9278C13.2263 16.9278 13.4757 16.7025 13.5059 16.4064L14.0392 11.1698V8.33112Z" fill="currentColor"/>
  </svg>
})



export const PDFIcon =  createIcon({
  displayName: 'PDFIcon',
  viewBox: '0 4 26 24',
  path: (
    <>
    <path d= "M22.361 10.833h-5.833c-.584 0-.972-.416-.972-1.041v-6.25H17.5V8.75h4.861v2.083Z" fill="currentColor"/>
    <path d="M22.361 27.5H6.806c-.584 0-.973-.417-.973-1.042V3.542c0-.625.39-1.042.973-1.042h9.722c.291 0 .486.104.68.313l5.834 6.25a.978.978 0 0 1 .291.729v16.666c0 .625-.389 1.042-.972 1.042ZM7.778 25.417h13.61V10.208l-5.25-5.625h-8.36v20.834Z"fill="currentColor"/>
    <path d="M9.537 22.432v-5.25h.567v.606h.07c.042-.066.102-.15.178-.253.078-.105.19-.197.333-.279.146-.082.343-.124.592-.124.322 0 .605.08.85.241.245.161.437.389.574.684.138.295.207.643.207 1.044 0 .404-.07.755-.207 1.051a1.628 1.628 0 0 1-.571.686c-.244.161-.525.241-.843.241-.245 0-.442-.04-.59-.121a1.092 1.092 0 0 1-.34-.281 3.767 3.767 0 0 1-.184-.264h-.05v2.019h-.586Zm.577-3.341c0 .288.042.543.126.763.085.219.208.39.37.515a.962.962 0 0 0 .597.184c.246 0 .45-.065.614-.194.166-.131.29-.307.373-.527.085-.222.127-.47.127-.741 0-.269-.041-.51-.124-.726a1.12 1.12 0 0 0-.37-.514.978.978 0 0 0-.62-.192c-.238 0-.439.06-.601.182-.163.119-.285.286-.368.502a2.065 2.065 0 0 0-.124.748Zm5.13 1.988c-.318 0-.599-.08-.842-.24a1.628 1.628 0 0 1-.572-.687c-.138-.296-.206-.647-.206-1.051 0-.401.068-.75.206-1.044.137-.295.329-.523.574-.684.245-.16.529-.24.85-.24.249 0 .445.04.59.123.145.082.256.174.333.279.077.102.138.187.181.253h.05V15.91h.586V21h-.566v-.587h-.07a3.74 3.74 0 0 1-.184.264c-.08.104-.193.198-.34.28-.148.082-.344.122-.59.122Zm.08-.526a.962.962 0 0 0 .597-.184c.162-.125.285-.296.37-.515a2.12 2.12 0 0 0 .127-.763c0-.285-.042-.535-.125-.748a1.088 1.088 0 0 0-.368-.502.978.978 0 0 0-.601-.182.989.989 0 0 0-.622.192 1.146 1.146 0 0 0-.37.514 2.045 2.045 0 0 0-.122.726c0 .272.042.519.124.74.085.221.21.397.373.528.166.129.372.194.617.194Zm4.527-3.371v.497h-2.059v-.497h2.058ZM18.409 21v-4.345c0-.219.051-.401.154-.547a.954.954 0 0 1 .4-.328c.164-.073.337-.11.52-.11a1.368 1.368 0 0 1 .557.1l-.17.507a2.79 2.79 0 0 0-.124-.037.754.754 0 0 0-.204-.023c-.194 0-.334.05-.42.147-.084.098-.127.241-.127.43V21h-.586Z" fill="currentColor"/>
    </>
  )
})

export const PDFDisableIcon =  createIcon({
  displayName: 'PDFDisableIcon',
  viewBox: '0 8 26 24',
  path: (
    <>
    <path d="M22.361 14.833h-5.833c-.584 0-.972-.416-.972-1.041v-6.25H17.5v5.208h4.861v2.083Z" fill="currentColor"/>
    <path d="M22.361 31.5H6.806c-.584 0-.973-.417-.973-1.042V7.542c0-.625.39-1.042.973-1.042h9.722c.291 0 .486.104.68.313l5.834 6.25a.978.978 0 0 1 .291.729v16.666c0 .625-.389 1.042-.972 1.042ZM7.778 29.417h13.61V14.208l-5.25-5.625h-8.36v20.834Z" fill="currentColor"/>
    <path d="M9.537 26.432v-5.25h.567v.606h.07c.042-.066.102-.15.178-.253.078-.105.19-.197.333-.279.146-.082.343-.124.592-.124.322 0 .605.08.85.241.245.161.437.389.574.684.138.295.207.643.207 1.044 0 .404-.07.755-.207 1.051a1.628 1.628 0 0 1-.571.686c-.244.161-.525.241-.843.241-.245 0-.442-.04-.59-.121a1.092 1.092 0 0 1-.34-.281 3.767 3.767 0 0 1-.184-.264h-.05v2.019h-.586Zm.577-3.341c0 .288.042.543.126.763.085.219.208.39.37.515a.962.962 0 0 0 .597.184c.246 0 .45-.065.614-.194.166-.131.29-.307.373-.527.085-.222.127-.47.127-.741 0-.269-.041-.51-.124-.726a1.12 1.12 0 0 0-.37-.514.978.978 0 0 0-.62-.192c-.238 0-.439.06-.601.182-.163.119-.285.286-.368.502a2.065 2.065 0 0 0-.124.748Zm5.13 1.988c-.318 0-.599-.08-.842-.24a1.628 1.628 0 0 1-.572-.687c-.138-.296-.206-.647-.206-1.051 0-.401.068-.75.206-1.044.137-.295.329-.523.574-.684.245-.16.529-.24.85-.24.249 0 .445.04.59.123.145.082.256.174.333.279.077.102.138.187.181.253h.05V19.91h.586V25h-.566v-.587h-.07a3.74 3.74 0 0 1-.184.264c-.08.104-.193.198-.34.28-.148.082-.344.122-.59.122Zm.08-.526a.962.962 0 0 0 .597-.184c.162-.125.285-.296.37-.515a2.12 2.12 0 0 0 .127-.763c0-.285-.042-.535-.125-.748a1.088 1.088 0 0 0-.368-.502.978.978 0 0 0-.601-.182.989.989 0 0 0-.622.192 1.146 1.146 0 0 0-.37.514 2.045 2.045 0 0 0-.122.726c0 .272.042.519.124.74.085.221.21.397.373.528.166.129.372.194.617.194Zm4.527-3.371v.497h-2.059v-.497h2.058ZM18.409 25v-4.345c0-.219.051-.401.154-.547a.954.954 0 0 1 .4-.328c.164-.073.337-.11.52-.11a1.368 1.368 0 0 1 .557.1l-.17.507a2.79 2.79 0 0 0-.124-.037.754.754 0 0 0-.204-.023c-.194 0-.334.05-.42.147-.084.098-.127.241-.127.43V25h-.586Z" fill="currentColor"/>
    <path d="m24.044 10.043-1.709-2.239-6.835 8.957-6.835-8.957-1.71 2.24L13.792 19l-6.835 8.957 1.709 2.239 6.835-8.957 6.835 8.957 1.71-2.24L17.208 19l6.835-8.957Z" fill="#CD1414"/>
    </>
  )
})

export const CheckedIcon =  createIcon({
  displayName: 'CheckedIcon',
  viewBox: '0 0 26 24',
  path: (
    <>
      <circle cx="12" cy="12" r="12" fill="#38D263"/>
      <path d="m8.4 16.258-4.032-4.041L3 13.587 8.4 19 20 7.371 18.632 6 8.4 16.258Z" fill="#FFFFFF"/>
    </> 
    )
  })

export const UnCheckedIcon = createIcon({
  displayName: 'CheckedIcon',
  viewBox: '0 0 24 24',
  path:(
    <>
      <circle cx="12" cy="12" r="12" fill="#ACC2CF"/>
      <path  d="M19.071 6.343 17.657 4.93 12 10.586 6.343 4.929 4.93 6.343 10.586 12l-5.657 5.657 1.414 1.414L12 13.414l5.657 5.657 1.414-1.414L13.414 12l5.657-5.657Z" fill="#FFFFFF"/>
    </>
  )
})

export const XIcon = createIcon({
  displayName: 'X',
  d: "M19 6.3L17.8 5 12 10.6 6.3 4.9 5 6.3l5.7 5.7-5.7 5.7L6.3 19l5.7-5.7 5.7 5.7 1.4-1.4-5.7-5.7 5.7-5.7z"
})

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  d: 'M8.4 16.3l-4-4L3 13.5 8.4 19 20 7.4 18.6 6 8.4 16.3z'
})

export const LoginIcon = createIcon({
  displayName: 'LoginIcon',
  path: (
    <>
      <path d="M11 7L9.6 8.4L12.2 11H2V13H12.2L9.6 15.6L11 17L16 12L11 7ZM20 19H12V21H20C21.1 21 22 20.1 22 19V5C22 3.9 21.1 3 20 3H12V5H20V19Z" fill="currentColor"/>
    </>
  )
})

export const FileIcon = createIcon({
  displayName: 'FileIcon',
  path: <path d="M8 16h8v2H8v-2Zm0-4h8v2H8v-2Zm6-10H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6Zm4 18H6V4h7v5h5v11Z" fill="currentColor" />
})
export const LogIcon = createIcon({
  displayName: 'LogIcon',
  viewBox: "0 0 548.291 548.291",
  path: (
    <>
    <path d="M486.201,196.124h-13.166V132.59c0-0.396-0.062-0.795-0.115-1.196c-0.021-2.523-0.825-5-2.552-6.963L364.657,3.677
		c-0.033-0.031-0.064-0.042-0.085-0.073c-0.63-0.707-1.364-1.292-2.143-1.795c-0.229-0.157-0.461-0.286-0.702-0.421
		c-0.672-0.366-1.387-0.671-2.121-0.892c-0.2-0.055-0.379-0.136-0.577-0.188C358.23,0.118,357.401,0,356.562,0H96.757
		C84.894,0,75.256,9.651,75.256,21.502v174.613H62.092c-16.971,0-30.732,13.756-30.732,30.733v159.812
		c0,16.968,13.761,30.731,30.732,30.731h13.164V526.79c0,11.854,9.638,21.501,21.501,21.501h354.776
		c11.853,0,21.501-9.647,21.501-21.501V417.392h13.166c16.966,0,30.729-13.764,30.729-30.731V226.854
		C516.93,209.872,503.167,196.124,486.201,196.124z M96.757,21.502h249.054v110.009c0,5.939,4.817,10.75,10.751,10.75h94.972v53.861
		H96.757V21.502z M317.816,303.427c0,47.77-28.973,76.746-71.558,76.746c-43.234,0-68.531-32.641-68.531-74.152
		c0-43.679,27.887-76.319,70.906-76.319C293.389,229.702,317.816,263.213,317.816,303.427z M82.153,377.79V232.085h33.073v118.039
		h57.944v27.66H82.153V377.79z M451.534,520.962H96.757v-103.57h354.776V520.962z M461.176,371.092
		c-10.162,3.454-29.402,8.209-48.641,8.209c-26.589,0-45.833-6.698-59.24-19.664c-13.396-12.535-20.75-31.568-20.529-52.967
		c0.214-48.436,35.448-76.108,83.229-76.108c18.814,0,33.292,3.688,40.431,7.139l-6.92,26.37
		c-7.999-3.457-17.942-6.268-33.942-6.268c-27.449,0-48.209,15.567-48.209,47.134c0,30.049,18.807,47.771,45.831,47.771
		c7.564,0,13.623-0.852,16.21-2.152v-30.488h-22.478v-25.723h54.258V371.092L461.176,371.092z" fill="currentColor" />
	<path d="M212.533,305.37c0,28.535,13.407,48.64,35.452,48.64c22.268,0,35.021-21.186,35.021-49.5
		c0-26.153-12.539-48.655-35.237-48.655C225.504,255.854,212.533,277.047,212.533,305.37z" fill="currentColor" />
    </>
  )
})

export const UpdateIcon = createIcon({
  displayName: 'UpdateIcon',
  path: <path d="M8 0V3C12.42 3 16 6.58 16 11C16 12.57 15.54 14.03 14.76 15.26L13.3 13.8C13.75 12.97 14 12.01 14 11C14 7.69 11.31 5 8 5V8L4 4L8 0ZM2 11C2 14.31 4.69 17 8 17V14L12 18L8 22V19C3.58 19 0 15.42 0 11C0 9.43 0.46 7.97 1.24 6.74L2.7 8.2C2.25 9.03 2 9.99 2 11Z" fill="currentColor"/>
})



export const KingIcon = createIcon({
  displayName: 'KingIcon',
  viewBox: "0 0 497.274 497.274",
  path :<path d="M411.428 357.652H78.241L43.585 209.446C19.393 208.475 0 188.465 0 164.043c0-25.075 20.381-45.438 45.455-45.438s45.455 20.363 45.455 45.438c0 12.052-4.888 23.486-13.252 31.886 55.249 59.713 100.775 55.09 143.036-15.934-10.887-8.488-17.505-21.634-17.505-35.786 0-25.057 20.381-45.455 45.455-45.455s45.455 20.381 45.455 45.455c0 15.087-7.641 29.027-19.781 37.356 36.333 69.789 82.847 73.353 143.583 14.452a45.142 45.142 0 0 1-11.523-30.192c0-25.057 20.381-45.42 45.438-45.42s45.455 20.363 45.455 45.42c0 24.969-20.222 45.279-45.138 45.455l-40.705 146.372zM94.405 337.307h301.548l34.868-125.479c-73.812 70.653-137.09 59.06-179.281-30.28l-3.829-8.099-4.358 7.923c-49.32 89.605-111.803 100.757-178.169 31.039l29.221 124.896zm347.92-147.801 4.306.882c15.811 3.335 30.245-8.999 30.245-24.545 0-13.817-11.24-25.075-25.075-25.075s-25.075 11.258-25.075 25.075c0 8.523 4.323 16.411 11.593 21.069l4.006 2.594zm-396.87-50.555c-13.834 0-25.075 11.258-25.075 25.075 0 13.834 11.24 25.092 25.075 25.092 1.482 0 2.894-.212 4.306-.459l4.111-.723 3.9-2.223c7.994-4.553 12.758-12.67 12.758-21.687 0-13.817-11.258-25.075-25.075-25.075zm203.156-19.834c-13.834 0-25.075 11.258-25.075 25.092 0 9.899 5.894 18.899 14.999 22.922l9.123 4.023 9.564-3.529c9.846-3.617 16.463-13.023 16.463-23.416 0-13.834-11.24-25.092-25.074-25.092zM399.711 398.52H89.976c-5.647 0-10.252-4.606-10.252-10.252 0-5.647 4.606-10.252 10.252-10.252h309.735c5.647 0 10.252 4.606 10.252 10.252 0 5.629-4.605 10.252-10.252 10.252z"  fill="currentColor" />
})