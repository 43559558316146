import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as authClient from '../api/auth-client';
import { updateUser } from '../api/users';
import { ADMIN, MANAGER, USER } from '../consts';
import { changeLanguage } from '../i18n';


const AuthContext = React.createContext();

function AuthProvider({children, ...props}) {

  const [state, setState] = useState({
    status: 'pending',
    error: null,
    user: null,
    isAuthenticated: false,
    statusText: '',
    redirect_to: '/login'
  })

  let history = useHistory()

  const {isAuthenticated, user} = state
  useEffect(() => {
    if (isAuthenticated && user){
      changeLanguage(user.language || 'en')
    }
  }, [isAuthenticated] )

  const login = (form) => {
    authClient.login(form)
      .then(result => {

        authClient.getUser()
          .then(({data: user}) => {
            if (history.location.pathname === '/login'){
              history.push('/')
            }
            setState({...state, error: null, ...result, isAuthenticated: true, user})
          })
          .catch(error => {
            setState({...state, ...result})
          })
      })
  }

  const tokenLogin = () => {
    authClient.getUser()
    .then(({data: user}) => {
      const { pathname } = history.location;
      if (pathname === '/token-login' || pathname === '/login'){
        history.push('/')
      }
      setState({...state, error: null, isAuthenticated: true, user})
    })
    .catch(error => {
      setState({
        ...state, isAuthenticated: false, statusText: 'Authentication error'
      })

    })
  }

  const logout = (statusText='') => {
    authClient.logout()
      .then(() => {
        history.push('/login')
        setState({...state, isAuthenticated: false, statusText, user: null})
      })
  }


  useEffect(() => {
    const token = authClient.getToken()
    if (token){
      authClient.getUser().then(
        ({data: user}) => {
          setState({...state, status: 'success', isAuthenticated: true, error: null, user})
          const { pathname } = history.location;
          if (pathname === '/login'){
            history.push('/')
        }
      }).catch(
        error => logout('Authentication error')
      )
    } else {
      logout();
    }
  }, [])

  const fetchUser = () => {
    return authClient.getUser()
      .then(({data: user}) => {
        setState({...state, user})
        changeLanguage(user.language || 'en')
      })
      .catch(error => {
        console.error('something wrong in fetching the user', error)
      })
  }

  return (
    <AuthContext.Provider value={{
      ...state, login, logout, tokenLogin, fetchUser
    }} {...props}>
      {children}
      {/* {state.status === 'pending'
        ? 'bro wait please'
        : state.status === 'error'
          ? <div>Oh nooo</div>
          :
      } */}
    </AuthContext.Provider>
  )
}


function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}


function useUser(){
  const {user} = useAuth()

  return user && {
    ...user,
    isAdmin: user.level.id === ADMIN,
    isManager: user.level.id === MANAGER,
    isUser: user.level.id === USER,
    // update: (data) => updateUser(this.profile_id, data).then((resp) => {
    //     authClient.getUser()
    //     return resp
    //   })
  }
}

export {AuthProvider, AuthContext, useAuth, useUser}