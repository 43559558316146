import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const UKIcon = createIcon({
  displayName: 'UKIcon',
  viewBox: '0 0 512 512',
  path: <svg xmlns="http://www.w3.org/2000/svg" ><circle cx="256" cy="256" r="256" fill="#f0f0f0"/><g fill="#0052b4"><path d="M53 100.1a255 255 0 00-44.2 89.1H142l-89-89zM503.2 189.2a255 255 0 00-44.1-89l-89.1 89h133.2zM8.8 322.8a255 255 0 0044.1 89l89-89H9zM411.9 53a255 255 0 00-89.1-44.2V142l89-89zM100.1 459a255 255 0 0089.1 44.2V370l-89 89zM189.2 8.8a255 255 0 00-89 44.1l89 89V9zM322.8 503.2a255 255 0 0089-44.1l-89-89.1v133.2zM370 322.8l89 89a255 255 0 0044.2-89H370z"/></g><g fill="#d80027"><path d="M509.8 222.6H289.4V2.2a258.6 258.6 0 00-66.8 0v220.4H2.2a258.6 258.6 0 000 66.8h220.4v220.4a258.5 258.5 0 0066.8 0V289.4h220.4a258.5 258.5 0 000-66.8z"/><path d="M322.8 322.8L437 437c5.3-5.2 10.3-10.7 15-16.4l-97.7-97.8h-31.5zM189.2 322.8L75 437c5.2 5.3 10.7 10.3 16.4 15l97.8-97.7v-31.5zM189.2 189.2L75 75a256.6 256.6 0 00-15 16.4l97.7 97.8h31.5zM322.8 189.2L437 75a256.3 256.3 0 00-16.4-15l-97.8 97.7v31.5z"/></g></svg>
})


export const ItalyIcon = createIcon({
  displayName: 'ItalyIcon',
  viewBox: '0 0 512 512',
  path: <svg xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" r="256" fill="#f0f0f0"/><path d="M512 256A256 256 0 00345 16v480a256 256 0 00167-240z" fill="#d80027"/><path d="M0 256a256 256 0 00167 240V16A256 256 0 000 256z" fill="#6da544"/></svg>
})


export const MapIcon = createIcon({
  displayname : 'MapIcon',
  path: <svg xmlns="http://www.w3.org/2000/svg"><path d="M20.5 3L20.34 3.03L15 5.1L9 3L3.36 4.9C3.15 4.97 3 5.15 3 5.38V20.5C3 20.78 3.22 21 3.5 21L3.66 20.97L9 18.9L15 21L20.64 19.1C20.85 19.03 21 18.85 21 18.62V3.5C21 3.22 20.78 3 20.5 3ZM10 5.47L14 6.87V18.53L10 17.13V5.47ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM19 17.54L16 18.55V6.86L19 5.7V17.54Z" fill="currentColor"/></svg>
})

export const NoMapIcon = createIcon({
  displayName: 'NoMapIcon',
  path: <svg>
      <path d="M20.5 3L20.34 3.03L15 5.1L9 3L3.36 4.9C3.15 4.97 3 5.15 3 5.38V20.5C3 20.78 3.22 21 3.5 21L3.66 20.97L9 18.9L15 21L20.64 19.1C20.85 19.03 21 18.85 21 18.62V3.5C21 3.22 20.78 3 20.5 3ZM10 5.47L14 6.87V18.53L10 17.13V5.47ZM5 6.46L8 5.45V17.15L5 18.31V6.46ZM19 17.54L16 18.55V6.86L19 5.7V17.54Z" fill="currentColor"/>
      <path d="M19 6.3L17.8 5 12 10.6 6.3 4.9 5 6.3l5.7 5.7-5.7 5.7L6.3 19l5.7-5.7 5.7 5.7 1.4-1.4-5.7-5.7 5.7-5.7z" fill="#FF3B30"/>
    </svg>
})
