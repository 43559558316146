import React from 'react'
import { createIcon } from '@chakra-ui/react'

const TriangleLeftIcon = createIcon({
  displayName: 'TriangleLeftIcon',
  d: "M8.2 12.8a1 1 0 010-1.6l4.7-3.3a1 1 0 011.6.8v6.6a1 1 0 01-1.6.8l-4.7-3.3z"
})

const TriangleRightIcon = createIcon({
  displayName: 'TriangleRightIcon',
  d: "M15.8 12.8a1 1 0 000-1.6l-4.7-3.3a1 1 0 00-1.6.8v6.6a1 1 0 001.6.8l4.7-3.3z"
})

const TriangleDownIcon = createIcon({
  displayName: 'TriangleDownIcon',
  d: 'M12.8 15.8a1 1 0 01-1.6 0l-3.3-4.7a1 1 0 01.8-1.6h6.6a1 1 0 01.8 1.6l-3.3 4.7z'
})

const TriangleUpIcon = createIcon({
  displayName: 'TriangleUpIcon',
  d: 'M12.8 8.2a1 1 0 00-1.6 0l-3.3 4.7a1 1 0 00.8 1.6h6.6a1 1 0 00.8-1.6l-3.3-4.7z'
})

const TriangleDoubleIcon = createIcon({
  displayName: 'TriangleDoubleIcon',
  d: 'M12.8 1.3a1 1 0 00-1.6 0l-4 6.2A1 1 0 008 9h8a1 1 0 00.8-1.5l-4-6.2zM11.2 22.7a1 1 0 001.6 0l4-6.2A1 1 0 0016 15H8a1 1 0 00-.8 1.5l4 6.2z'
})


export { TriangleLeftIcon, TriangleRightIcon, TriangleDownIcon, TriangleDoubleIcon, TriangleUpIcon }