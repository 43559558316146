import React from 'react'
import { MainLayout } from '../layouts'
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "../shared/components/DataTable";
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import { EditIcon } from '@/shared/icons'
import { useUsers } from '../contexts/users';
import { useHistory } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useIsMobile, useIsTablet } from '../hooks';
import { usersTableCommonColumnProps } from '../components/elements';
import { useTranslation } from 'react-i18next';


const Users = (props) => {

  const {users, isFetching, isError} = useUsers()
  const history = useHistory()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const {t} = useTranslation(['general'])

  if (isFetching) {
    return <FullPageSpinner />
  }

  const columnsProps = {
    ...usersTableCommonColumnProps(isMobile, isTablet, t),
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => <EditIcon
        cursor='pointer'
        fontSize={{ base: "xs", md: "md" }}
        onClick={() => history.push(`/manage/users/${row.id}`)}
      />
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <MainLayout heading={t('general:users', 'users').toCapitalCase()} my={16}>
      <Box flex="1" textAlign="left" my={12}>
        <DataTable
          data={users}
          columns={columns}
          toolbar={(props) => <Box width={{base: '100%', md: '30%'}}>
                                <SearchBar searchProps={props.searchProps} mb={6} />
                              </Box>}
        />
      </Box>
    </MainLayout>
  )

}

export default Users