import { Box } from '@chakra-ui/react';
import React from 'react'
import ResponsiveText from '../ResponsiveText';
import { SorterIcon } from './elements'



export function onSelectRow(row, isSelected, selectedRows, filterBy='id'){
  if(!isSelected) {
    return [...selectedRows.filter(key => key !== row[filterBy])];
  } else {
    return [...selectedRows, row[filterBy]];
  }
}

function formatColumnText(column){
  column = column.indexOf("_") !== -1 ? column.replace("_", " ") : column;
  return column.toCapitalCase();
}

const standardDefaultColumnsProps = {
  isKey: false,
  key: (column) => column,
  align: 'center',
  headerStyle: (colum, colIndex) => {
    return { textAlign: 'center'};
  },
  headerAlign: 'center',
  headerFormatter: (column) => (col, colIndex, components) => <ResponsiveText>{col.text} <SorterIcon col={col} components={components}/></ResponsiveText>,
  dataField: (column) => column,
  hidden: false,
  formatter: (column) => (cell, row) => <ResponsiveText>{cell}</ResponsiveText>,
  style: {},
  sort: false,
  width: null,
  isDummyField: false,
  text: (column) => formatColumnText(column)
};

function _getColumnProp(column, prop, columnProps, defaultProp){
  if (columnProps && columnProps.hasOwnProperty(prop)){
    return columnProps[prop];
  } else {
    return typeof defaultProp === 'function' ? defaultProp(column) : defaultProp;
  }
}

function _getColumnProps(column, columnsWithProps, defaultProps){
  return Object.keys(defaultProps).reduce((toReturn, prop) => {
    toReturn[prop] = _getColumnProp(column, prop, columnsWithProps[column], defaultProps[prop] );
    return toReturn;
  }, {});
}

/** Return uniformed columns with uniformed props based on reactbootstrap table
   * @param {List} columns list of columns
   * @param {Object} columnsWithProps object with key column_id and value column props
   * @param {Object} defaultColumnsProps object with key column_id and value column props,
   * make sure to add `column` as param if a prop has to return a function
   * */
export function getUniformedColumns(columns, columnsWithProps, defaultColumnsProps){
  const newDefaultsColumnsProps = { ...standardDefaultColumnsProps, ...defaultColumnsProps };
  return columns.map(column => _getColumnProps(column, columnsWithProps, newDefaultsColumnsProps));
}