import axios from 'axios'

export function updateUser(id, data){
  return axios.patch(`/api/userprofiles/${id}/`, data)
}

export const readNotification = (notificationId) => {
  return axios.patch(`/api/notifications/values/${notificationId}/`, {read: true})
}

export const markAsReadMultipleNotifications = (profileId, ids) => {
  return axios.post(
    `/api/userprofiles/${profileId}/mark_notifications_as_read/`,
    {ids}
  )
}