import axios from 'axios'
import Cookies from 'js-cookie';

export const MAX_AUTH_RETRIES = 5;
const tokenKeyName = 'token'

function handleLoginSuccessResponse({data: {token, is_registered}}){
  sessionStorage.setItem(tokenKeyName, token)
  sessionStorage.setItem('init_time', Date.now());
  // In case of successful login remove lockdown entries from the localstorage.
  localStorage.removeItem('lock_start');
  localStorage.removeItem('retries');
  return {
    isAuthenticated: true,
    redirect_to: '/',
    statusText: "You have been successfully logged in."
  }
}

function handleLoginErrorResponse({response}){
  let statusText = 'Authentication Error!!';
  let retries = parseInt(localStorage.getItem('retries')) || 0;

  for (let field in response.data){
    try {
      statusText = response.data[field][0];
    }
    catch (err){
      console.error('autherror', err.message);
    }
  }
  retries += 1;
  localStorage.setItem('retries', retries);
  if ( retries === MAX_AUTH_RETRIES ){
    localStorage.setItem('lock_start', Date.now());
  }
  return {
    isAuthenticated: false,
    statusText
  }
}


function login({ username, password}){
  return axios.post("/api-token-auth/", {
    username, password
  })
  .then(handleLoginSuccessResponse)
  .catch(handleLoginErrorResponse);
}

function getUser() {
  const token = getToken()
  if (!token) {
    return Promise.resolve(null)
  }

  return axios.get('/api/get_user_info/')
    .catch(error => {
      logout()
      return Promise.reject(error)
    });
}

function logout(){
  sessionStorage.removeItem(tokenKeyName);
  sessionStorage.removeItem('init_time');
  return Promise.resolve()
}

function getToken() {
  // Get token from cookie ad set it to sessionStorage
  const c_token = Cookies.get(tokenKeyName);
  if (c_token){
    sessionStorage.setItem(tokenKeyName, c_token);
    sessionStorage.setItem('init_time', Date.now());
    Cookies.remove(tokenKeyName);
  }

  return sessionStorage.getItem(tokenKeyName);
}


export { login, logout, getToken, getUser }