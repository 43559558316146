import React from "react";
import { Route } from "react-router";

import Users from './Users'
import User from './User'
import Notifications from './Notifications'
import Vpn from './Vpn'
import Reports from './Reports'
import Updates from "./Updates/Updates";

import {
  MANAGER_USERS_URL,
  MANAGER_USER_URL,
  MANAGER_NOTIFICATIONS_URL,
  MANAGER_VPNS_URL,
  MANAGER_REPORTS_URL,
  MANAGER_UPDATES_URL,
} from '../api/urls';


const ManagerRoutes = (authProps) => {

  return (
    <div>
      <Route exact path={MANAGER_USER_URL} render={(props) => <User {...props} {...authProps} />} />
      <Route exact path={MANAGER_USERS_URL} render={(props) => <Users {...props} {...authProps} />} />
      <Route path={MANAGER_NOTIFICATIONS_URL} render={props => <Notifications {...props} {...authProps} />} />
      <Route path={MANAGER_VPNS_URL} render={props => <Vpn {...props}{...authProps} />} />
      <Route path={MANAGER_UPDATES_URL} render={props => <Updates {...props}{...authProps} />} />
      <Route path={MANAGER_REPORTS_URL} render={props => <Reports {...props} {...authProps} />} />
    </div>
  );
};

export default ManagerRoutes;