import React, { useState, useCallback } from 'react'
import { useMinds } from '@/contexts/minds';
import { useMachines } from '@/contexts/machines';
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "@/shared/components/DataTable";
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import {
  Box, HStack, Text, Badge, Tooltip, useColorMode, useToast, Center, useDisclosure, FormLabel, Switch
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { handleMindLogin } from "@/api/minds";
import { goOnMindFormatter } from "@/utils/formatters";
import { mode } from "@/shared/theme";
import { formatDistance } from "date-fns";
import GroupFilter from "@/shared/components/GroupFilter";
import ResponsiveText from "@/shared/components/ResponsiveText";
import { InfoIcon } from '../../shared/icons';
import { ModalSystemTable } from "./elements"
import axios from 'axios';
import { STANDARD_ERROR_MSG, TOAST_POSITION } from "@/consts";


const Machines = (props) => {
  const { machines, isFetching } = useMachines()
  const { minds } = useMinds()
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { t } = useTranslation(['general', 'machines'])
  const textColor = mode('frigel.neutral.white', 'frigel.dark.black')({ colorMode })
  const [selectedSoftwares, setSelectedSoftwares] = useState([])
  const [selectedModels, setSelectedModels] = useState([])
  const [dataSystem, setDataSystem] = useState({})
  const [onlineMachine, setOnlineMachine] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const applyFilters = useCallback((machines) => {
    let filter_machines = machines

    if (selectedModels && selectedModels.length > 0) {
      filter_machines = filter_machines.filter((machine) => selectedModels.some((v) => { return v.value == machine.machine_model }))
    }
    if (selectedSoftwares && selectedSoftwares.length > 0) {
      filter_machines = filter_machines.filter((machine) => selectedSoftwares.some((v) => {
        const sw = v.value.split(':')[0]
        const version = v.value.split(':')[1]
        if (!version) {
          return sw == machine.software
        }
        return sw == machine.software && machine.version.startsWith(version)
      }))
    }
    if (onlineMachine) {
      const new_minds_list = minds.filter((mind) => mind.status != 'offline' && mind.status['num_vpn'] !== mind.status['warning']?.length)
      filter_machines = filter_machines.filter((machine) => machine.machine_status.general_status != 'offline' && new_minds_list.map(m=>m.id).includes(machine.mind_id))
    }

    return filter_machines
  }, [selectedSoftwares, selectedModels, onlineMachine])


  if (isFetching || minds.length == 0) {
    return <FullPageSpinner />
  }

  const machine_filtered = applyFilters(machines)

  const columnsProps = {
    id: { hidden: true, isKey: true },
    serial: {
      text: t('general:serial-number', 'Serial number'),
      sort: true,
      headerAlign: 'left',
      align: 'left',
      formatter: (cell, row) => <ResponsiveText compress={'15ch'}>{cell}</ResponsiveText>,
    },
    machine_model: {
      text: t('general:model', 'model').toCapitalCase(),
      sort: true,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => ['ECS3P2'].includes(row.software) && row.infosystem3pr ? <> {cell} <InfoIcon
        cursor='pointer'
        fontSize={{ base: "xs", md: "md" }}
        onClick={() => {
          axios.get('/api/get_system_info/', { params: { machine_serial: row.serial, mind_id: row.mind_id } })
            .then((resp) => {
              setSelectedMachine(row.serial)
              setDataSystem(resp.data)
              if (resp.data.hasOwnProperty('system')) {
                onOpen()
              }
            })
            .catch((reason) => {
              toast({
                status: 'error',
                description: STANDARD_ERROR_MSG,
                position: TOAST_POSITION
              });
              console.error(reason);
            })
        }
        }
      />
      </> :
        cell
    },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      sort: true,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => <ResponsiveText compress={'15ch'}>{cell}</ResponsiveText>,
    },
    software: {
      text: ('software').toCapitalCase(),
      sort: true,
      headerAlign: "left",
      align: "left",
    },
    version: {
      text: ('version').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    mind_serial: {
      text: "MiND serial",
      sort: true,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => {
        const mind = minds.find((item => item['id'] === row.mind_id))
        return <ResponsiveText compress={'15ch'}>{mind.serial}</ResponsiveText>
      }
    },
    machine_status: {
      text: `${t('general:Status', 'status').toCapitalCase()} (${t('general:last-seen', 'last seen')})`,
      headerAlign: "Center",
      align: "Center",
      formatter: (cell, row) => {
        if (cell['general_status']) {
          return (
            <HStack justifyContent="center">
              <Badge bgColor={cell['general_status'] ? `frigel.${colorMode}.status.${cell['general_status']}` : 'frigel.neutral.gray1'} borderRadius="md"
                color={cell['general_status'] == 'offline' ? textColor : 'frigel.neutral.white'} p={1}>
                {/* i18next-extract-disable-next-line */}
                {t(`machines:${cell['general_status'].toLowerCase().toCapitalCase()}`, cell['general_status'])}
              </Badge>
              {row['last_seen'] &&
                <>
                  <Tooltip label={row['last_seen'].split('T')[0].concat(' ', row['last_seen'].split('T')[1])}>
                    <Text fontSize='sm'>{`(${formatDistance(new Date(row['last_seen']), new Date())})`}</Text>
                  </Tooltip>
                </>}
            </HStack>
          )
        }
        return <Text>{'-'}</Text>
      }
    },

    goOnMind: {
      isDummyField: true,
      text: `${t('minds:login', 'login').toCapitalCase()}`,
      formatter: (cell, row) => {
        const mind = minds.find((item => item['id'] === row['mind_id']))
        return goOnMindFormatter(cell, mind, () => handleMindLogin(mind.mid, mind.serial, mind.mind_url, toast, t), colorMode)
      }
    },
  }
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);


  function options(type, selector) {
    let list_machines = machines
    if ((selectedModels.length > 0 && selector == selectedSoftwares)) {
      list_machines = list_machines.filter((machine) => selectedModels.some((v) => { return v.value == machine.machine_model }))
    }

    if ((selectedSoftwares.length > 0 && selector == selectedModels)) {
      list_machines = list_machines.filter((machine) => selectedSoftwares.some((v) => {
        const sw = v.value.split(':')[0]
        const version = v.value.split(':')[1]
        return sw == machine.software && machine.version.startsWith(version)
      }))
    }

    if (onlineMachine) {
      list_machines = list_machines.filter((machine) => machine.machine_status.general_status != 'offline' && minds.find(obj => obj.id === machine.mind_id).status != 'offline')
    }

    if (type == 'model_name') {
      return [...new Map(list_machines.map((machine) => (machine.is_modbus || !machine.software.startsWith("ECS") ? '' : machine[type]))
        .filter((v) => (['-', undefined, null, ""].includes(v) == false))
        .filter((v) => (selector.length == 0 || selector.map((v) => v.value).includes(v) == false))
        .map((v) => (v.split('.').map((item, index) => {
          if (index == 0) {
            return [{ label: v.split(':')[0], value: v.split(':')[0] }, { label: v.split('.')[0], value: v.split('.')[0] }]
          } else {
            return { label: v.split('.').slice(0, index + 1).join('.'), value: v.split('.').slice(0, index + 1).join('.') }
          }
        })))
        .flatMap(x => { if (Array.isArray(x)) { return x.flat() } else { return x } })
        .map(item => [item['value'], item])).values()]
        .sort((a, b) => a.value.localeCompare(b.value))
    } else {
      return [...new Map(list_machines.map((machine) => (machine.is_modbus ? '' : machine[type]))
        .filter((v) => (['-', undefined, null, ""].includes(v) == false))
        .filter((v) => (selector.length == 0 || selector.map((v) => v.value).includes(v) == false))
        .map((v) => ({ label: v, value: v }))
        .map(item => [item['value'], item])).values()]
        .sort((a, b) => a.value.localeCompare(b.value))
    }
  }

  return (
    <>
      <Box flex="1" textAlign="left" my={12}>
        <DataTable
          data={machine_filtered}
          columns={columns}
          noDataIndication={<Center>{t('general:NoData', "No data to display")}</Center>}
          customSizePerPage={15}
          toolbar={(props) => <Box width={{ base: '100%', md: '80%' }}>
            <HStack>
              <Box width="30%">
                <SearchBar searchProps={props.searchProps} />
              </Box>
              <GroupFilter
                value={selectedModels}
                placeholder={t('machines:FilterModel', 'Filter by model')}
                options={options('machine_model', selectedModels)}
                onChange={values => { values != null ? setSelectedModels(values) : setSelectedModels([]) }}
              />
              <GroupFilter
                value={selectedSoftwares}
                placeholder={t('machines:FilterSoftware', 'Filter by software')}
                options={options('model_name', selectedSoftwares)}
                onChange={values => { values != null ? setSelectedSoftwares(values) : setSelectedSoftwares([]) }}
              />

              <FormLabel marginLeft={2} fontSize='sm'>{'Connected :'}</FormLabel>
              <Switch cursor="pointer" isChecked={onlineMachine} onChange={() => { setOnlineMachine(!onlineMachine) }} />

            </HStack>
          </Box>}
        />
      </Box>
      <ModalSystemTable machineSerial={selectedMachine} dataSystem={dataSystem} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Machines;