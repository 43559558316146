import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  useColorMode,
  ModalBody,
  IconButton,
  useDisclosure,
  FormLabel,
  Select,
  VStack,
  FormControl,
  Text,
  Input,
  ModalFooter,
  useToast,
  FormHelperText,
  Tooltip
} from "@chakra-ui/react";
import { default as ReactSelect } from "react-select";
import AsyncSelect from "react-select/async";
import { AddUserIcon, EditIcon } from "../../shared/icons";
import ModalSkeleton from "@/shared/components/ModalSkeleton";
import { PERMISSIONS_LIST } from "@/consts";
import theme from '../../shared/theme';
import { reactSelectDarkColors, secondaryBtnStyle} from "../../shared/theme";
import { StaticRow } from "@/components/elements";
import { TOAST_POSITION, STANDARD_ERROR_MSG } from "../../consts";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";
import {Button} from '@/shared/components/core/Button'
import { fetchCompanies, fetchCompanyCoordinates, upsertCompany } from "../../api/companies";
import {KeyIcon} from '@/shared/icons'
import { getToken } from '../../api/minds'
import { Chart, registerables } from 'chart.js';
import { Bar } from "react-chartjs-2";

export const AddUserModal = ({ users, onSubmit }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [selectedUser, setSelectedUser] = useState();
  const [permission, setPermission] = useState(1);
  const [availablePermissions, setAvailablePermissions] = useState([]);
  const toast = useToast()
  const { t } = useTranslation(['general', 'minds'])

  useEffect(() => {
    if (selectedUser) {
      const permissions = PERMISSIONS_LIST.filter(
        (p) => selectedUser.level.id >= p.id
      );
      setAvailablePermissions(permissions);
    }
  }, [selectedUser]);

  return (
    <>
      <IconButton
        icon={<AddUserIcon />}
        size="sm"
        fontSize="lg"
        onClick={() => onOpen()}
        {...secondaryBtnStyle({ colorMode })}
      />
      <ModalSkeleton isOpen={isOpen} onClose={onClose} title={startCase(t('general:add-user', "add user"))}>
        <ModalBody>
          <Box
            as="form"
            w="full"
            id='add_user_to_mind'
            onSubmit={(e) => {
              e.preventDefault();
              // TODO: Add api call
              onSubmit({ permission, selectedUser })
                .then(() => onClose())
                .catch(reason => {
                  toast({
                    status: 'error',
                    description: STANDARD_ERROR_MSG,
                    position: TOAST_POSITION,
                    isClosable: true,
                  })
                  console.error(reason)
                })
            }}
          >
            <VStack align="flex-start">
              <FormControl w="full" id="users">
                <FormLabel>User</FormLabel>
                <ReactSelect
                  theme={colorMode === 'dark' && { colors: reactSelectDarkColors }}
                  name="users"
                  isSearchable={true}
                  isClearable={true}
                  onChange={(s) => {
                    s ? setSelectedUser(s.user) : setSelectedUser();
                  }}
                  options={users.map((u) => ({
                    value: u.id,
                    label: u.fullname || u.email,
                    user: u,
                  }))}
                />
              </FormControl>

              <FormControl isDisabled={!selectedUser}>
                <FormLabel>{t('general:permission', 'permission').toCapitalCase()}</FormLabel>
                <Select
                  value={permission}
                  onChange={(e) => setPermission(e.target.value)}
                >
                  {availablePermissions.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant='secondary' mr={2}>
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end" variant='primary' type="submit" form='add_user_to_mind'>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};

export const EditCompanyModal = ({ currentCompany, onSubmit }) => {
  const [company, setCompany] = useState(currentCompany);

  const { name, address, latitude, longitude, country, sector } = company;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [companyToSubmit, setCompanyToSubmit] = useState();
  const [companiesCount, setCompaniesCount] = useState(-1)
  const [coordinate, setCoordinate] = useState();
  const { colorMode } = useColorMode();
  const toast = useToast()
  const { t } = useTranslation(['general', 'minds'])

  const promiseOptions = (inputValue) => fetchCompanies(inputValue)
    .then(resp => {
      const [options, data] = resp
      setCompaniesCount(data.count)
      return options
    });

  useEffect(() => {
    if (!isOpen) {
      // Reset
      setCompany(currentCompany)
    }
  }, [isOpen])


  useEffect(() => {
    axios.post('/api/get_country/', company)
    .then(({data}) =>{
      if (data.message){
        toast({
          status: 'error',
          description: data.message,
          position: TOAST_POSITION,
          isClosable: true,
        })
      }
      setCompany({
        name: data.name,
        address: data.address,
        latitude: data.latitude,
        longitude: data.longitude,
        country:{ code :data.country.code ,name : data.country.name},
        serial: data.serial,
        sector: data.sector
      });
    })
  }, [coordinate])

  return (
    <>
      <EditIcon onClick={onOpen} cursor="pointer" />
      <ModalSkeleton
        isOpen={isOpen}
        title={`${t('general:edit', 'edit').toCapitalCase()} MiND ${t('general:company', 'company').toCapitalCase()}`}
        onClose={onClose}
        size="xl"
      >
        <ModalBody>
          <VStack
            align="flex-start"
            w="full"
            as="form"
            id="companyForm"
            onSubmit={(e) => {
              e.preventDefault();
              let dataToSubmit = {
                ...company,
                ...companyToSubmit,
                country: country.code
              }
              dataToSubmit =  sector ? {...dataToSubmit, sector: sector.id} : {...dataToSubmit}

              upsertCompany(dataToSubmit)
                .then(data => {
                  onSubmit(data)
                  .then(() => onClose())
                  .catch((reason) => {
                    toast({
                      status: 'error',
                      description: STANDARD_ERROR_MSG,
                      position: TOAST_POSITION,
                      isClosable: true,
                    })
                    console.error(reason)
                  })
                })
                .catch(reason => {
                  toast({
                    status: 'error',
                    description: STANDARD_ERROR_MSG,
                    position: TOAST_POSITION,
                    isClosable: true,
                  })
                  console.error(reason)
                })

            }}
          >
            <FormControl my={4} w="inherit" id='search_company'>
              {companiesCount > 20 && <FormHelperText mb={1}>
                  {t('minds:refine-company-search', 'If you do not find the searched company please refine the search...')}
                </FormHelperText>}
              <AsyncSelect
                placeholder={`${t('general:Search', 'Search')} ${t('general:company', 'company')}...`}
                theme={colorMode === 'dark' && {colors:  reactSelectDarkColors}}
                onChange={(c) => {
                  fetchCompanyCoordinates(c.company).then((coordinates) => {
                    const selectedCompnayBEnames = {
                      ...c.company,
                      ...coordinates,
                    };
                    setCompany({
                      name: selectedCompnayBEnames.ragione_sociale,
                      address: [
                        selectedCompnayBEnames.indirizzo,
                        selectedCompnayBEnames.cap,
                        selectedCompnayBEnames.localita,
                        selectedCompnayBEnames.nazione,
                      ].join(", "),
                      latitude: selectedCompnayBEnames.latitude,
                      longitude: selectedCompnayBEnames.longitude,
                      country: { name: selectedCompnayBEnames.nazione },
                    });
                    setCompanyToSubmit(selectedCompnayBEnames);
                  });
                }}
                cacheOptions
                noOptionsMessage={() => (
                  companiesCount === -1
                    ? t('minds:company-type-to-load-options', 'Type to load the available options')
                    : t('minds:no-options', 'no options').toCapitalCase()
                )}
                loadOptions={promiseOptions}
              />
            </FormControl>
            <Box px={4}>
              <StaticRow label={t('general:name', 'name').toCapitalCase()} labelProps={{w: '40%'}} content={<Text w='70%'>{name}</Text>} />
              <StaticRow label={t('general:address', "address").toCapitalCase()} labelProps={{w: '40%'}} content={<Text w='70%'>{address}</Text>} />
              <StaticRow
                label={t('general:latitude', "latitude").toCapitalCase()}
                labelProps={{w: '40%'}}
                content={
                  <Input type='number' id='latitude' px={2} w='200px'
                    value={latitude}
                    onChange={(e) => {
                      setCompanyToSubmit({ ...companyToSubmit, latitude: e.target.value });
                      setCompany({ ...company, latitude: e.target.value });
                      setCoordinate(latitude)
                    }}
                    isRequired />
                }
              />
              <StaticRow
                label={t('general:longitude', "longitude").toCapitalCase()}
                labelProps={{w: '40%'}}
                content={
                  <Input type='number' id='longitude' px={2} w='200px'
                    value={longitude}
                    onChange={(e) => {
                      setCompanyToSubmit({ ...companyToSubmit, longitude: e.target.value });
                      setCompany({ ...company, longitude: e.target.value });
                      setCoordinate(longitude);
                    }}
                    isRequired />
                }
                />
                  <StaticRow label={t('general:country', "country").toCapitalCase()} labelProps={{ w: '40%' }}
                    content={country.name} />
                    {/* <StaticRow label='Sector' content={sector.name}/> */}
            </Box>
          </VStack>
        </ModalBody>

          <ModalFooter>
            <Button
              alignSelf="flex-end" variant='secondary'
              onClick={onClose}
            >
              {t('general:Cancel', 'Cancel')}
            </Button>
            <Button
              ml={3}
              form="companyForm"
              alignSelf="flex-end"
              variant='primary'
              type="submit"
            >
              {t('general:Submit', 'Submit')}
            </Button>
          </ModalFooter>
      </ModalSkeleton>
    </>
  );
};


export const AccessKey = ({mid}) => {
  const [token, setToken] = useState()
  const {t} = useTranslation(['general', 'minds'])
  const toast = useToast()

  const successToast = () => {
    toast({
      title: t('minds:token-copied', 'Access token copied to clipboard').toCapitalCase(),
      position: TOAST_POSITION,
      isClosable: true,
      })
    }

  return (
    <>
      <Tooltip placement='top' label={
          <Box>
            <Box>{t('general:access-token', 'access token').toCapitalCase()}</Box>
          </Box>
        }>
        <KeyIcon
          cursor='pointer'
          fontSize={{base: 'xs', md: 'md'}}
          onClick={() => {
          if (token) {
            navigator.clipboard.writeText(token)
            successToast()
          }
          else {
            getToken(mid)
            .then(({data: {token, error}}) => {
              if (token){
                setToken(token)
                navigator.clipboard.writeText(token)
                successToast()
              } else {
                toast({
                  status: 'error',
                  description: error,
                  position: TOAST_POSITION,
                  isClosable: true,
                })
              }
            })
          }
        }}/>
      </Tooltip>
    </>
  )
};

export const ConnectionHistory = ({datasets}) => {
  const { colorMode } = useColorMode()
  Chart.register(...registerables);
  const currentHour = new Date().getHours();
  const labels = Array.from({length: 24}, (_, i) => (i + currentHour + 1) % 24)
  return (
    <Bar
      data={{
        labels: labels,
        datasets: datasets
    }}
      options={{
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: true,
                labels: {
                    boxWidth: 12,
                    useBorderRadius: true,
                    borderRadius: 6,
                    color: colorMode === 'light' ? theme.colors.frigel.neutral.darkGray : theme.colors.frigel.neutral.white
                },
            }
        },

        scales: {
            x: {
                stacked: true,
                grid: {
                    color: colorMode === 'light' ? Chart.defaults.borderColor : 'white',
                },
                ticks: {
                    color: colorMode === 'light' ? theme.colors.frigel.neutral.darkGray : theme.colors.frigel.neutral.white
                },
                border: {
                    color: colorMode === 'light' ? Chart.defaults.borderColor : 'white',
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks: {
                    display: false
                },
                border: {
                    color: colorMode === 'light' ? Chart.defaults.borderColor : 'white',
                }
            }
        },
        responsive: true,
        maintainAspectRatio : false
    }
  }
  />
  )
}