import React, { useState, useCallback, useEffect } from 'react'
import { useMinds } from '@/contexts/minds';
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "@/shared/components/DataTable";
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import {
  Box, HStack, Text, Badge, Tooltip, useColorMode, useToast, Center, useDisclosure, Switch,
  FormLabel, Divider
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { handleMindLogin } from "@/api/minds";
import { goOnMindFormatter } from "@/utils/formatters";
import { mode } from "@/shared/theme";
import { formatDistance } from "date-fns";
import GroupFilter from "@/shared/components/GroupFilter";
import ResponsiveText from "@/shared/components/ResponsiveText";
import { InfoIcon } from '../../shared/icons';
import { STANDARD_ERROR_MSG, TOAST_POSITION, ECODRY_MODELS } from "@/consts";
import { ModalSystemTable } from "./elements";
import axios from 'axios';
import { includes } from 'lodash';


const Systems = (props) => {
  const { minds } = useMinds()
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { t } = useTranslation(['general', 'machines'])
  const textColor = mode('frigel.neutral.white', 'frigel.dark.black')({ colorMode })
  const [selectedEcodry, setSelectedEcodry] = useState([])
  const [selectedCircuits, setSelectedCircuits] = useState([])
  const [systems, setSystems] = useState()
  const [dataSystem, setDataSystem] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [chillerBox, setChillerBox] = useState(false)
  const [onlineMachine, setOnlineMachine] = useState(false)
  const [selectedMachine, setSelectedMachine] = useState(null)

  useEffect(() => {
    axios.get('/api/get_all_systems/')
      .then((response) => {
        setSystems(response.data)
      }

      )
  }, [])


  const applyFilters = useCallback((systems) => {
    let filter_machines = systems

    if (selectedCircuits && selectedCircuits.length > 0) {
      filter_machines = filter_machines.filter((system) => selectedCircuits.some((v) => { return system.circuits.includes(v.value) }))
    }
    if (selectedEcodry && selectedEcodry.length > 0) {
      filter_machines = filter_machines.filter((system) => selectedEcodry.some((v) => { return v.value == system.ecodry.model }
      ))
    }

    if (chillerBox) {
      filter_machines = filter_machines.filter((system) => system.central_chiller.chiller_box)
    }

    if (onlineMachine) {
      const new_minds_list = minds.filter((mind) => mind.status != 'offline' && mind.status['num_vpn'] !== mind.status['warning']?.length)
      filter_machines = filter_machines.filter((system) => system.machine_status.general_status != 'offline' && new_minds_list.map(m=>m.id).includes(system.mind_id))
    }

    return filter_machines
  }, [selectedEcodry, selectedCircuits, chillerBox, onlineMachine])


  if (!systems) {
    return <FullPageSpinner />
  }


  const machine_filtered = applyFilters(systems)

  const columnsProps = {
    id: { hidden: true, isKey: true },
    serial: {
      text: t('general:serial-number', 'Serial number'),
      sort: true,
      headerAlign: 'left',
      align: 'left',
      formatter: (cell, row) => <ResponsiveText compress={'15ch'}>{cell}</ResponsiveText>,
    },
    version: {
      text: ('version').toCapitalCase(),
      sort: true,
      headerAlign: "left",
      align: "left",
    },
    ecodry: {
      text: t('machines:Ecodry', 'Ecodry').toCapitalCase(),
      sort: true,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => <ResponsiveText compress={'15ch'}>{ECODRY_MODELS[cell.model] ? ECODRY_MODELS[cell.model].name : cell.model}</ResponsiveText>
    },
    central_chiller: {
      text: t('machines:Chiller', 'Chiller').toCapitalCase(),
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => <Text>{cell.chiller_box ? `serial: ${cell.n_serial_chiller}, digital: ${cell.n_digital_chiller}` : '-'} </Text>

    },
    circuits: {
      text: t('machines:Circuits', 'Circuits').toCapitalCase(),
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => <ResponsiveText compress={'15ch'}>{cell.join(', ')}</ResponsiveText>
    },
    chiller_box: {
      text: t('machines:ChillerBox', 'Chiller box').toCapitalCase(),
      headerAlign: "left",
      align: "left",
      hidden: true,
    },
    mind_serial: {
      text: "MiND serial",
      sort: true,
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => {
        const mind = minds.find((item => item['id'] === row['mind_id']))
        return <ResponsiveText compress={'15ch'}>{mind.serial}</ResponsiveText>
      }
    },
    machine_status: {
      text: `${t('general:Status', 'status').toCapitalCase()} (${t('general:last-seen', 'last seen')})`,
      headerAlign: "Center",
      align: "Center",
      formatter: (cell, row) => {
        if (cell['general_status']) {
          return (
            <HStack justifyContent="center">
              <Badge bgColor={cell['general_status'] ? `frigel.${colorMode}.status.${cell['general_status']}` : 'frigel.neutral.gray1'} borderRadius="md"
                color={cell['general_status'] == 'offline' ? textColor : 'frigel.neutral.white'} p={1}>
                {/* i18next-extract-disable-next-line */}
                {t(`machines:${cell['general_status'].toLowerCase().toCapitalCase()}`, cell['general_status'])}
              </Badge>
              {row['last_seen'] &&
                <>
                  <Tooltip label={row['last_seen'].split('T')[0].concat(' ', row['last_seen'].split('T')[1])}>
                    <Text fontSize='sm'>{`(${formatDistance(new Date(row['last_seen']), new Date())})`}</Text>
                  </Tooltip>
                </>}
            </HStack>
          )
        }
        return <Text>{'-'}</Text>
      }
    },

    goOnMind: {
      isDummyField: true,
      text: `${t('minds:login', 'login').toCapitalCase()}`,
      formatter: (cell, row) => {
        const mind = minds.find((item => item['id'] === row['mind_id']))
        return goOnMindFormatter(cell, mind, () => handleMindLogin(mind.mid, mind.serial, mind.mind_url, toast, t), colorMode)
      }
    },

    info: {
      isDummyField: true,
      text: t('general:info', 'info').toCapitalCase(),
      formatter: (cell, row) => (
        <InfoIcon
          cursor='pointer'
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            axios.get('/api/get_system_info/', { params: { machine_serial: row.serial, mind_id: row.mind_id } })
              .then((resp) => {
                setSelectedMachine(row.serial)
                setDataSystem(resp.data)
                if (resp.data.hasOwnProperty('system')) {
                  onOpen()
                }
              })
              .catch((reason) => {
                toast({
                  status: 'error',
                  description: STANDARD_ERROR_MSG,
                  position: TOAST_POSITION
                });
                console.error(reason);
              })
          }
          }
        />
      ),
    },
  }
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);


  function options(type, selector) {
    let list_systems = systems
    if ((selectedCircuits.length > 0 && selector == selectedEcodry)) {
      list_systems = list_systems.filter((system) => selectedCircuits.some((v) => { return system.circuits.includes(v.value) }))
    }

    if ((selectedEcodry.length > 0 && selector == selectedCircuits)) {
      list_systems = list_systems.filter((system) => selectedEcodry.some((v) => {
        return v.value == system.ecodry.model
      }))
    }

    if (chillerBox) {
      list_systems = list_systems.filter((system) => system.central_chiller.chiller_box)
    }

    if (onlineMachine) {
      list_systems = list_systems.filter((system) => system.machine_status.general_status != 'offline' && minds.find(obj => obj.id === system.mind_id).status != 'offline')
    }


    if (type == 'circuits') {
      return [...new Map(list_systems.map((systems) => (systems.circuits.length > 0 ? systems.circuits : ''))
        .flat()
        .filter((v) => (selector.length == 0 || selector.map((v) => v.value).includes(v) == false))
        .map((v) => ({ label: v, value: v }))
        .map(item => [item['value'], item])).values()]
    } else if (type == 'ecodry') {
      return [...new Map(list_systems.map((systems) => (systems.ecodry.model != null ? systems.ecodry.model : ''))
        .filter((v) => (selector.length == 0 || selector.map((v) => v.value).includes(v) == false))
        .map((v) => (ECODRY_MODELS[v] ? { label: ECODRY_MODELS[v].name, value: v } : { label: '', value: '' }))
        .map(item => [item['value'], item])).values()]
    }
  }

  return (
    <>
      <Box flex="1" textAlign="left" my={12}>
        <DataTable
          data={machine_filtered}
          columns={columns}
          noDataIndication={<Center>{t('general:NoData', "No data to display")}</Center>}
          customSizePerPage={15}
          toolbar={(props) => <Box width={{ base: '100%', md: '80%' }}>
            <HStack>
              <Box width="30%">
                <SearchBar searchProps={props.searchProps} />
              </Box>
              <GroupFilter
                value={selectedCircuits}
                placeholder={t('machines:FilterCircuits', 'Filter by circuits')}
                options={options('circuits', selectedCircuits)}
                onChange={values => { values != null ? setSelectedCircuits(values) : setSelectedCircuits([]) }}
              />
              <GroupFilter
                value={selectedEcodry}
                placeholder={t('machines:FilterEcodry', 'Filter by ecodry')}
                options={options('ecodry', selectedEcodry)}
                onChange={values => { values != null ? setSelectedEcodry(values) : setSelectedEcodry([]) }}
              />
              <FormLabel fontSize='sm'>{'Chiller box :'}</FormLabel>
              <Switch cursor="pointer" isChecked={chillerBox} onChange={() => { setChillerBox(!chillerBox) }} />
              <Center height='25px'>
                <Divider orientation='vertical' />
              </Center>
              <FormLabel fontSize='sm'>{'Connected :'}</FormLabel>
              <Switch cursor="pointer" isChecked={onlineMachine} onChange={() => { setOnlineMachine(!onlineMachine) }} />

            </HStack>
          </Box >}
        />
      </Box >
      <ModalSystemTable machineSerial={selectedMachine} dataSystem={dataSystem} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export default Systems;