import React from 'react'
import { updateUser } from '../api/users'
import { useAuth } from '../contexts/auth'
import { StarIcon } from '../shared/icons'

const FavoriteMind = ({mindId, ...rest}) => {
  const {user, fetchUser} = useAuth()
  const {preferences: { favoriteMinds=[] }} = user

  return (
  <StarIcon radius={5}  fontSize='lg'
    fill='none' color={favoriteMinds.includes(mindId) ? '#fffa00' : 'white'} stroke='#bbbbbb' strokeWidth='2px'
    cursor='pointer'
    _hover={{
      color: '#fffa00'
    }}

    onClick={() => {
      let returningPromise = Promise.resolve()
      if (favoriteMinds.includes(mindId)){
        const newFavoriteMinds = favoriteMinds.filter(
          mId => mId !== mindId
        )
        returningPromise = updateUser(user.profile_id, {
          preferences: {
            ...user.preferences,
            favoriteMinds: newFavoriteMinds
          }})

      } else {
        returningPromise = updateUser(user.profile_id, {
          preferences: {
            ...user.preferences,
            favoriteMinds: favoriteMinds ? [
              ...favoriteMinds, mindId
            ]: [mindId]
          }})

      }
      returningPromise.then(() => fetchUser())
    }}
    {...rest}
    />
  )
}
export default FavoriteMind;