import React, { useState, useEffect, useRef } from "react";
import { MainLayout } from "../../layouts";
import { useTranslation } from "react-i18next";
import { useData } from '../../hooks'
import { StatsCard } from './elements';
import { FRIGEL_BRANCHES_INTERACTIONS_LIST } from '@/consts'
import { Box, Text, HStack, ButtonGroup, Select, Stack, VStack } from '@chakra-ui/react';
import {Button} from '@/shared/components/core/Button'
import { useWindowDimensions } from '@/shared/utils';
import theme from '@/shared/theme'

const Stats = (props) => {
  const {t} = useTranslation(['general','reports']);
  const [statsMonthly, setStatsMonthly] = useState(true);
  const [statsBranch, setStatsBranch] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [heightTopElement, setHeightTopElement] = useState(0)
  const [floatingTitle, setFloatingTitle] = useState(0)
  const refTopElement = useRef(null)
  const minds_creation_dates = [12, 24]
  const minds_creation_dates_by_year = minds_creation_dates.map((item) => {return item/12})
  
  const {data:monthlyData, refetch:monthlyRefetch} = useData({
    url:'/api/get_stats_hub/',
    params: {type:'month', ticks: 12, minds_creation_dates, branch: FRIGEL_BRANCHES_INTERACTIONS_LIST[statsBranch]}
  })
  
  const {data:weeklyData, refetch:weeklyRefetch} = useData({
    url:'/api/get_stats_hub/',
    params: {type:'week', ticks: 12 , minds_creation_dates, branch: FRIGEL_BRANCHES_INTERACTIONS_LIST[statsBranch]}
  })
  
  const currentData = statsMonthly ? monthlyData : weeklyData;
  const {width:windowWidth} = useWindowDimensions()

  useEffect(() => {
    monthlyRefetch();
    weeklyRefetch();
  }, [statsBranch])

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, [])

  useEffect(() => {
    setHeightTopElement(refTopElement.current.clientHeight)
  }, [windowWidth])

  useEffect(() => {
    if (statsMonthly && monthlyData?.x_axis?.length>0) {
      setFloatingTitle(new Date(monthlyData?.x_axis[monthlyData.x_axis.length-1]).toLocaleString('default', { month: 'long', year: 'numeric' }));
    }
    else if (!statsMonthly && weeklyData?.x_axis?.length>0) {
      let date = new Date(Date.UTC(weeklyData?.x_axis[weeklyData.x_axis.length-1].split(':')[0], 0, (1 + (weeklyData?.x_axis[weeklyData.x_axis.length-1].split(':')[1] - 1) * 7)));
      const dayOfWeek = date.getDay();
      if (dayOfWeek <=4)
        date.setDate(date.getDate() - date.getDay() + 1);
      else
        date.setDate(date.getDate() + 8 - date.getDay());
      let dateEnd = new Date(date)
      dateEnd.setDate(date.getDate() + 6)
      setFloatingTitle(`${date.toLocaleString('default', { day: 'numeric', month: date.getMonth()===dateEnd.getMonth() ? undefined : 'short'})}-${dateEnd.toLocaleString('default', { day: 'numeric', month: 'short', year: 'numeric' })}`);
    }
    else {
      setFloatingTitle('');
    }
  }, [statsMonthly, monthlyData, weeklyData])

  return (
    <>
      <Box style={{position: 'fixed', top: `${Math.max(0, 48-scrollPosition)}px`, zIndex: 100}}  background='frigel.blueAccent' width='full' p='8px' ref={refTopElement}>
        <Stack justify="space-between" align='center' direction={['column', 'row']} px='80px'>
          <HStack spacing='40px'>
              <Text color='white' fontSize='md'>{t('general:Reference', 'Reference')}</Text>
              <Text color='white' fontSize='md' as='b'>
                {floatingTitle}
              </Text>
          </HStack>
          <HStack>
            <ButtonGroup variant='secondary' spacing='10px'>
              <Button isActive={!statsMonthly} onClick={() => setStatsMonthly(false)} mr={2} >
                {t('general:weekly', 'Weekly')}
              </Button>
              <Button isActive={statsMonthly} onClick={() => setStatsMonthly(true)} mr={2} >
                {t('general:monthly', 'Monthly')}
              </Button>
            </ButtonGroup>
            <Select value={statsBranch} width='80px' color='white' size='sm' onChange={(e) => setStatsBranch(e.target.value)}>
                {FRIGEL_BRANCHES_INTERACTIONS_LIST.map((item, index) => {return <option key={index} value={index}>{item}</option>})}
            </Select>
          </HStack>
        </Stack>
      </Box>
      <MainLayout heading={t('general:stats','Stats')} mt={`${heightTopElement}px`}>
        <VStack spacing='48px'>
          <StatsCard title={t('general:Overview', 'Overview')}
            cardData={[
              {
                chart_data: [
                  {data: currentData.minds_online, label: t('general:mindsOnline', 'MiNDs online'), color: theme.colors.frigel.neutral.chart1},
                  {data: currentData.minds?.map((item, index) => { return item -  currentData.minds_online[index] }), label:  t('general:mindsOffline', 'MiNDs offline'), color: theme.colors.frigel.neutral.chart2},
                ],
                x_axis: currentData.x_axis,
                cumulativeData: currentData.minds,
                cumulativeName: 'MiNDs',
              },
              {
                chart_data: [
                  {data: currentData.unique_customers, label:  t('general:activeCustomers', 'Active customers'), color: theme.colors.frigel.neutral.chart1},
                  {data: currentData.unique_users?.map((item, index) => { return item -  currentData.unique_customers[index] }), label: t('general:activeFrigelUsers', 'Active Frigel users'), color: theme.colors.frigel.neutral.chart2},
                ],
                x_axis: currentData.x_axis,
                cumulativeData: currentData.unique_users,
                cumulativeName: t('general:activeUsers', 'Active users'),
              }
            ]}
            monthly={statsMonthly}
            />

          
            <StatsCard title={t('general:mindsByCreation', 'MiNDs by creation')}
              cardData={[
                {
                  chart_data: [
                    {data: currentData.minds_created_online_12, label: `${t('general:mindsOnline', 'MiNDs online')} (${minds_creation_dates_by_year[0]}y)`, color: theme.colors.frigel.neutral.chart1},
                    {data: currentData.minds_created_12?.map((item, index) => { return item -  currentData.minds_created_online_12[index] }), label:  `${t('general:mindsOffline', 'MiNDs offline')} (${minds_creation_dates_by_year[0]}y)`, color: theme.colors.frigel.neutral.chart2},
                  ],
                  x_axis: currentData.x_axis,
                  cumulativeData: currentData.minds_created_12,
                  cumulativeName: `MiNDs (${minds_creation_dates_by_year[0]}y)`,
                },
                {
                  chart_data: [
                    {data: currentData.minds_created_online_24, label: `${t('general:mindsOnline', 'MiNDs online')} (${minds_creation_dates_by_year[1]}y)`, color: theme.colors.frigel.neutral.chart1},
                    {data: currentData.minds_created_24?.map((item, index) => { return item -  currentData.minds_created_online_24[index] }), label:  `${t('general:mindsOffline', 'MiNDs offline')} (${minds_creation_dates_by_year[1]}y)`, color: theme.colors.frigel.neutral.chart2},
                  ],
                  x_axis: currentData.x_axis,
                  cumulativeData: currentData.minds_created_24,
                  cumulativeName: `MiNDs (${minds_creation_dates_by_year[1]}y)`,
                }
              ]}
              monthly={statsMonthly}
            />

          <StatsCard title={t('general:Machines', 'Machines')}
            cardData={[
              {
                chart_data: [
                  {data: currentData.machines_online, label: t('general:machinesOnline', 'Machines online'), color: theme.colors.frigel.neutral.chart1},
                  {data: currentData.machines?.map((item, index) => { return item -  currentData.machines_online[index] }), label:t('general:machinesOffline', 'Machines offline'), color: theme.colors.frigel.neutral.chart2},
                ],
                x_axis: currentData.x_axis,
                cumulativeData: currentData.machines,
                cumulativeName: t('general:Machines', 'Machines'),
              },
            ]}
            monthly={statsMonthly}
            machines={currentData.machine_families}
            />

          <StatsCard title={t('general:notificationsUsage', 'Notifications Usage')}
            cardData={[
              {
                chart_data: [
                  {data: currentData.customers_with_notifications, label: t('general:customers', 'Customers'), color: theme.colors.frigel.neutral.chart1},
                  {data: currentData.users_with_notifications?.map((item, index) => { return item -  currentData.customers_with_notifications[index] }), label:  t('general:frigelUsers', 'Frigel users'), color: theme.colors.frigel.neutral.chart2},
                ],
                x_axis: currentData.x_axis,
                cumulativeData: currentData.users_with_notifications,
                cumulativeName: t('general:Users', 'Users'),
              }
            ]}
            monthly={statsMonthly}
            pieData={currentData.notifications_types ? currentData.notifications_types: []}
            />

          <StatsCard title={t('general:Reports', 'Reports')}
            cardData={[
              {
                chart_data: [
                  {data: currentData.reports_created, label: t('general:reportsCreated', 'Reports created'), color: theme.colors.frigel.neutral.chart1},
                  {data: currentData.reports_total?.map((item, index) => { return item -  currentData.reports_created[index] }), label:  t('general:reportsNotCreated', 'Reports not created'), color: theme.colors.frigel.neutral.chart2},
                ],
                x_axis: currentData.x_axis,
                cumulativeData: currentData.reports_total,
                cumulativeName: t('general:ReportsTotal', 'ReportsTotal'),
                cumulativeDataAlt: currentData.reports_machines_count,
                cumulativeNameAlt: t('general:Machines', 'Machines'),
              },
              {
                chart_data: [
                  {data: currentData.reports_customers, label:  t('general:Customers', 'Customers'), color: theme.colors.frigel.neutral.chart1},
                  {data: currentData.reports_users?.map((item, index) => { return item -  currentData.reports_customers[index] }), label: t('general:frigelUsers', 'Frigel users'), color: theme.colors.frigel.neutral.chart2},
                ],
                x_axis: currentData.x_axis,
                cumulativeData: currentData.reports_users,
                cumulativeName: t('general:Users', 'Users'),
              }
            ]}
            monthly={statsMonthly}
            />
          <StatsCard title={'Top Users'}
            top_users={currentData.top_users}
            monthly={statsMonthly}
            />
          </VStack>
      </MainLayout>
    </>
  );
};

export default Stats;
