import { extendTheme, theme as defaultTheme } from "@chakra-ui/react"

export function mode(light, dark) {
  return (props) => (props.colorMode === "light" ? light : dark)
}

const styles = {
  global: (props) => ({
    "html, body": {
      fontFamily: "body",
      color: mode("frigel.neutral.darkGray", "frigel.neutral.white")(props),
      bg: mode('frigel.neutral.white', 'frigel.dark.black')(props),
      lineHeight: "base",
      fontFeatureSettings: `"pnum"`,
      fontVariantNumeric: "proportional-nums",
    },
    // color: mode("frigel.neutral.darkGray", "whiteAlpha.900")(props),
    // bg: mode('white', 'frigel.dark.black')(props),
    // lineHeight: "base",
    "*::placeholder": {
      color: mode("gray.400", "whiteAlpha.400")(props),
    },
    "*, *::before, &::after": {
      borderColor: mode("gray.200", "whiteAlpha.300")(props),
      wordWrap: "break-word",
    },
    // fontFeatureSettings: `"pnum"`,
    // fontVariantNumeric: "proportional-nums",
  })
}

export const primaryBtnStyle =  ({ colorMode }) => ({
  bg: mode('frigel.light.blue', 'frigel.blueAccent')({ colorMode }),
  color: 'white',
  _hover: {
    _disabled:{
      cursor: 'not-allowed',
      boxShadow: 'none',
      color: 'frigel.blueAccent',
      bg: mode('frigel.light.blue', 'frigel.dark.blue')({ colorMode }),
      opacity: 1,},
    bg: mode('frigel.blueAccent', 'frigel.blueAccentLight')({ colorMode }),
    color: 'white'
  },
  _checked: {
    bg: mode('frigel.blueAccent', 'frigel.blueAccentLight')({ colorMode }),
    color: 'green'
  },
  _expanded: {
    bg: mode('frigel.blueAccent', 'frigel.blueAccentLight')({ colorMode }),
    color: 'white'
  },
  _disabled: {
    cursor: 'not-allowed',
    boxShadow: 'none',
    color: 'frigel.blueAccent',
    bg: mode('frigel.light.blue', 'frigel.dark.blue')({ colorMode }),
    opacity: 1,
    _hover: {
      bg: mode('frigel.light.blue', 'frigel.dark.blue')({ colorMode }),
    }
  },
})


export const secondaryBtnStyle = ({ colorMode }) => ({
  bg: mode('frigel.neutral.gray3', 'frigel.dark.blue1')({ colorMode }),
  color: mode('frigel.neutral.darkGray', 'white')({ colorMode }),
  _hover: {
    _disabled:{
      cursor: 'not-allowed',
      boxShadow: 'none',
      color: mode('frigel.neutral.gray2', 'frigel.blueAccent')({ colorMode }),
      bg: mode('frigel.neutral.gray3', 'frigel.dark.blue1')({ colorMode }),
      opacity: 1,
      },
    bg: mode('frigel.neutral.darkGray', 'frigel.blueAccent')({ colorMode }),
    color: 'white'
  },
  _checked: {
    bg: mode('frigel.neutral.darkGray', 'frigel.blueAccent')({ colorMode }),
    color: 'white'
  },
  _expanded: {
    bg: mode('frigel.neutral.darkGray', 'frigel.blueAccent')({ colorMode }),
    color: 'white'
  },
  _disabled: {
    cursor: 'not-allowed',
    boxShadow: 'none',
    color: mode('frigel.neutral.gray2', 'frigel.blueAccent')({ colorMode }),
    bg: mode('frigel.neutral.gray3', 'frigel.dark.blue1')({ colorMode }),
    opacity: 1,
    _hover: {
      bg: mode('frigel.neutral.gray3', 'frigel.dark.blue1')({ colorMode }),
    }
  },
})

export const accentBtnStyle = ({ colorMode }) => ({
  bg: mode('frigel.blueAccent', 'frigel.blueAccentLight')({ colorMode }),
  color: 'frigel.neutral.white',
  _hover: {
    bg: 'frigel.blueAccentLight',
    color: 'frigel.neutral.white',
    _disabled: {
      bg: mode('frigel.blueAccent', 'frigel.blueAccentLight')({ colorMode })
    }
  },
  _checked: {
    bg: 'frigel.blueAccentLight',
    color: 'frigel.neutral.white'
  },
  _expanded: {
    bg: 'frigel.blueAccentLight',
    color: 'frigel.neutral.white'
  },
  _disabled: {
    cursor: 'not-allowed',
    boxShadow: 'none',
    color: 'frigel.neutral.white',
    bg: mode('frigel.blueAccent', 'frigel.blueAccentLight')({ colorMode }),
    opacity: 0.5,
  },
})
export const deleteBtnStyle =  ({ colorMode }) => ({
  bg: mode('frigel.red', 'frigel.general.red')({ colorMode }),
  color: 'white',
  _hover: {
    _disabled:{
      cursor: 'not-allowed',
      boxShadow: 'none',
      color: 'frigel.general.red',
      bg: mode('frigel.red', 'frigel.dark.red')({ colorMode }),
      opacity: 1,},
    bg: mode('frigel.general.red', 'frigel.red')({ colorMode }),
    color: 'white'
  },
  _checked: {
    bg: mode('frigel.general.red', 'frigel.red')({ colorMode }),
    color: 'green'
  },
  _expanded: {
    bg: mode('frigel.general.red', 'frigel.red')({ colorMode }),
    color: 'white'
  },
  _disabled: {
    cursor: 'not-allowed',
    boxShadow: 'none',
    color: 'frigel.general.red',
    bg: mode('frigel.red', 'frigel.dark.red')({ colorMode }),
    opacity: 1,
    _hover: {
      bg: mode('frigel.red', 'frigel.dark.red')({ colorMode }),
    }
  },
})


export const badgeStyle = ({ colorMode }) => ({
  bg: mode('frigel.neutral.darkGray', 'frigel.blueAccent')({ colorMode }),
  color: 'white',
  borderRadius: 'lg',
  _groupHover: {
    bg: mode('frigel.neutral.gray3', 'frigel.blueAccentLight')({ colorMode }),
    color: mode('frigel.neutral.darkGray', 'white')({ colorMode })
  },
  _hover: {
    bg: mode('frigel.neutral.gray3', 'frigel.blueAccentLight')({ colorMode }),
    color: mode('frigel.neutral.darkGray', 'white')({ colorMode })
  }
})


const Button = {
  ...defaultTheme.components.Button,

   sizes: {
  },
  variants: {
    ...defaultTheme.components.Button.variants,
    primary: primaryBtnStyle,
    secondary: secondaryBtnStyle,
    delete: deleteBtnStyle,
    outline:({ colorMode }) => ({
      bg: 'transparent',
      border: '2px',
      borderColor: mode('frigel.neutral.gray3', 'frigel.dark.blue1')({ colorMode }),
      color: mode('frigel.neutral.darkGray', 'white')({ colorMode }),
      _hover: {
        bg: 'transparent',
        color: 'frigel.blueAccent',
        borderColor: 'frigel.blueAccent'
      },
      _checked: {
        bg: 'transparent',
        color: 'frigel.blueAccent',
        borderColor: 'frigel.blueAccent'
      },
      _expanded: {
        bg: 'transparent',
        color: 'frigel.blueAccent',
        borderColor: 'frigel.blueAccent'
      },
      _focus: {
        bg: 'transparent',
        color: 'frigel.blueAccent',
        borderColor: 'frigel.blueAccent'
      }
    }),
    accent: accentBtnStyle
  },

  defaultProps: {
    size: "sm",
    variant: "ghost",
  },
}

const theme = extendTheme({
  components: {
    Button,
  },
  styles,
  colors: {
    frigelSwitch: {
      200: '#5380C5',
      500: '#23325F',
    },
    frigel: {
      neutral: {
        gray0: '#FFFFFF',
        gray1: '#8A8A8A',
        gray2: '#AEAEAE',
        gray3: '#CECECE',
        darkGray: '#4F4F4F',
        white: '#FCFCFC',
        chart1: '#5588D7',
        chart2: '#5ACCCE',
      },
      general: {
        yellow:'#FFD11A',
        red:'#FF3B30',
        green:'#38D263',
        orange:'#FA9042'
      },
      blueAccentLight: '#66A2FF',
      blueAccentLight50: '#66A2FF26',
      blueAccent: '#5380C5',
      200: '#1E2940', // primary dark (not sure if it's the correct color)
      300: '#66A2FF', // hover dark
      500: '#23325F',
      600: '#5380C5',
      800: '#23325F',
      900: '#000F3E',
      light: {
        blue: '#23325F',
        darkblue: '#000F3E',
        ultraLightGray: '#E9E9E9',
        stats: '#FFFFFF',
        status: {
          alarm: '#FF3B30',
          alarm50: '#ff3b3026',
          warning: '#FFD11A',
          warning50: '#ffd11a26',
          online: '#38D263',
          on: '#38D263',
          heating: '#FA9042',
          cooling: '#4BB0DF' ,
          off: '#4F4F4F',
          offline: '#ACC2CF',
          offline50: '#ACC2CF26',
          draining: '#5ACCCE',
          loading: '#5588D7',
          standby: '#AEAEAE'
        }
      },
      dark: {
        blue: '#23325F',
        darkblue: '#000F3E',
        ultraLightGray: '#1E2940',
        stats: '#1E2940',
        blue1: '#3B4E72',
        blue2: '#1E2940',
        black: '#071626',
        status: {
          alarm: '#FF3B30',
          warning: '#FFD11A',
          online: '#38D263',
          on: '#38D263',
          heating: '#FA9042',
          cooling: '#4BB0DF' ,
          off: '#4F4F4F',
          offline: '#ACC2CF',
          draining: '#5ACCCE',
          loading: '#5588D7',
          standby: '#AEAEAE'
        }
      }
    }
  }
})
export default theme;

export const reactSelectDarkColors = ({
  /*
   * multiValue(remove)/color:hover
   */
   danger: 'var(--danger)',

  /*
   * multiValue(remove)/backgroundColor(focused)
   * multiValue(remove)/backgroundColor:hover
   */
   dangerLight: 'var(--danger-light)',

  /*
   * control/backgroundColor
   * menu/backgroundColor
   * option/color(selected)
   */
   neutral0: '#2D3748',

  /*
    * control/backgroundColor(disabled)
   */
   neutral5: 'var(--neutral-5)',

  /*
   * control/borderColor(disabled)
   * multiValue/backgroundColor
   * indicators(separator)/backgroundColor(disabled)
   */
   neutral10: 'var(--neutral-10)',

  /*
   * control/borderColor
   * option/color(disabled)
   * indicators/color
   * indicators(separator)/backgroundColor
   * indicators(loading)/color
   */
   neutral20: '#ffffff29',

  /*
   * control/borderColor(focused)
   * control/borderColor:hover
   */
   neutral30: 'var(--neutral-30)',

  /*
   * menu(notice)/color
   * singleValue/color(disabled)
   * indicators/color:hover
   */
   neutral40: 'var(--neutral-40)',

  /*
   * placeholder/color
   */
   neutral50: 'var(--neutral-50)',

  /*
   * indicators/color(focused)
   * indicators(loading)/color(focused)
   */
   neutral60: 'var(--neutral-60)',

   neutral70: 'var(--neutral-70)',

  /*
   * input/color
   * multiValue(label)/color
    * singleValue/color
   * indicators/color(focused)
   * indicators/color:hover(focused)
   */
   neutral80: 'var(--neutral-80)',

   neutral90: 'var(--neutral-90)',

  /*
   * control/boxShadow(focused)
   * control/borderColor(focused)
   * control/borderColor:hover(focused)
   * option/backgroundColor(selected)
   * option/backgroundColor:active(selected)
   */
   primary: '#5380C5',

  /*
   * option/backgroundColor(focused)
   */
   primary25: '#5380C5',

  /*
   * option/backgroundColor:active
   */
   primary50: '#5380C5',

   primary75: 'var(--primary-75)',
 })