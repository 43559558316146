export const MINDS_URL = '/api/get_user_related_minds/'
export const MIND_TOKEN_URL = '/api/get_token/'
export const USERS_URL = '/api/get_users_info/'
export const MIND_LATEST_RELEASE_URL = '/api/get_latest_mind_release/'

export const MANAGER_URL = '/manage';
export const MANAGER_USERS_URL = `${MANAGER_URL}/users`
export const MANAGER_USER_URL = `${MANAGER_USERS_URL}/:id`
export const MANAGER_NOTIFICATIONS_URL = `${MANAGER_URL}/notifications/:mindId?/:userId?`;
export const MANAGER_VPNS_URL = `${MANAGER_URL}/vpn`;
export const MANAGER_REPORTS_URL = `${MANAGER_URL}/reports/:mindId?/:userId?`;
export const MANAGER_UPDATES_URL = `${MANAGER_URL}/update`;

export const UNAUTHORIZED_URL = '/unauthorized'