import React, { useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import { ArrowLoop } from "../../shared/icons";
import { MainLayout } from "../../layouts";
import { MachinesTable, MindsTable, UsersTable } from "./elements";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ACCORDION_NOTIF_REPO_INDEX, ADMIN, MANAGER } from "../../consts";
import { useUser } from "../../contexts/auth";
import { useUsers } from "../../contexts/users";
import { startCase } from "lodash";
import { Button } from '@/shared/components/core/Button'
import FullPageSpinner from '@/shared/components/FullPageSpinner'

const Reports = () => {
  const [selectedMind, setSelectedMind] = useState();
  const [simpleMode, setSimpleMode] = useState(true);
  const user = useUser();
  const [selectedUser, setSelectedUser] = useState();
  const {isFetching: isFetchingUsers } = useUsers()
  const [index, setIndex] = useState(ACCORDION_NOTIF_REPO_INDEX.minds);
  const history = useHistory();
  const {t} = useTranslation(['general','reports'])

  useEffect(() => {
    setSelectedUser(user)
  }, [])

  return (
    <MainLayout heading={t('general:Reports','Reports')}>
      <Flex justifyContent="flex-end">
        <Button
          alignSelf="flex-end"
          size="xs"
          variant='secondary'
          leftIcon={<ArrowLoop fontSize="lg" mr={-1} />}
          onClick={() => {
            const nextSimpleMode =  !simpleMode
            setSimpleMode(nextSimpleMode)

            setSelectedUser(undefined)
            setSelectedMind()
            setIndex(ACCORDION_NOTIF_REPO_INDEX.minds);
            history.replace(`/manage/reports`)
          }}
        >
        {startCase(t('general:switch-to', 'switch to'))} {simpleMode
        ? startCase(t('notifications:advance-mode', 'advance mode'))
        : startCase(t('notifications:simple-mode', 'simple mode'))}
        </Button>
      </Flex>
      <Accordion index={index}>
        <AccordionItem border='none'>
          <Flex>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {selectedMind ? (
                  <Box alignSelf="center">
                    <b>MiND</b>
                    {` - [${selectedMind.serial}]  ${selectedMind.company_name}`}
                  </Box>
                ) : (
                  <b>MiNDs</b>
                )}
              </Box>
            </AccordionButton>
            {selectedMind && (
              <CloseButton
                alignSelf="center"
                onClick={() => {
                  setIndex(ACCORDION_NOTIF_REPO_INDEX.minds);
                  setSelectedMind();
                  setSelectedUser();
                  history.push("/manage/reports");
                }}
              />
            )}
          </Flex>
          <AccordionPanel py={6}>
            <MindsTable
              onEditClick={(mind) => {
                setSelectedMind(mind);
                history.push(`/manage/reports/${mind.id}/${user.profile_id}`)
                // Not working on first access
                // if (simpleMode === true){
                //   history.push(`/manage/reports/${mind.id}/${user.profile_id}`)
                // } else {
                //   history.push(`/manage/reports/${mind.id}`)
                // }
                setIndex(ACCORDION_NOTIF_REPO_INDEX.users);
              }}
            />
          </AccordionPanel>
        </AccordionItem>
            <AccordionItem display={simpleMode === false ? 'block' : 'none'}>
              <Flex>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {selectedUser ? (
                      <Box>
                        <b>{t('general:user', 'user').toCapitalCase()}</b>
                        {` - [${selectedUser.email}]  ${selectedUser.fullname}`}
                      </Box>
                    ) : (
                      <b>{t('general:users', 'users').toCapitalCase()}</b> 
                    )}
                  </Box>
                </AccordionButton>
                {selectedUser && (
                  <CloseButton
                    alignSelf="center"
                    onClick={() => {
                      setIndex(ACCORDION_NOTIF_REPO_INDEX.users);
                      setSelectedUser();
                      history.push(`/manage/reports/${selectedMind.id}`);
                    }}
                  />
                )}
              </Flex>
              <AccordionPanel py={6}>
                {selectedMind && (
                  <UsersTable
                    simpleMode={simpleMode}
                    mind={selectedMind}
                    onEditClick={(user) => {
                      setSelectedUser(user)
                      setIndex(ACCORDION_NOTIF_REPO_INDEX.machines);
                    }}
                  />
                )}
              </AccordionPanel>
            </AccordionItem>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <b>{t('general:machines', "machines").toCapitalCase()}</b> {(index === ACCORDION_NOTIF_REPO_INDEX.users && isFetchingUsers) && <FullPageSpinner/> }
            </Box>
          </AccordionButton>
          <AccordionPanel py={6}>
          {(simpleMode || (selectedUser !== undefined))  &&
            <MachinesTable 
              userName={user.fullname}
              canEdit={selectedUser && (user.isManager || user.isAdmin) && (selectedUser.id === user.id || selectedUser.level.id < user.level.id) }/>
          }
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </MainLayout>
  );
};

export default Reports;