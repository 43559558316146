import React from 'react';
import AuthenticatedApp from './authenticated-app';
import UnauthenticatedApp from './unauthenticated-app';
import './i18n';
import { useAuth } from './contexts/auth';

const App = (props) => {
  const { logout, user, fetchUser } = useAuth();

  // React.useEffect(() => {
  //   loadAuthenticatedApp()
  // }, [])

  return user ? (
    <AuthenticatedApp user={user} logout={logout} fetchUser={fetchUser} />
  ) : (
    <UnauthenticatedApp />
  );
};

export default App;
