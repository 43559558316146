import React, { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import Navbar from './components/Navbar'
import Minds from './pages/Minds'
import Mind from './pages/Mind'
import User from './pages/User'
import Stats from './pages/Stats'
import ReportFiles from './pages/ReportFiles/ReportFiles'
import ManagerRoutes from './pages/ManagerRoutes'
import Machines from './pages/Minds/Machines'
import Systems from './pages/Minds/Systems'


const Unauthorized = (props) => {

  const history = useHistory()
  useEffect(() => {
    history.push('/')
  }, [])

  return (
    <div>Not found</div>
  )
}

const NotFound = (props) => {

  return (
    <div> 404 | Page not found</div>
  )
}

const AuthenticatedApp = ({ user, logout, fetchUser, ...rest }) => {

  const authProps = { user, logout, fetchUser }
  return (
    <>
      <Navbar {...authProps} />
      {/* <Navbar {...authProps} {...rest}/> */}
      {/* <Route exact path={UNAUTHORIZED_URL} component={Unauthorized} />
      <Route path='/register' render={(props) =>  <Register {...props} {...authProps} /> } />
      <Route path="/" render={(props) => <MainStructure {...props} {...authProps}/>} /> */}
      <Switch>
        <Route exact path='/minds/:id' render={(props) => <Mind {...props} {...authProps} />} />
        <Route path="/user" render={(props) => <User {...props} {...authProps} />} />
        <Route path="/unauthorized" render={(props) => <Unauthorized />} />
        <Route path="/manage/:id" render={(props) => <ManagerRoutes {...props} {...authProps} />} />
        <Route path="/reportfiles" render={(props) => <ReportFiles {...props} {...authProps} />} />
        <Route path="/stats" render={(props) => <Stats {...props} {...authProps} />} />
        <Route exact path="/" render={(props) => <Minds {...props} {...authProps} />} />
        <Route path="/machines" render={props => <Machines {...props} {...authProps} />} />
        <Route path="/systems" render={props => <Systems {...props} {...authProps} />} />
        <Route path="*" component={NotFound} />
      </Switch>

    </>
  )
}

export default AuthenticatedApp;