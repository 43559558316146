import React from 'react'
import {
   Modal, ModalOverlay, ModalContent,ModalHeader, useColorMode,
   ModalCloseButton
} from '@chakra-ui/react'

const ModalSkeleton = ({isOpen, onClose, title, children, ...rest}) => {
const {colorMode} = useColorMode()
return (
  <Modal isOpen={isOpen} onClose={onClose} {...rest}>
    <ModalOverlay>
      <ModalContent>
        <ModalHeader color='white' bgColor={`frigel.${colorMode}.blue`}
          borderTopRadius='md'
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color='white'/>
          {children}
      </ModalContent>
    </ModalOverlay>
  </Modal>
  )
}

export default ModalSkeleton;