import React, { useEffect, useState } from "react";
import axios from 'axios'
import {
  Box,
  Heading,
  Text,
  Table, Tbody, Tr, Td,
  CircularProgress,
  CircularProgressLabel,
  useColorMode,
  Divider,
  Center,
  useDisclosure,
  useToast,
  Modal, ModalBody, ModalOverlay, ModalCloseButton, ModalContent, ModalHeader,
  IconButton,
  Tooltip
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import { Button } from '@/shared/components/core/Button'
import { WarningIcon, CheckedIcon, TimeIcon, DownloadIcon, TrashIcon, StopIcon } from "../../shared/icons";
import { useTranslation } from 'react-i18next';
import { TOAST_POSITION, STANDARD_ERROR_MSG, MANUFACTURER } from "../../consts";
import { getNotifiedWhenUpdateFinished } from "../../components/UpdateMind";
import FileSaver from 'file-saver';


const refetchLog = (mind, setReadLog) => {
  if (mind?.id) {
    setTimeout(() => {
      return axios.get('/api/read_update_mind_cache/', { params: { 'id': mind.id, 'serial': mind.serial, 'id_log': mind.id_log } })
        .then(({ data }) => {
          setReadLog(data)
        })
        .catch(error => {
          return Promise.reject(error)
        });
    }, 1000);
  }
}


export const RemoveNotificationUpdate = (toast, t, serial) => {
  return axios.get('/api/remove_notify_update/', { params: { 'serial': serial } })
    .then(({ data }) => {
      if (data.error) {
        switch (data.error) {
          case 'update_finished':
            toast({
              status: 'success',
              description: t('errors:Update_finished', 'Update has already been completed'),
              position: TOAST_POSITION
            })
            break;
          case 'not_subscribed':
            toast({
              status: 'success',
              description: t('errors:Notify_userNotSubscribed', 'Not subscribed to the notification'),
              position: TOAST_POSITION
            })
            break;
        }
      } else {
        toast({
          status: 'success',
          description: t('general:RemoveNotifyUpdate', 'You have unsubscribed from the notification'),
          position: TOAST_POSITION
        })
      }
    })
    .catch((error) => {
      toast({
        description: t(
          'errors:CommandNotSent',
          'Command has not been sent'
        ),
        title: "Action is not permitted",
        status: 'error',
        position: TOAST_POSITION
      })
    })
}



export const MindUpdatingSection = (props) => {
  const [readLog, setReadLog] = useState()
  const [mindShowLog, setMindShowLog] = useState()
  const [userRequest, setUserRequest] = useState()
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();
  const { t } = useTranslation(['general', 'errors', 'minds'])
  const toast = useToast()

  useEffect(() => {
    refetchLog(mindShowLog, setReadLog)
  }, [readLog])

  const showLog = (mind) => {
    setUserRequest(mind.user)
    return axios.get('/api/read_update_mind_cache/', { params: { 'id': mind.id, 'serial': mind.serial, 'id_log': mind.id_log } })
      .then(({ data }) => {
        setReadLog(data)
        onOpen()
      })
      .catch(error => {
        return Promise.reject(error)
      });
  }

  return (
    <>
      <Box align='center' borderWidth={mindShowLog && mindShowLog?.length > 0 ? '2px' : '1px'} borderColor={mindShowLog && mindShowLog?.length > 0 ? 'frigel.general.green' : ''} borderRadius='md' overflowY='scroll'>
        {/* background= "radial-gradient(at center, #fcfcfc, #b5d7bb)" */}
        <Heading as='h3' size='lg' alignContent='center' mb={4} > {t('general:UpdateInProgress', 'Updates in progress')}</Heading>
        <Table size='sm'>
          <Tbody>
            {props.data && props.data?.map((item) => {
              return (

                <Tr key={item.serial}>
                  <Td>{<CircularProgress isIndeterminate color='green.300' size='25px' />}</Td>
                  <Td >{item.serial}</Td>
                  <Td>{`${item.sw_version.charAt(0) === 'v' ? item.sw_version.substr(1) : item.sw_version} --> ${props.mindLatestVersion}`}</Td>
                  <Td> <Button
                    cursor='pointer'
                    size='xs'
                    padding='10px'
                    variant='secondary'
                    onClick={() => {
                      setMindShowLog(item)
                      showLog(item)
                    }}
                  >{'show log'}
                  </Button>
                  </Td>
                  <Td>
                    {!item.user_notif.includes(props.user.id) ?
                      <Button
                        justifyContent='center' alignItems='center'
                        variant='secondary'
                        px={5}
                        size='xs'
                        onClick={() => { getNotifiedWhenUpdateFinished(toast, t, item.serial) }}
                        left={4}
                      >
                        {t('general:Notify_me', 'Notify me')}
                      </Button>
                      :
                      <Button
                        justifyContent='center' alignItems='center'
                        variant='secondary'
                        px={5}
                        size='xs'
                        onClick={() => { RemoveNotificationUpdate(toast, t, item.serial) }}
                        left={4}
                      >
                        {t('general:UnsubscribeNotification', 'Unsubscribe notification')}
                      </Button>}
                  </Td>
                </Tr>
              )
            })}
            {props.data?.length == 0 &&
              <>
                <Tr><Td></Td></Tr>
                <Tr><Td><Center>{t('general:NoData', "No data to display")}</Center></Td></Tr>
              </>}
          </Tbody>
        </Table>
      </Box>
      <Modal isCentered
        isOpen={isOpen}
        size="5xl"
        rounded='lg'
        scrollBehavior='inside'
        onClose={() => {
          onClose()
        }}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader textColor='frigel.neutral.gray0' bgColor={`frigel.${colorMode}.blue`} borderTopRadius='md'>
              {(readLog?.update_log?.in_progress ? t('general:Updating_mind', 'Updating MiND') : t('general:Update_lastlog', "Last update log")) + `: ${mindShowLog?.serial}`}
              {' user requesting' + `: ${userRequest}`}
            </ModalHeader>
            <ModalCloseButton textColor='frigel.neutral.gray0' />
            <ModalBody>
              {readLog?.update_log?.length > 0 ? readLog?.update_log.map((o, idx) => <Text key={idx} color={o.includes('fatal') || o.includes('MiND update: failed') ? 'red.500' :
                o.includes('MiND update: succefully') ? 'green.500' : 'black.500'}>{o}</Text>) : t('general:No_log', 'No log data available')}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>

  )
}


export const QueueMinds = (props) => {
  const toast = useToast();
  const { t } = useTranslation(['general', 'errors', 'minds'])

  const removeMind = (mind) => {
    return axios.post('/api/update/delete/', {
      mind: +mind.id
    })
      .then(({ data }) => {
        toast({
          status: 'success',
          description: t('general:RemoveMindQueueUpdate', 'MiND removed from update queue'),
          position: TOAST_POSITION
        })
        props.refetch()
      })
      .catch(error => {
        toast({
          status: 'error',
          description: "Action is not permitted",
          position: TOAST_POSITION
        })
        return Promise.reject(error)
      });

  }
  return (

    <Box align='center'>
      <Heading as='h3' size='lg' alignContent='center' mb={4} > {t('general:UpdateQueue', 'Update queue')} </Heading>
      <Box overflowY='scroll' h='175px'>
        <Table size='sm' >
          <Tbody>
            {props.data?.length > 0 && props.data.map((item) => {
              return (
                <Tr key={item.serial}>
                  <Td>{<TimeIcon />}</Td>
                  <Td>{item.serial}</Td>
                  <Td>{item.sw_version.charAt(0) === 'v' ? item.sw_version.substr(1) : item.sw_version}</Td>
                  <Td>
                    {item.status === 'offline' ?
                      <Tooltip placement='top' label={t('general:MindDisconnectedMessage', 'At this moment the Mind is disconnected')}>
                        <WarningIcon color={'frigel.light.status.alarm'} />
                      </Tooltip> : ''}
                  </Td>
                  <Td>{item.user_id === props.user.profile_id || props.user.level.id >= MANUFACTURER ? <TrashIcon
                    cursor='pointer'
                    fontSize={{ base: "xs", md: "md" }}
                    onClick={() => {
                      removeMind(item)
                    }}
                  /> : ''} </Td>
                  <Td>{!item.user_notif.includes(props.user.id) ? <Button
                    justifyContent='center' alignItems='center'
                    variant='secondary'
                    px={5}
                    size='xs'
                    onClick={() => { getNotifiedWhenUpdateFinished(toast, t, item.serial) }}
                    left={4}
                  >
                    {t('general:Notify_me', 'Notify me')}
                  </Button> : <Button
                    justifyContent='center' alignItems='center'
                    variant='secondary'
                    px={5}
                    size='xs'
                    onClick={() => { RemoveNotificationUpdate(toast, t, item.serial) }}
                    left={4}
                  >
                    {t('general:UnsubscribeNotification', 'Unsubscribe notification')}
                  </Button>}
                  </Td>
                </Tr>
              )
            })}
            {props.data?.length == 0 &&
              <>
                <Tr><Td></Td></Tr>
                <Tr><Td><Center>{t('general:NoData', "No data to display")}</Center></Td></Tr>
              </>
            }
          </Tbody>
        </Table>
      </Box>
    </Box>
  )
}


export const LastUpdateLogSection = (props) => {
  const [readLog, setReadLog] = useState()
  const [mindShowLog, setMindShowLog] = useState()
  const [userRequest, setUserRequest] = useState()
  const { isOpen: isOpenUpdating, onOpen: onOpenUpdating, onClose: onCloseUpdating } = useDisclosure();

  const { colorMode } = useColorMode();
  const { t } = useTranslation(['general', 'errors', 'minds'])

  useEffect(() => {
    refetchLog(mindShowLog, setReadLog)
  }, [readLog])

  const showLog = (mind) => {
    setUserRequest(mind?.user)
    return axios.get('/api/read_update_mind_cache/', { params: { 'id': mind.id, 'serial': mind.serial, 'id_log': mind.id_log } })
      .then(({ data }) => {
        setReadLog(data)
        onOpenUpdating()
      })
      .catch(error => {
        return Promise.reject(error)
      });

  }
  const failed_update = props.data?.filter(function (mind) { return !mind.successful })

  return (
    <>
      <Box align='center'>
        <Heading as='h3' size='lg' alignContent='center' mb={4}>{t('general:UpdateHistory', 'Update history')}</Heading>
        <Text> {`in the last 24 hours, ${failed_update ? failed_update.length : 0} Minds failed to update`}</Text>
        <Box overflowY='scroll' h='150px'>
          <Table size='sm' >
            <Tbody align={props.data ? 'center' : ''}>
              {props.data && props.data?.map((item) => {
                return (

                  <Tr>
                    <Td>{item.successful ? <CheckedIcon /> : <WarningIcon color={'frigel.light.status.alarm'} />}</Td>
                    <Td color={!item.successful ? 'red' : ''}>{item.serial}</Td>
                    <Td>{item.finished_at}</Td>
                    <Td> <Button
                      cursor='pointer'
                      size='xs'
                      padding='10px'
                      variant='secondary'
                      onClick={() => {
                        setMindShowLog(item)
                        showLog(item)
                      }}
                    >{'show log'}
                    </Button></Td>
                  </Tr>
                )
              })}
              {props.data?.length == 0 &&
                <>
                  <Tr><Td></Td></Tr>
                  <Tr><Td><Center>{t('general:NoData', "No data to display")}</Center></Td></Tr>
                </>}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <Modal isCentered
        isOpen={isOpenUpdating}
        size="5xl"
        rounded='lg'
        scrollBehavior='inside'
        onClose={() => {
          onCloseUpdating()
        }}
      >
        <ModalOverlay>
          <ModalContent>
            <ModalHeader textColor='frigel.neutral.gray0' bgColor={`frigel.${colorMode}.blue`} borderTopRadius='md'>
              {(readLog?.update_log?.in_progress ? t('general:Updating_mind', 'Updating MiND') : t('general:Update_lastlog', "Last update log")) + `: ${mindShowLog?.serial}`}
              {' user requesting' + `: ${userRequest}`}
            </ModalHeader>
            <ModalCloseButton textColor='frigel.neutral.gray0' />
            <ModalBody>
              {readLog?.update_log?.length > 0 ? readLog?.update_log.map((o, idx) => <Text key={idx} color={o.includes('fatal') || o.includes('MiND update: failed') ? 'red.500' :
                o.includes('MiND update: succefully') ? 'green.500' : 'black.500'}>{o}</Text>) : t('general:No_log', 'No log data available')}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}


export const LastReleaseSection = (props) => {
  const toast = useToast();
  const [progressing, setProgressing] = useState(0);
  const [controller, setController] = useState();
  const { t } = useTranslation(['general'])

  return (
    <Box>
      <Heading as='h3' size='lg' align='center' mb={4}>{`Latest release: ${props.mindLatestVersion}  `}
        {progressing > 0 &&
          <CircularProgress value={progressing} size="40px" color="green.500" >
            <CircularProgressLabel>
              <Button cursor='pointer'
                size='xs'
                padding='10px'
                onClick={() => controller.abort()}>
                <Tooltip label='click to cancel download'><StopIcon /></Tooltip>
              </Button>
            </CircularProgressLabel>

          </CircularProgress>}
        {progressing == 0 &&
          <IconButton icon={<DownloadIcon />}
            variant='secondary'
            onClick={() => {
              const newController = new AbortController();
              setController(newController);
              axios.get('/api/download_archive/', {
                params: { 'version': props.mindLatestVersion },
                responseType: 'arraybuffer',
                signal: newController.signal,
                headers: {
                  'Content-Type': 'application/tar+gzip'
                },
                onDownloadProgress: (progressEvent) => {
                  let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setProgressing(percentCompleted);
                }
              })
                .then((resp) => {
                  FileSaver.saveAs(new Blob([resp.data], { type: 'application/octet-stream' }), `ECSMIND-${props.mindLatestVersion}.tgz`, { autoBOM: false });
                  setProgressing(0);
                })
                .catch((reason) => {
                  if (reason.message == 'canceled') {
                    toast({
                      status: 'info',
                      description: t('general:DownloadCancelled', 'Download cancelled'),
                      position: TOAST_POSITION
                    });
                  } else {
                    toast({
                      status: 'error',
                      description: STANDARD_ERROR_MSG,
                      position: TOAST_POSITION
                    });
                  }
                  setProgressing(0);
                })
            }}
          />
        }
      </Heading>
      <Text overflowY='scroll' overflowX='auto' h='150px'>
        {props.changelogs?.map((changelogObj, index) => {
          return (
            <div key={changelogObj.version}>
              <p>{changelogObj.version}</p>
              <ReactMarkdown children={changelogObj.changelog} className="changelog-markdown" />
              {index + 1 < props.changelogs.length && <Divider mb='6' hr='6' />}
            </div>
          )
        })}
      </Text>
    </Box>

  )
}