import i18n from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";

// How to use translations in React Component class
// import { Translation } from 'react-i18next';
// <Translation>
//   {
//     t => <h1>{t('TEST')}</h1>
//   }
// </Translation>

i18n
  .use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    saveMissing: true,
    // saveMissingTo: 'all',

    backend: {
      loadPath: process.env.NODE_ENV === "production" ? `https://frigel.fra1.cdn.digitaloceanspaces.com/hub/statics/locales/{{lng}}/{{ns}}.json` : `/statics/locales/{{lng}}/{{ns}}.json`,
      crossDomain: true
    },

    ns: [
      'translation',

      // every new namespace must be declared here!
      'general',
      'minds',
      'machines',
      'users',
      'notifications'
    ],

    defaultNS: 'translation',

    // Change lng to 'cimode' to have namespace:key on the interface
    // it is useful for screenshot purposes
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'it'],

    supportedLngs: ['en', 'it'],

    nsSeparator: ':',
    keySeparator: '.',

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    // This option appends namespace to the key in case of 'cimode'
    appendNamespaceToCIMode: true,
    react: {
      useSuspense: false,
      wait: true,
    },
    // Activate debug while developing new translation can save long time
    debug: false
  });

export default i18n;

/**
 * A wrapper function over i18n change language function
 *
 * @param {string} lang
 * @param {function} callback
 */
export const changeLanguage = (lang, callback = () => {}) => {
  if (i18n.language !== 'cimode') {
    i18n.changeLanguage(lang, callback)
  }
}