import React, { useState, useEffect } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  CloseButton,
  Flex,
} from "@chakra-ui/react";
import { MainLayout } from "../../layouts";
import { MachinesTable, ReportsTable } from "./elements";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useData } from "@/hooks";
import { useUser } from '../../contexts/auth';


const ACCORDION_INDEX ={
  machines: 0,
  reports: 1,
}

Object.freeze(ACCORDION_INDEX)

const ReportFiles = () => {
  const history = useHistory();
  const user = useUser()
  const {t} = useTranslation(['general', 'reports'])
  const [selectedMachine, setSelectedMachine] = useState();
  const [index, setIndex] = useState(ACCORDION_INDEX.machines);
  const { data ,isFetching, refetch } = useData({
    url: '/api/report_file/',
    })
  const { data:reportMachineData, refetch:reportMachineRefetch } = useData({
    url: `/api/get_report_machine/`,
  });

  useEffect(() => {
    const { pathname } = history.location;
    const machine_id = pathname.split('/')[2]
    if (machine_id) {
      setSelectedMachine({}); // to show the loader
      setIndex(ACCORDION_INDEX.reports);
    }
  }, [])
  
  useEffect(() => {
    if (isFetching === false){
      const timerId = setTimeout(() => refetch(), 50000)
      return () => clearTimeout(timerId)
    }
  }, [isFetching])

  useEffect(() => {
    if (index === ACCORDION_INDEX.reports && selectedMachine && Object.keys(selectedMachine).length === 0) {
      const machine_id = parseInt(history.location.pathname.split('/')[2]);
      let machine = undefined;machine
      reportMachineData.forEach(reportMachineDatum =>  {
        if (reportMachineDatum.id === machine_id) {
          machine = reportMachineDatum;
          return
        }
      }
      )
      setSelectedMachine(machine);
      if (!machine) {
        setIndex(ACCORDION_INDEX.machines);
        history.push(`/reportfiles`);
      }
    }
  }
  , [reportMachineData])

  return (
    <MainLayout heading={t('reports:report-files', 'Report files')}>
      <Accordion index={index}>
        <AccordionItem>
          <Flex>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {selectedMachine ? (
                  <Box>
                    <b>{t('general:machine', 'machine').toCapitalCase()}</b>
                    {` - [${selectedMachine.serial}]  ${selectedMachine.name}`}
                  </Box>
                ) : (
                  <b>{t('general:machines', "machines").toCapitalCase()}</b>
                )}
              </Box>
            </AccordionButton>
            {selectedMachine && (
              <CloseButton
                alignSelf="center"
                onClick={() => {
                  setIndex(ACCORDION_INDEX.machines);
                  setSelectedMachine();
                  history.push(`/reportfiles`);
                }}
              />
            )}
          </Flex>
          <AccordionPanel py={6}>
            <MachinesTable
              user = {user.profile_id}
              mind_related = {user.minds_related}
              data = {reportMachineData}
              refetch = {reportMachineRefetch}
              onEditClick={(machine) => {
                setSelectedMachine(machine);
                setIndex(ACCORDION_INDEX.reports);
                history.push(`/reportfiles/${machine.id}`)
                refetch()
              }}
            />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
                    <b>{t('general:Reports','Reports')}</b>
            </Box>
          </AccordionButton>
          <AccordionPanel py={6}>
            {selectedMachine && (
              <ReportsTable  
                reports = {data}
                machine = {selectedMachine.serial}
              />
              )} 
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </MainLayout>
  );
};

export default ReportFiles;