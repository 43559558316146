import React from 'react'
import { createIcon } from '@chakra-ui/react'

const ChevronDownIcon = createIcon({
  displayName: 'ChevronDownIcon',
  d: "M6 9.1L7.2 8l4.8 4.7L16.8 8 18 9.1 12 15 6 9.1z"
})

const ChevronLeftIcon = createIcon({
  displayName: 'ChevronLeftIcon',
  d: "M14.9 6L16 7.2 11.3 12l4.7 4.8-1.1 1.2L9 12l5.9-6z"
})

const ChevronUpIcon = createIcon({
  displayName: 'ChevronUpIcon',
  d: "M6 9.126L7.15 8 12 12.747 16.85 8 18 9.126 12 15 6 9.126z"
})

export { ChevronDownIcon, ChevronLeftIcon, ChevronUpIcon }
