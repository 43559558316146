import React, { useState } from "react";
import {
  Box,
  ModalBody,
  useDisclosure,
  FormLabel,
  Select,
  FormControl,
  useToast,
  ModalFooter,
} from "@chakra-ui/react";
import { EditIcon } from "@/shared/icons";
import ModalSkeleton from "@/shared/components/ModalSkeleton";
import { PERMISSIONS_LIST } from "@/consts";
import {Button} from '@/shared/components/core/Button'
import { USER, TOAST_POSITION, STANDARD_ERROR_MSG } from "@/consts";
import { useTranslation } from "react-i18next";

const EditPermissionModal = ({ permission, limitPermission, onSubmit }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const permissions = PERMISSIONS_LIST
    .filter(p => limitPermission.id >= p.id)
    .map(p => <option key={p.id} value={p.id}>{p.name}</option>)

  const [_permission, _setPermission] = useState(permission.id || USER)
  const toast = useToast()
  const {t} = useTranslation(['general', 'users'])
   
  return (
    <>
      <EditIcon cursor="pointer"  fontSize={{ base: "xs", md: "md" }} onClick={onOpen} />
      <ModalSkeleton
        isOpen={isOpen}
        onClose={onClose}
        title={t('users:change-permission', "Change Permission")}
      >
        <ModalBody my={2}>
          <Box as='form' id='change_permission' onSubmit={e => {
              e.preventDefault()
              onSubmit(_permission)
                .then(() => onClose())
                .catch(reason => {
                  toast({
                    status: 'error',
                    description: STANDARD_ERROR_MSG,
                    position: TOAST_POSITION,
                    isClosable: true
                  })
                  console.error(reason)
                })
            }}>
            <FormControl>
              <FormLabel>{t('general:permission', 'permission').toCapitalCase()}</FormLabel>
              <Select value={_permission} onChange={(e) => _setPermission(+e.target.value)}>
                {permissions}
              </Select>
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant = 'secondary' mr={2} >
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end" variant= 'primary' type="submit" form='change_permission' isDisabled={_permission == permission.id  ? true : false}>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};

export default EditPermissionModal;