export function getHiddenRows(selectedRows, hiddenRows, allRows, keyField='id'){
  if (hiddenRows.length > 0)
    return []

  return allRows
          .map(r => r[keyField])
          .filter(r => !selectedRows.includes(r));
}

import { useState, useEffect } from 'react';

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}