import React from 'react';

const InlineBar = ({rightSlot, leftSlot, leftSlotStyle={}, rightSlotStyle={}, barStyle={}}) =>
  (<div style={{ display: 'flex', justifyContent: 'space-between', ...barStyle }}>
    <div style={{
        display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline',
        ...leftSlotStyle
      }}>
      {leftSlot}
    </div>
    <div style={{
      display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline',
      ...rightSlotStyle
    }}>
    {rightSlot}
    </div>
  </div>);


export default InlineBar;
