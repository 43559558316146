import React from 'react'
import { useData } from "@/hooks";
import { MINDS_URL, MIND_LATEST_RELEASE_URL } from "@/api/urls";
import { useUser } from './auth';
const MindsContext = React.createContext()

function MindsProvider({children, ...props}) {
  const user = useUser() || { preferences: {} }
  const {preferences: { favoriteMinds=[], customizeMinds={} } } = user
  const {data, isFetching, isError, refetch} = useData({
    url: MINDS_URL,
    refetchInterval: 15000,
    skipFetchIfHidden: true
  })

  const minds_related = user && user.minds_related || [];
  const currentUserMinds = [];

  data.forEach(m => {
    if(minds_related.includes(m.id)){
        m.user_permission = user.perms[+m.id]
        currentUserMinds.push(m)
      }
    }
  )

  currentUserMinds.sort((a, b) => {
    if (favoriteMinds.includes(a.id) && favoriteMinds.includes(b.id)){
      return 0
    } else if (favoriteMinds.includes(a.id)){
      return -1
    } else if (favoriteMinds.includes(b.id)){
      return 1
    }
  })

  currentUserMinds.forEach(m => {
    const nickname = (customizeMinds[m.id] && customizeMinds[m.id].nickname) || ''
    m.nickname = nickname
    return m
  })

  return (<MindsContext.Provider value={{
    minds: data, isFetching, isError, refetch,
    currentUserMinds
    }} {...props}>
    {children}
  </MindsContext.Provider>
  )
}


function useMinds() {
  const context = React.useContext(MindsContext)
  if (context === undefined) {
    throw new Error(`useMinds must be used within a MindsProvider`)
  }
  return context
}

const LatestReleaseContext = React.createContext()

function LatestReleaseProvider({children, ...props}) {
  const {data, isFetching, isError, refetch} = useData({
    url: MIND_LATEST_RELEASE_URL
  })

  return (<LatestReleaseContext.Provider value={{
    changelogs: data?.changelogs, version: data?.version, isFetching, isError, refetch,
    }} {...props}>
    {children}
  </LatestReleaseContext.Provider>
  )
}

function useLatestRelease() {
  const context = React.useContext(LatestReleaseContext)
  if (context === undefined) {
    throw new Error(`useLatestRelease must be used within a MindsProvider`)
  }
  return context
}

export {MindsProvider, useMinds, LatestReleaseProvider, useLatestRelease}