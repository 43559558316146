import React from "react";
import { Route } from 'react-router-dom'

import Login from './pages/Login'

const UnauthenticatedApp = () => {
  return (
    <>
      <Route exact path='/token/:token' component={() => <div> token login</div>}/>
      <Route path="/login" component={Login} />
    </>
  )
}

export default UnauthenticatedApp;