import React, { useEffect, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  useColorMode,
  useDisclosure,
  DrawerFooter,
  CloseButton,
  Image,
  Link,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";

import { ChevronDownIcon, HamburgerIcon } from "../../icons";


export const NavLink = ({ children, to, color='white', bgHover='frigel.600', px=6, ...props }) => (
  <Link color={color} margin={0}
    fontSize={{base: 'xs', md: 'sm'}}
    _hover={{ bg: bgHover, color, textDecoration: 'none'}}
    _visited={{ color }}
    _active={{ color }}
    px={px}
    {...props}>
    {children}
  </Link>
);


export const MobileNavigation = ({
  widgets,
  imageProps,
  config,
  isSelected,
  selectedBg,
  onClick,
  currentPathname,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [selectedUrl, setSelectedUrl] = useState();
  const textColor = useColorModeValue("black", "white")
  useEffect(() => {
    if (!isOpen) {
      setSelectedUrl(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <IconButton
        display={{ base: "block", md: "none" }}
        _hover={{ bg: "frigel.600" }}
        variant="ghost"
        color="white"
        onClick={() => (isOpen ? onClose() : onOpen())}
        icon={<HamburgerIcon />}
      />
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom" size="full">
        <DrawerOverlay>
          <DrawerContent zIndex={1350} height='93vh'>
            <Flex
              justify="space-between"
              bg={`frigel.${colorMode}.blue`}
              w="100%"
              pl={4}
              pr={6}
              py={2}
              alignItems="center"
            >
              <Image {...imageProps} />
              <CloseButton onClick={onClose} color="white" />
            </Flex>

            <DrawerBody>
              <VStack justify="stretch">
                {config.map((el, i) => {
                  if (el.hidden) return;
                  let selected = isSelected(el);
                  if (selectedUrl) {
                    selected = selectedUrl === el.url && el.children;
                  }

                  return (
                    <div key={el.name + el.url + i}>
                      <NavLink
                        color={
                          selected
                            ? "white"
                            : textColor
                        }
                        p={5}
                        _hover={{
                          bg: selectedBg,
                          color: "white",
                        }}
                        _active={{
                          bg: selectedBg,
                          color: "white",
                        }}
                        bg={selected && selectedBg }
                        w="full"
                        borderRadius="md"
                        textAlign="center"
                        fontSize="lg"
                        onClick={(e) => {
                          if (!el.children) {
                            onClick(el.url);
                            onClose();
                          }
                          setSelectedUrl(el.url);
                        }}
                      >
                        {el.name}
                        {el.children && <ChevronDownIcon />}
                      </NavLink>
                      {el.url === selectedUrl &&
                        el.children &&
                        el.children.map((c, i) => {
                          const childSelected =
                            currentPathname.split("/")[2] &&
                            c.url.split("/")[2] ===
                              currentPathname.split("/")[2];

                          return (
                            <NavLink
                              color={
                                childSelected
                                  ? "white"
                                  : textColor
                              }
                              key={c.name + c.url + i}
                              py={2}
                              _hover={{
                                bg: selectedBg,
                                color: "white",
                              }}
                              bg={childSelected && selectedBg}
                              w="md"
                              borderRadius="md"
                              textAlign="center"
                              fontSize="md"
                              onClick={(e) => {
                                onClick(c.url);
                                onClose();
                              }}
                            >
                              {c.name}
                            </NavLink>
                          );
                        })}
                    </div>
                  );
                })}
              </VStack>
            </DrawerBody>
            <DrawerFooter bg={`frigel.${colorMode}.blue`}>
              <HStack>{widgets}</HStack>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};
