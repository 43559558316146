import React, { useEffect, useState } from 'react';
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import {EditLayout} from '@/layouts'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMinds } from '@/contexts/minds';
import { useUsers } from '@/contexts/users';
import { SectionWithTitle, StaticRow } from '@/components/elements';
import DataTable,{ getUniformedColumns, SearchBar} from '@/shared/components/DataTable';
import { Box, Center, Flex, Text, useToast } from '@chakra-ui/react';
import { AddModal, EditProfile, UnitsSection} from './elements';
import EditPermissionModal from '../../components/EditPermissionModal';
import ConfirmAlert from '../../components/ConfirmDeleteAlert';
import axios from 'axios';
import { TOAST_POSITION, MANAGER, LANGUAGES, PAGEVIEWS_OPTIONS } from '../../consts';
import { useAuth, useUser } from '../../contexts/auth';
import { useIsMobile, useIsTablet } from '../../hooks';
import { mindsTableCommonColumnProps } from '../../components/elements';
import { PlusIcon } from '../../shared/icons';
import { useTranslation } from 'react-i18next';
import { isTypedArray, startCase } from 'lodash';


const User = (props) => {
  const [user, setUser] = useState()
  const {minds} = useMinds()
  const {users, isFetching, isError,  refetch: refetchUsers} = useUsers()
  const {id} = useParams()
  const {pathname} = useLocation()
  const toast = useToast()
  const currentUser = useUser()
  const {fetchUser} = useAuth()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const {t} = useTranslation(['general', 'users'])

  useEffect(() => {
    if (isFetching === false && pathname !=='/user'){
      const result = users.filter(u => u.id == id)
      if (result.length > 0){
        setUser(result[0])
      }
    } else if (pathname === '/user'){
      setUser(currentUser)
    }

  }, [isFetching, pathname])


  if (!user){
    return <FullPageSpinner />
  }

  const isCurrentUserPage = pathname === '/user'
  const canViewMinds = currentUser.level.id >= MANAGER;

  const {
    username, fullname, email, level, company,
    frigel_branch, language, minds_related, preferences
  } = user;
  const lang = LANGUAGES.filter(l => l.value === language)[0] || {label: language, icon: ''}
  const MindsPageView = PAGEVIEWS_OPTIONS[preferences['pageView']] || {text: ''}

  const userMinds = []
  const notUserMinds = []
  minds.forEach(m => {
    if(minds_related.includes(m.id)){
      m.user_permission = user.perms[+m.id]
      userMinds.push(m)
    } else {
      notUserMinds.push(m)
      }
    }
  )

  let mindsTableCommon = mindsTableCommonColumnProps(isMobile, isTablet, t)
  delete mindsTableCommon['company_country.name']
  delete mindsTableCommon.app_version
  const columnsProps = {
    ...mindsTableCommon,
    'user_permission.name': { text: t('general:permission', 'permission').toCapitalCase()},
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => <EditPermissionModal permission={row.user_permission}
        limitPermission={level}
        onSubmit={(permission) => {
          return axios.post('/api/permissions/', {
            level: +permission,
            userprofile: +user.profile_id,
            mind: +row.id
          }).then(resp => {
            // TODO: Add some delay as refetchUsers is slow.
            toast({
              title: t('users:mind-permission-changed', 'MiND permission changed successfully!'),
              position: TOAST_POSITION
            })
            refetchUsers()
          })
        }}
      />
    },
    delete: {
      text: '',
      isDummyField: true,
      formatter: (cell, row) => <ConfirmAlert
        title={t('users:remove-mind-access', 'Remove MiND access')}
        message={t('users:confirm-remove-mind-access', 'Are you sure to remove access for this MiND?')}
        confirmBtnText={t('general:remove', 'remove').toCapitalCase()}
        onConfirm={() => {
          return axios.post('/api/permissions/delete/', {
            userprofile: +user.profile_id,
            mind: +row.id
          }).then(resp => {
            toast({
              title: t('users:mind-permission-removed', 'MiND permission removed successfully!') ,
              position: TOAST_POSITION
            })
            refetchUsers()
          })
      }} />
    }
  }

  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <EditLayout>
      <SectionWithTitle title={startCase(t('users:user-info', 'user info'))} rightHeadingChild={<EditProfile
        user={user}
        onSuccess={() => {
          //FIXME: Not a proper way to update user info.. probably better to separate current user from Users table
          // Create Profile page instead of this one.
          fetchUser()
          refetchUsers()
        }}
      />}>
        <StaticRow label={t('general:username', 'username').toCapitalCase()} content={username}/>
        <StaticRow label={t('general:fullname', 'fullname').toCapitalCase()} content={fullname}/>
        <StaticRow label={t('general:email', 'Email').toCapitalCase()} content={email}/>
        <StaticRow label={t('general:company', 'company').toCapitalCase()} content={company}/>
        <StaticRow label={t('general:branch', 'branch').toCapitalCase()} content={frigel_branch}/>
        <StaticRow label={t('general:language', 'language').toCapitalCase()} content={
            <Box>{lang.icon} {lang.label}</Box>
        }/>
        <StaticRow label={t('general:pageView', 'MiNDs page view').toCapitalCase()} content={MindsPageView.text}/>
      </SectionWithTitle>
      <UnitsSection user={user} isCurrentUserPage={isCurrentUserPage} onSuccess={(resp) => {
          // FIXME: Can be optimized as in the response there is
          // updated version on user, can be pass through in to users
          // updated the state
          refetchUsers()
        }
      }/>
      {canViewMinds &&
      <SectionWithTitle title={t('users:minds-related', 'MiNDs Related')}>
        {/* TODO: do not show table until user minds are loaded */}
        <DataTable
          data={userMinds}
          columns={columns}
          noDataIndication={<Center>{t('users:user-no-mind-access', 'User does not have access to any MiND')}</Center>}
          toolbar={(props) =>
            <Flex justifyContent='space-between'>
              <AddModal
                icon={<PlusIcon/>}
                selectOptions={notUserMinds.map(m => ({
                  value: m.id,
                  label: `[${m.serial}] ${m.company_name}`,
                  selectValue: m
                }))}
                permissionLevel={level}
                onSubmit={data => {
                  return axios.post('/api/permissions/', {
                    level: +data.permission,
                    userprofile: +user.profile_id,
                    mind: +data.selected.id
                  }).then(resp => {
                    toast({
                      title: t('users:mind-added', 'MiND added successfully!'),
                      position: TOAST_POSITION
                    })
                    refetchUsers()
                  })
                }}
                />
              <Box justifyContent='flex-end'>
                <SearchBar searchProps={props.searchProps} />
              </Box>
            </Flex>
          }
        />

      </SectionWithTitle>
      }

    </EditLayout>
  )
}

export default User;