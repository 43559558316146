import React from "react";
import {
  Text,
  Tooltip, HStack,useRadioGroup,
  ModalBody, Heading,
  UnorderedList, ListItem,
  Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon,
  TableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Table
} from "@chakra-ui/react";
import {  CIRCUIT_A, CIRCUIT_B, CIRCUIT_C, CIRCUIT_D, PAGEVIEW_MAP } from "../../consts";

import { InfoIcon, CheckIcon, XIcon, NoMapIcon, MapIcon } from '../../shared/icons';
import ModalSkeleton from "@/shared/components/ModalSkeleton";

import RadioCard from "../../shared/components/RadioCard";
import {
  ECODRY_MODELS, ECODRY_TYPES, ECODRY_CONTROL_SENSORS, ECODRY_ADIABATIC_MODES,
  UCP_MODELS, UCP_FANS_TYPES, UCP_BOOSTER_MODE,
  CENTRAL_CHILLER_CONTROL_LOGICS, CENTRAL_CHILLER_CONTROL_SENSORS, CHILLER_BOX_FC_MODE,
  SERIAL_CHILLER_COMPRESSOR_TYPES, SERIAL_CHILLER_PUMPS, SERIAL_CHILLER_GAS,
  SERIAL_CHILLER_ADAPTIVE_LOAD, SERIAL_CHILLER_COOLING_MEDIUM, SERIAL_CHILLER_EEV_TYPES,
  CIRCUIT_TYPES, CIRCUIT_DUTY, CIRCUIT_CONTROL_TYPES, TANK_TYPES
} from "@/consts";



export const ViewPage = ({ defaultValue, onChange, props }) => {

  const { getRadioProps } = useRadioGroup({
    defaultValue,
    onChange,
  })

  return (
    <>
      <RadioCard key={defaultValue} {...getRadioProps({ defaultValue })} mx={1} size='md'>
        <Tooltip label={`${defaultValue === PAGEVIEW_MAP ? 'Hidden' : 'Show'} map`}>
          <Text fontSize='xs'>{defaultValue === PAGEVIEW_MAP ? <NoMapIcon fontSize='md' /> : <MapIcon fontSize='md' />}</Text>
        </Tooltip>
      </RadioCard>
    </>
  )
};

const booleanFormatter = (value) => {
  return value ? <CheckIcon /> : <XIcon />
}

export const ModalSystemTable = ({machineSerial, dataSystem, isOpen, onClose}) => {
  let ucp_data_table = []
  if (dataSystem.hasOwnProperty('ucps') && dataSystem.ucps.length > 0) {
    ucp_data_table = dataSystem.ucps.reduce((acc, obj) => {
      var index = 0
      Object.entries(obj).forEach(([key, value], i) => {
        console.log(key, value)
        if (key == 'index') {
          return
        }
        if (i == 0) {
          index = 0
        }
        if (!acc[index]) {
          acc[index] = [];
        }
        acc[index].push(value);
        index += 1
      });
      return acc;
    }, [])

    // for every array in ucp_data_table, add the header 
    // attention: the order of the header is important
    const header = ['SW name', 'SW version', 'Type', 'Model', 'Fan type', 'Fan number', 'Adiabatic mode', 'Booster mode', 'Booster water recovery', 'Free cooling mode', 'Spray system drain', 'Power meter']
    ucp_data_table.forEach((row, rowIndex) => {
      row.unshift(header[rowIndex])
    })
  }
  return (
    <ModalSkeleton isOpen={isOpen} onClose={onClose} size='xl' title={
      <HStack spacing={2}>
        <InfoIcon fontSize='md' />
        <Heading fontSize='md' as='h3'>
          {`System Info ( ${machineSerial} )`}
        </Heading>
      </HStack>
    }>
      <ModalBody>
        <Accordion defaultIndex={[0]}>
          {dataSystem.hasOwnProperty('system') &&
            <UnorderedList>
              <AccordionItem key={0}>
                <AccordionButton >
                  <Text as='strong' flex="1" textAlign="left">{'System'}</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <UnorderedList>
                    <ListItem>
                      <Text as='strong'>{dataSystem.system.hmi_sw}</Text>: {dataSystem.system.hmi_sw_version_major}.{dataSystem.system.hmi_sw_version_middle}.{dataSystem.system.hmi_sw_version_minor}  {dataSystem.system.hmi_sw_extra_info}
                    </ListItem>
                    <ListItem>
                      <Text as='strong'>{dataSystem.system.logic_sw}</Text>: {dataSystem.system.logic_sw_version_major}.{dataSystem.system.logic_sw_version_minor}  {dataSystem.system.logic_sw_extra_info}
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem key={1}>
                <AccordionButton>
                  <Text as='strong' flex="1" textAlign="left">{`Ecodry ${ECODRY_MODELS[dataSystem.ecodry.model] ? ECODRY_MODELS[dataSystem.ecodry.model].name : dataSystem.ecodry.model}`}</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel>
                  <UnorderedList>
                    <ListItem>
                      <Text as='strong'>{'Type'}</Text>: {ECODRY_TYPES[dataSystem.ecodry.type] ? ECODRY_TYPES[dataSystem.ecodry.type].name : dataSystem.ecodry.type}
                    </ListItem>
                    <ListItem>
                      <Text as='strong'>{'Control sensor'}</Text>: {ECODRY_CONTROL_SENSORS[dataSystem.ecodry.control_sensor] ? ECODRY_CONTROL_SENSORS[dataSystem.ecodry.control_sensor].name : dataSystem.ecodry.control_sensor}
                    </ListItem>
                    <ListItem>
                      <Text as='strong'>{'Adiabatic mode'}</Text>: {ECODRY_ADIABATIC_MODES[dataSystem.ecodry.adiabatic_mode] ? ECODRY_ADIABATIC_MODES[dataSystem.ecodry.adiabatic_mode].name : dataSystem.ecodry.adiabatic_mode}
                    </ListItem>
                    <ListItem>
                      <Text as='strong'>{'Booster mode'}</Text>: {dataSystem.ecodry.booster_mode}
                    </ListItem>
                    <ListItem>
                      <Text as='strong'>{'Free cooling mode'}</Text>: {booleanFormatter(dataSystem.ecodry.free_cooling_mode)}
                    </ListItem>
                    <ListItem>
                      <Text as='strong'>{'Spray system drain'}</Text>: {booleanFormatter(dataSystem.ecodry.spray_system_drain)}
                    </ListItem>
                  </UnorderedList>
                </AccordionPanel>
              </AccordionItem>
              {dataSystem.hasOwnProperty('ucps') && dataSystem.ucps.length > 0 &&
                <AccordionItem key={2}>
                  <AccordionButton>
                    <Text as='strong' flex="1" textAlign="left">{`UCP (${dataSystem.ucps.length})`}</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <TableContainer>
                      <Table size='sm'>
                        <Thead>
                          <Tr>
                            <Th></Th>
                            {Array.from({ length: dataSystem.ucps.length }, (_, i) => i).map((i) => {
                              return <Th key={i}>UCP {i + 1}</Th>
                            }
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {ucp_data_table.map((row, rowIndex) => (
                            <Tr key={rowIndex}>
                              {row.map((cell, cellIndex) => {
                                if (cellIndex > 0) {
                                  if (rowIndex == 2) {
                                    return <Td key={cellIndex}>{ECODRY_TYPES[cell] ? ECODRY_TYPES[cell].name : cell}</Td>
                                  } else if (rowIndex == 3) {
                                    return <Td key={cellIndex}>{UCP_MODELS[cell] ? UCP_MODELS[cell].name : cell}</Td>
                                  } else if (rowIndex == 4) {
                                    return <Td key={cellIndex}>{UCP_FANS_TYPES[cell] ? UCP_FANS_TYPES[cell].name : cell}</Td>
                                  }  else if (rowIndex == 6) {
                                    return <Td key={cellIndex}>{ECODRY_ADIABATIC_MODES[cell] ? ECODRY_ADIABATIC_MODES[cell].name : cell}</Td>
                                  } else if (rowIndex == 7 && dataSystem.system.hmi_sw_version_major >= 6) {
                                    return <Td key={cellIndex}>{UCP_BOOSTER_MODE[cell] ? UCP_BOOSTER_MODE[cell].name : cell}</Td>
                                  }
                                  return <Td key={cellIndex}>{typeof (cell) == 'boolean' ? booleanFormatter(cell) : cell}</Td>
                                } else {
                                  return <Td key={cellIndex}><strong>{cell}</strong></Td>
                                }
                              }
                              )}
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </AccordionPanel>
                </AccordionItem>

              }
              {dataSystem.hasOwnProperty('central_chiller') && dataSystem.central_chiller.control_logic != 0 &&
                <AccordionItem key={4}>
                  <AccordionButton>
                    <Text as='strong' flex="1" textAlign="left">{'Central Chiller'}</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <UnorderedList>
                      <ListItem>
                        <Text as='strong'>{dataSystem.central_chiller.sw_name}</Text>: {dataSystem.central_chiller.sw_version}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Control logic'}</Text>: {CENTRAL_CHILLER_CONTROL_LOGICS[dataSystem.central_chiller.control_logic] ? CENTRAL_CHILLER_CONTROL_LOGICS[dataSystem.central_chiller.control_logic].name : dataSystem.central_chiller.control_logic}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Control sensor'}</Text>: {CENTRAL_CHILLER_CONTROL_SENSORS[dataSystem.central_chiller.control_sensor] ? CENTRAL_CHILLER_CONTROL_SENSORS[dataSystem.central_chiller.control_sensor].name : dataSystem.central_chiller.control_sensor}
                      </ListItem>
                      {dataSystem.central_chiller.chiller_box_installed &&
                        <ListItem>
                          <Text as='strong'>{'Chiller box'}</Text>:
                          <UnorderedList>
                            <ListItem>
                              <Text as='strong'>{'Installed'}</Text>: {booleanFormatter(dataSystem.central_chiller.chiller_box_installed)}
                            </ListItem>
                            <ListItem>
                              <Text as='strong'>{'Ambient sensor'}</Text>: {dataSystem.central_chiller.chiller_box_ambient_sensor}
                            </ListItem>
                            <ListItem>
                              <Text as='strong'>{'Free cooling mode'}</Text>: {CHILLER_BOX_FC_MODE[dataSystem.central_chiller.chiller_box_free_cooling_mode] ? CHILLER_BOX_FC_MODE[dataSystem.central_chiller.chiller_box_free_cooling_mode].name : dataSystem.central_chiller.chiller_box_free_cooling_mode}
                            </ListItem>
                          </UnorderedList>
                        </ListItem>
                      }
                      <ListItem>
                        <Text as='strong'>{'N serial chiller'}</Text>: {dataSystem.central_chiller.n_serial_chiller}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'N digital chiller'}</Text>: {dataSystem.central_chiller.n_digital_chiller}
                      </ListItem>
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              }
              {dataSystem.hasOwnProperty('Serial_chillers') && dataSystem.serial_chillers.map((serial_chiller, index) => (
                <AccordionItem key={'serial_C' + index}>
                  <AccordionButton>
                    <Text as='strong' flex="1" textAlign="left">{'Chiller '} {serial_chiller.index}</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <UnorderedList>
                      <ListItem>
                        <Text as='strong'>{serial_chiller.sw_name}</Text>: {serial_chiller.sw_version}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Compressor_type'}</Text>: {SERIAL_CHILLER_COMPRESSOR_TYPES[serial_chiller.compressor_type] ? SERIAL_CHILLER_COMPRESSOR_TYPES[serial_chiller.compressor_type].name : serial_chiller.compressor_type}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Circuits'}</Text>: {serial_chiller.circuits}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Compressors per circuit'}</Text>: {serial_chiller.compressors_per_circuit}
                      </ListItem>

                      <ListItem>
                        <Text as='strong'>{'Pumps'}</Text>: {SERIAL_CHILLER_PUMPS[serial_chiller.pumps] ? SERIAL_CHILLER_PUMPS[serial_chiller.pumps].name : serial_chiller.pumps}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Gas'}</Text>: {SERIAL_CHILLER_GAS[serial_chiller.gas] ? SERIAL_CHILLER_GAS[serial_chiller.gas].name : serial_chiller.gas}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Compressor inverter'}</Text>: {SERIAL_CHILLER_PUMPS[serial_chiller.compressor_inverter] ? SERIAL_CHILLER_PUMPS[serial_chiller.compressor_inverter].name : serial_chiller.compressor_inverter}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Adaptive load'}</Text>: {SERIAL_CHILLER_ADAPTIVE_LOAD[serial_chiller.adaptive_load] ? SERIAL_CHILLER_ADAPTIVE_LOAD[serial_chiller.adaptive_load].name : serial_chiller.adaptive_load}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Cooling medium'}</Text>: {SERIAL_CHILLER_COOLING_MEDIUM[serial_chiller.cooling_medium] ? SERIAL_CHILLER_COOLING_MEDIUM[serial_chiller.cooling_medium].name : serial_chiller.cooling_medium}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Reduced capacity control'}</Text>: {booleanFormatter(serial_chiller.reduced_capacity_control)}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Eev type'}</Text>: {SERIAL_CHILLER_EEV_TYPES[serial_chiller.eev_type] ? SERIAL_CHILLER_EEV_TYPES[serial_chiller.eev_type].name : serial_chiller.eev_type}
                      </ListItem>
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              ))}
              {dataSystem.hasOwnProperty('circuits') && dataSystem.circuits.map((circuit, index) => {
                let duty_value = CIRCUIT_DUTY[circuit.duty] ? CIRCUIT_DUTY[circuit.duty].name : ''

                if (circuit.duty && circuit.duty == 1) {
                  if (circuit.name == 'A'){
                    duty_value = 'ECODRY WATER'
                  } else if (circuit.name == 'B'){
                    duty_value = 'CENTRAL CHILLER WATER'
                  } else if (circuit.name == 'C'){
                    duty_value = 'EVAPORATORS'
                  }
                }
                if (circuit.name == 'D'){
                  if (circuit.duty == 0){
                    duty_value = 'NO'
                  }else{
                    duty_value = 'YES'
                  }
                }
                return (
                <AccordionItem key={'circuit' + index}>
                  <AccordionButton>
                    <Text as='strong' flex="1" textAlign="left">{'Circuit '} {circuit.name}</Text>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    <UnorderedList>
                      <ListItem>
                        <Text as='strong'>{circuit.sw_name}</Text>: {circuit.sw_version}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Type'}</Text>: {CIRCUIT_TYPES[circuit.type] ? CIRCUIT_TYPES[circuit.type].name : circuit.type}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Number of pumps'}</Text>: {circuit.number_of_pumps}
                      </ListItem>
                      <ListItem>
                        <Text as='strong'>{'Power meter'}</Text>: {booleanFormatter(circuit.power_meter)}
                      </ListItem>
                      { [CIRCUIT_A, CIRCUIT_B, CIRCUIT_C, CIRCUIT_D].includes(circuit.name) &&
                      <>
                        <ListItem>
                          <Text as='strong'>{'Duty'}</Text>: {duty_value}
                        </ListItem>
                        <ListItem>
                          <Text as='strong'>{'Control type'}</Text>: {CIRCUIT_CONTROL_TYPES[circuit.control_type] ? CIRCUIT_CONTROL_TYPES[circuit.control_type].name : circuit.control_type}
                        </ListItem>
                        <ListItem>
                          <Text as='strong'>{'Sov'}</Text>: {booleanFormatter(circuit.sov)}
                        </ListItem>
                        <ListItem>
                          <Text as='strong'>{'Tank'}</Text>:
                          <UnorderedList>
                            <ListItem>
                              <Text as='strong'>{'Type'}</Text>: {TANK_TYPES[circuit.tank_type] ? TANK_TYPES[circuit.tank_type].name : circuit.tank_type}
                            </ListItem>
                            {circuit.tank_type!=0 &&
                              <ListItem>
                                <Text as='strong'>{'Auto fill'}</Text>: {booleanFormatter(circuit.tank_auto_fill)}
                              </ListItem>
                            }
                          </UnorderedList>
                        </ListItem>
                        <ListItem>
                          <Text as='strong'>{'Filter'}</Text>: {booleanFormatter(circuit.filter)}
                        </ListItem>
                        {circuit.name === CIRCUIT_A &&
                        <ListItem>
                          <Text as='strong'>{'Bypass valve'}</Text>: {booleanFormatter(circuit.bypass_valve)}
                        </ListItem>
                      }
                    </>
                  }
                    </UnorderedList>
                  </AccordionPanel>
                </AccordionItem>
              )})}
            </UnorderedList>
          }
        </Accordion>
      </ModalBody>
    </ModalSkeleton>
  )
}