import axios from 'axios'
import { MIND_TOKEN_URL } from './urls'
import { STANDARD_ERROR_MSG, TOAST_POSITION } from "../consts";


export const getToken = (mid) => axios.get(MIND_TOKEN_URL, {params: {mid}})
export const sendToken = (mind_url, token) => {
    // Send token through post so it can not be logged on a proxy
  
    const endpoint = `${mind_url}/api/set_token/`;
    const formNode = document.createElement('form');
    formNode.target = '_blank'
    formNode.action = endpoint;
    formNode.method = 'POST';
    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'token';
    formInput.value = token;
    formNode.appendChild(formInput);
    document.body.appendChild(formNode);
    formNode.submit();
    document.body.removeChild(formNode)
  }

export const handleMindLogin = (mid, serial, mind_url, toast, t) => {
    // getToken(mid).then(({data: {token, error}}) => console.log(token))
    getToken(mid)
        .then(({data: {token, error}}) => {
        if (token) {
            toast({
                title: t('minds:open-new-tab', 'opening a new tab...').toCapitalCase(),
                description: `${t('minds:redirect-to', 'you are going to be redirect to').toCapitalCase()} ${serial} MiND`,
                position: TOAST_POSITION,
                isClosable: true,
            })
            setTimeout(() => sendToken(mind_url, token), 2000)
        } else {
            toast({
                status: 'error',
                description: STANDARD_ERROR_MSG,
                position: TOAST_POSITION,
                isClosable: true,
            })
        }
        })
        .catch(reason => {
            toast({
                status: 'error',
                description: STANDARD_ERROR_MSG,
                position: TOAST_POSITION,
                isClosable: true,
            })
    })
}