import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  useColorMode,
  ModalBody,
  IconButton,
  useDisclosure,
  FormLabel,
  Select,
  VStack,
  FormControl,
  useToast,
  ModalFooter,
  FormHelperText,
  RadioGroup,
} from "@chakra-ui/react";
import { default as ReactSelect } from "react-select";
import AsyncSelect from "react-select/async";
import { AddUserIcon, EditIcon, UKIcon } from "../../shared/icons";
import ModalSkeleton from "@/shared/components/ModalSkeleton";
import { PERMISSIONS_LIST } from "@/consts";
import { secondaryBtnStyle, reactSelectDarkColors } from "../../shared/theme";
import { TOAST_POSITION, USER, STANDARD_ERROR_MSG, LANGUAGES, PAGEVIEWS_OPTIONS, PAGEVIEW_MAP} from "../../consts";
import { StaticRow, SectionWithTitle } from '@/components/elements'
import { updateUser } from "../../api/users";
import { fetchCompanies, fetchCompanyCoordinates, upsertCompany } from "../../api/companies";
import { useTranslation } from "react-i18next";
import { useUser } from "../../contexts/auth";
import {Button} from '@/shared/components/core/Button'


export const AddModal = ({ selectOptions, permissionLevel, onSubmit, icon=AddUserIcon }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [selected, setSelected] = useState();
  const [permission, setPermission] = useState(USER)
  const permissions = PERMISSIONS_LIST.filter(p => p.id <= permissionLevel.id)
  const toast = useToast()
  const {t} = useTranslation(['general'])

  return (
    <>
      {/* TODO: Change the icon */}
      <IconButton
        icon={icon}
        size="sm"
        fontSize="lg"
        onClick={() => onOpen()}
        {...secondaryBtnStyle({ colorMode })}
      />
      <ModalSkeleton isOpen={isOpen} onClose={onClose} title={`${t('general:add', 'add').toCapitalCase()} MiND`}>
        <ModalBody>
          <Box
            as="form"
            w="full"
            id='add_mind_to_user'
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit({permission, selected})
                .then(() => onClose())
                .catch(reason => {
                  toast({
                    status: 'error',
                    description: STANDARD_ERROR_MSG,
                    position: TOAST_POSITION
                  })
                  console.error(reason)
                })
            }}
          >
            <VStack align="flex-start">
              <FormControl w="full" id="minds">
                <FormLabel>MiND</FormLabel>
                <ReactSelect
                  name="minds"
                  theme={colorMode === 'dark' && {colors:  reactSelectDarkColors}}
                  isSearchable={true}
                  isClearable={true}
                  onChange={(s) => {
                    s ? setSelected(s.selectValue) : setSelected();
                  }}
                  options={selectOptions}
                />
               </FormControl>

              <FormControl isDisabled={!selected}>
                <FormLabel>{t('general:permission', 'permission').toCapitalCase()}</FormLabel>
                <Select value={permission}  onChange={e => setPermission(e.target.value)}>
                  {permissions.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant='secondary' mr={2}>
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end"
            variant='primary' type="submit" form='add_mind_to_user'>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};


export const UnitsSection = ({user, onSuccess}) => {

  if (!user || !user.pressure){
    return null
  }
  const {colorMode} = useColorMode()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const toast = useToast()
  const {t} = useTranslation(['general', 'users'])

  const fields = [
    {label: t('users:Pressure', 'Pressure'), key: 'pressure', options: PRESSURE_LIST},
    {label: t('users:Flowrate', 'Flowrate'), key: 'flowrate', options: FLOWRATE_LIST},
    {label: t('users:Temperature', 'Temperature'), key: 'temperature', options: TEMPERATURE_LIST},
    {label: t('users:Power', 'Power'), key: 'power', options: POWER_LIST},
    {label: t('users:Length', 'Length'), key: 'length', options: LENGTH_LIST},
    // {label: t('users:Frequency', 'Frequency'), key: 'frequency', options: FREQUENCY_LIST},
    // {name: 'Percentage', key: 'percentage', values: PERCENTAGE_LIST},
    // {name: 'Time', key: '_time', values: TIME_LIST},
  ];

  return (
    <>
      <SectionWithTitle title={t('users:units-preferences', 'Units Preferences')}  rightHeadingChild={<EditIcon cursor='pointer' onClick={onOpen} />}>
        {fields.map(f => <StaticRow key={f.key} label={f.label} content={user[f.key]} />)}
      </SectionWithTitle>

      <ModalSkeleton isOpen={isOpen} onClose={onClose} title={t('users:edit-units', "Edit Units")}>
        <ModalBody as='form' id='units_form' onSubmit={(e) => {
          e.preventDefault()
          const units = [...e.target.elements].reduce((acc, u) => {
            acc[u.name] = u.value
            return acc
          }, {})
          updateUser(user.profile_id, {...units, id: user.profile_id})
            .then(resp => {
              toast({
                title: t('users:user-units-updated', 'User units updated!'),
                status: 'success',
                position: TOAST_POSITION
              })
              onSuccess(resp)
              onClose()
            })
            .catch(reason => {
              toast({
                status: 'error',
                description: STANDARD_ERROR_MSG,
                position: TOAST_POSITION
              })
              console.error(reason)
            })
        }}>
        {fields.map(f => (
          <StaticRow  key={f.key} label={f.label}
            labelProps={{w: ['100%', '50%'], lineHeight: 2 }}
              content={(
                <Select defaultValue={user[f.key]} name={f.key}>
                  {f.options.map(o => <option key={o.id}  value={o.id}>{o.name}</option>)}
                </Select>
              )}
          />))}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant='secondary' mr={2}>
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button lineHeight
            alignSelf="flex-end"
            variant='primary' type="submit" form='units_form'>
            {t('general:Submit', 'Submit')}
          </Button>

        </ModalFooter>
      </ModalSkeleton>
    </>
  )
}

export const EditProfile = ({user, onSuccess}) => {
  const {colorMode} = useColorMode()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {language, preferences} = user
  const [companiesCount, setCompaniesCount] = useState(-1)
  const [companyInfo, setCompanyInfo] = useState();
  const [companyToSubmit, setCompanyToSubmit] = useState();
  const {t} = useTranslation(['general'])
  const toast = useToast()
  const currentUser = useUser()
  const canChangeCompany = currentUser.isAdmin || currentUser.isManager
  
  useEffect(() => {
    if (!isOpen) {
      // Reset
      setCompanyInfo()
      setCompanyToSubmit()
    }
  }, [isOpen])

  const promiseOptions = (inputValue) => fetchCompanies(inputValue)
    .then(resp => {
      const [options, data] = resp
      setCompaniesCount(data.count)
      return options
    });

  const setUserProfile = (user, language, pageView, company=null) => {
    let data_to_send = {
      id: user.profile_id,
      language: language,
      preferences: {
        ...user.preferences,
        pageView: pageView,
      }
    }
    if (company!=null)
      data_to_send.company =company
    updateUser(user.profile_id, data_to_send).then((resp) => {
      onSuccess()
      toast({
        title: t('users:user-profile-updated', 'User profile updated!'),
        status: 'success',
        position: TOAST_POSITION,
      })
      onClose()
    }).catch(reason => {
      toast({
        status: 'error',
        description: STANDARD_ERROR_MSG,
        position: TOAST_POSITION
      })
      console.error(reason)
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (companyInfo) {
      let dataToSubmit = {
        ...companyInfo,
        ...companyToSubmit,
      }
      upsertCompany(dataToSubmit)
        .then(company => {
            setUserProfile(user, e.target.elements.language.value, e.target.elements.pageview.value, company.url)
          })
          .catch((reason) => {
            toast({
              status: 'error',
              description: STANDARD_ERROR_MSG,
              position: TOAST_POSITION,
              isClosable: true,
            })
            console.error(reason)
          })

    }
    else {
      setUserProfile(user, e.target.elements.language.value, e.target.elements.pageview.value)
    }
  }
  return (
    <>
      <EditIcon cursor='pointer' onClick={onOpen} />
      <ModalSkeleton isOpen={isOpen} title={`${t('general:edit').toCapitalCase()} ${t('general:Profile', "Profile")}`} onClose={onClose}>
        <ModalBody as="form" id="profileForm" onSubmit={handleSubmit}>
        {canChangeCompany && <FormControl my={4} w="inherit" id='search_company'>
              {companiesCount > 20 && <FormHelperText mb={1}>
                  {t('minds:refine-company-search', 'If you do not find the searched company please refine the search...')}
                </FormHelperText>}
              <AsyncSelect
                placeholder={`${t('general:Search', 'Search')} ${t('general:company', 'company')}...`}
                theme={colorMode === 'dark' && {colors:  reactSelectDarkColors}}
                onChange={(c) => {
                  fetchCompanyCoordinates(c.company).then((coordinates) => {
                    const selectedCompnayBEnames = {
                      ...c.company,
                      ...coordinates,
                    };
                    setCompanyInfo({
                      name: selectedCompnayBEnames.ragione_sociale,
                      address: [
                        selectedCompnayBEnames.indirizzo,
                        selectedCompnayBEnames.cap,
                        selectedCompnayBEnames.localita,
                        selectedCompnayBEnames.nazione,
                      ].join(", "),
                      latitude: selectedCompnayBEnames.latitude,
                      longitude: selectedCompnayBEnames.longitude,
                      country: { name: selectedCompnayBEnames.nazione },
                    });
                    setCompanyToSubmit(selectedCompnayBEnames);
                  });
                }}
                cacheOptions
                noOptionsMessage={() => (
                  companiesCount === -1
                    ? t('minds:company-type-to-load-options', 'Type to load the available options')
                    : t('minds:no-options', 'no options').toCapitalCase()
                )}
                loadOptions={promiseOptions}
              />
            </FormControl>}
          <FormControl>
            <FormLabel htmlFor="language">{t('general:language', 'language').toCapitalCase()}</FormLabel>
            <Select
              id="language"
              defaultValue={language}
            >
              {LANGUAGES.map(l => <option value={l.value}>{l.label}</option>)}
            </Select>
          </FormControl>
          <Box mt={5}>
          <FormLabel htmlFor="preference">{t('general:pageView', 'MiNDs page view').toCapitalCase()}</FormLabel>
          <Select
              id="pageview"
              defaultValue={preferences['pageView'] || PAGEVIEW_MAP}>
              {Object.keys(PAGEVIEWS_OPTIONS).map(p =><option value={p}>{p}</option> )}
            </Select>
          </Box>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button onClick={onClose} variant='secondary'>
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            type="submit"
            form="profileForm"
            variant='primary'
          >
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  )

}

// TODO: It can be moved elsewhere as well, but as they are used only in `UnitsFields` for
// collocation I have pasted them here.

export const PRESSURE_bar = 'bar';
export const PRESSURE_mbar = 'mbar';
export const PRESSURE_psi = 'psi';
export const PRESSURE_MPa = 'MPa';
export const PRESSURE_kPa = 'kPa';
export const PRESSURE_m = 'w_m';
export const PRESSURE_ft = 'w_ft';

export const PRESSURE_LIST = [
  {name: 'bar', id: PRESSURE_bar},
  {name: 'mbar', id: PRESSURE_mbar},
  {name: 'psi', id: PRESSURE_psi},
  {name: 'kPa', id: PRESSURE_kPa},
  {name: 'MPa', id: PRESSURE_MPa},
  {name: 'mH2O', id: PRESSURE_m},
  {name: 'ftH2O', id: PRESSURE_ft}
];

export const FLOWRATE_lmin = 'l/min';
export const FLOWRATE_lsec = 'l/sec';
export const FLOWRATE_m3h = 'm3/h';
export const FLOWRATE_GPM = 'GPM';

export const FLOWRATE_LIST = [
  {name: 'l/min', id: FLOWRATE_lmin},
  {name: 'l/sec', id: FLOWRATE_lsec},
  {name: 'm3/h', id: FLOWRATE_m3h},
  {name: 'GPM', id: FLOWRATE_GPM}
];

export const TEMPERATURE_C = '°C';
export const TEMPERATURE_F = '°F';
export const TEMPERATURE_K = 'K';
export const DIFFERENTIAL_F = 'd°F';
export const DIFFERENTIAL_C = 'd°C';

export const TEMPERATURE_LIST = [
  {name: '°C', id: TEMPERATURE_C},
  {name: '°F', id: TEMPERATURE_F},
  {name: '°K', id: TEMPERATURE_K},
  // {name: 'd°C', id: DIFFERENTIAL_C},
  // {name: 'd°F', id: DIFFERENTIAL_F}
];

export const POWER_kW = 'kW';
export const POWER_ton = 'ton';
export const POWER_W = 'W';

export const POWER_LIST = [
  {name: 'kW', id: POWER_kW},
  {name: 'ton', id: POWER_ton},
  {name: 'W', id: POWER_W}
];

export const LENGTH_mm = 'mm';
export const LENGTH_cm = 'cm';
export const LENGTH_in = 'in';
export const LENGTH_m = 'm';
export const LENGTH_ft = 'ft';

export const LENGTH_LIST = [
  {name: 'mm', id: LENGTH_mm},
  {name: 'cm', id: LENGTH_cm},
  {name: 'in', id: LENGTH_in},
  {name: 'm', id: LENGTH_m},
  {name: 'ft', id: LENGTH_ft}
];

// export const FREQUENCY_Hz = 'Hz';
// export const FREQUENCY_rpm = 'rpm';

// export const FREQUENCY_LIST = [
//   {name: 'Hz', id: FREQUENCY_Hz},
//   {name: 'rpm', id: FREQUENCY_rpm}
// ];

export const PERCENTAGE = '%';
export const PERCENTAGE_SMALL = '0.1%';

export const PERCENTAGE_LIST = [
  {name: '%', id: PERCENTAGE},
  // {name: '0.1%', id: PERCENTAGE_SMALL}
];


export const TIME_0s = '0s';
export const TIME_s = 's';
export const TIME_m = 't_m';
export const TIME_h = 'h';
export const TIME_d = 'd';
export const TIME_y = 'y';

export const TIME_LIST = [
  {name: 'sec/10', id: TIME_0s},
  {name: 'sec', id: TIME_s},
  {name: 'min', id: TIME_m},
  {name: 'hrs', id: TIME_h},
  {name: 'day', id: TIME_d},
  {name: 'year', id: TIME_y}
];