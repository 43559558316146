import React, { useEffect, useState } from 'react';
import {
  useColorMode, IconButton,
  Box,
  Avatar,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuDivider,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Heading,
  Text,
  Tooltip,
  HStack,
  CloseButton,
  Flex,
  Badge,
  SlideFade,
  Select,
  Button,
  useToast
} from "@chakra-ui/react"
import { useUser, useAuth } from '../../contexts/auth';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, ColorModeIcon } from '@/shared/icons';
import { CircleIcon, SettingsIcon, WarningIcon } from '../../shared/icons';
import { fetchUserNotificationsValues, readNotification, markAsReadMultipleNotifications } from '../../api/users';
import { useData } from '../../hooks';
import { secondaryBtnStyle } from '@/shared/theme'
import { STANDARD_ERROR_MSG, TOAST_POSITION } from '../../consts';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns'


export const ColorModeToggleButton = (props) => {
  const { colorMode, toggleColorMode } =  useColorMode()

  return (
    <IconButton
      _hover={{ bg: 'frigel.600' }}
      fontSize={20}
      aria-label={`Switch to ${colorMode} mode`}
      variant="ghost"
      color="white"
      onClick={toggleColorMode}
      icon={<ColorModeIcon/>}
      {...props}
    />
  )
}

export const UserMenuWidget = (props) => {
  const user = useUser()
  const { logout } = useAuth()
  const history = useHistory()
  const {colorMode} = useColorMode()
  const secondaryBtnStyleValues = secondaryBtnStyle({colorMode})
  const menuItemStyle = {
    _hover: secondaryBtnStyleValues._hover,
    _focus: secondaryBtnStyleValues._hover
  }

  const {t} = useTranslation(['general'])

  return (
    <>
      <Menu>
        <MenuButton as={Box} mt={1} cursor='pointer'
          {...props} >
          <Avatar name={user.fullname} size='sm' />
          <ChevronDownIcon color='white' ml={1} mt={2} />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={()=> history.push('/user') } {...menuItemStyle}>
             {t('general:Profile', 'Profile')} <Text as='small' ml={1}>({user.level.name})</Text>
          </MenuItem>
          <MenuDivider />
          <MenuItem onClick={() => logout()} {...menuItemStyle}>
            {t('general:Logout', 'Logout')}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}


export const NotificationsWidget = (props) => {

  const { isOpen, onOpen, onClose } = useDisclosure()
  const user = useUser()
  const  {colorMode} = useColorMode()
  const toast = useToast()
  const [notificationType, setNotificationType] = useState('alarm')
  const [count, setCount]  = useState(0)
  const {t} = useTranslation(['general', 'notifications'])


  const {data: notifications, isFetching, refetch} = useData({
    url: `/api/userprofiles/${user.profile_id}/notification_values/`,
    onSuccess: (d) => {
      if (count > 1 && d.length - count >= 1){
        // FIXME: Showing toast with this logic is not great as in case of 100+
        // there will not be any notification even if there are just came in.
        // To overcome the issue we can change the api sending back the ids of
        // the notifications we already have so in this way those will be discarded
        // one extra constrain will be on the date-time and the data in fe
        // will have to be ordered
        setCount(d.length)
        toast({
          status: 'info',
          description: t('notifications:new-notifications-available', 'New notifications available!'),
          position: TOAST_POSITION,
          isClosable: true,
        })
      } else {
        setCount(d.length)
      }
    },
    refetchInterval: 5000,
    skipFetchIfHidden: true,
  })

  const typeCounts = notifications.reduce((acc, n) => {
    const _type = n.channel._type ? n.channel._type.toLowerCase() : 'setup';
    if(_type==='setup'){
      acc.setups++
    }else{
      acc.alarms++
    }
    return acc
    }, {setups: 0, alarms: 0})

  useEffect(() => {
    if (isOpen){
      refetch()
    }
  }, [isOpen])

  const filteredNotifications = notifications.filter(n => {
    const _type = n.channel._type ? n.channel._type.toLowerCase() : 'setup';

    if (notificationType === 'all'){
      return true
    }
    if ((_type === 'warning' || _type === 'connected') && notificationType === 'alarm'){
      return true
    }

    return _type === notificationType
  })


  return (
    <>
      <IconButton
        _hover={{ bg: 'frigel.600' }}
        variant="ghost"
        color="white"
        icon={
          <>
            <WarningIcon/>
            {typeCounts.alarms > 0 &&
              <Badge pos='absolute' top={1} left={6} borderRadius='lg'
                bg={'frigel.light.status.alarm'}
                color='frigel.neutral.white'
              >
                {typeCounts.alarms >= 100 ? '100+': typeCounts.alarms}
              </Badge>
            }
          </>
      }
      onClick={() => {
        setNotificationType('alarm')
        onOpen()
      }}
      />
      <IconButton
        _hover={{ bg: 'frigel.600' }}
        variant="ghost"
        color="white"
        icon={
          <>
            <SettingsIcon/>
            {typeCounts.setups > 0 &&
              <Badge pos='absolute' top={1} left={6} borderRadius='lg'
                bg='frigel.neutral.white' color='frigel.neutral.darkGray'>
                {typeCounts.setups >= 100 ? '100+': typeCounts.setups}
              </Badge>
            }
          </>
        }
        onClick={() => {
          setNotificationType('setup')
          onOpen()
        }}
      />

      <Drawer placement={'right'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              <Flex justify='space-between'>
                {t('general:Notifications', 'Notifications')}
                <Select value={notificationType}
                  size="sm" ml={14}
                  onChange={e => setNotificationType(e.target.value)}>
                    <option value="all">{t('general:All', 'All')}</option>
                    <option value="alarm">{t('general:alarms', 'alarms').toCapitalCase()}</option>
                    <option value="setup">{t('general:setups', 'setups').toCapitalCase()}</option>
                </Select>
              </Flex>
            </DrawerHeader>
            <DrawerBody overflowX='hidden'>
              <VStack>
                {filteredNotifications.map((n, i) => <Notification key={n.id} {...n} refetch={refetch}/>)}
              </VStack>
            </DrawerBody>
            <DrawerFooter>
              <Button
                disabled={filteredNotifications.length === 0}
                onClick={() => {
                  const ids = filteredNotifications.map(n => n.id)
                  markAsReadMultipleNotifications(user.profile_id, ids)
                    .then(resp => {
                      refetch()
                      toast({
                        status: 'success',
                        description: t('notifications:notif-marked-as-read', 'Notifications has been marked as read') ,
                        position: TOAST_POSITION,
                        isClosable: true,
                      })
                    })
                    .catch(reason => {
                      toast({
                        status: 'error',
                        description: STANDARD_ERROR_MSG,
                        position: TOAST_POSITION,
                        isClosable: true,
                      })
                      console.error(reason)
                    })
                }}
                size='sm' {...secondaryBtnStyle({colorMode})} variant='outline'>
                  {t('general:Clear All', 'Clear All')}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

const boolean_values = ['true', 'True'];

const Notification = ({id, mind, datetime, mind_local_datetime, machine, channel, value, refetch}) => {
  const {t} = useTranslation(['general', 'notifications'])

  const _type = channel._type ? channel._type.toLowerCase() : 'setup';
  let notifClass = `frigel.light.status.offline`;
  let valueToShow;
  let desc = channel.description;

  if(_type === 'setup') {
    if(Array.isArray(channel.description))
      desc = channel.description.join(' > ')

    valueToShow = <Text as='small' fontSize='sm'>{t('general:changed-to', 'Changed to')}: {value}</Text>;
    notifClass = 'frigel.blueAccentLight'
  } else {
    const alarm_code = parseInt(value);
    let display_value;
    if(isNaN(alarm_code)) {
      if ( _type === 'connected' ){
        notifClass = value === 'False' ? `frigel.light.status.warning`: 'frigel.light.status.offline' ;
        display_value = '';
      }else{
        notifClass = boolean_values.includes(value) ? `frigel.light.status.${_type}` : 'frigel.light.status.offline';
        display_value = boolean_values.includes(value) ? '' : 'SOLVED';
      }
    } else {
      notifClass = alarm_code ? `frigel.light.status.${_type}` : 'frigel.light.status.offline'
      display_value = alarm_code ? `${alarm_code}` : 'SOLVED';

    }
    valueToShow = <Text as='strong'>{display_value}</Text>
  }

  const MindTooltipContent = (
    <Box>
      <Box>{t('general:serial', 'serial').toCapitalCase()}: {mind.serial}</Box>
      <Box>{t('general:company', 'company').toCapitalCase()}: {mind.company}</Box>
    </Box>
  )

  const MachineTooltipContent = (
    <Box>
      <Box>{t('general:serial', 'serial').toCapitalCase()}: {machine.serial}</Box>
      <Box>{t('general:name', 'name').toCapitalCase()}: {machine.name}</Box>
      <Box>{t('general:model', 'model').toCapitalCase()}: {machine.model}</Box>
    </Box>
  )

  const ChannelTooltipContent = (
    <Box>
      <Box>{t('general:code', 'code').toCapitalCase()}: {channel.name}</Box>
      <Box>{t('general:description', 'description').toCapitalCase()}: {desc}</Box>
    </Box>
  )
  const [transition, setTransition] = useState(true)
  const localesOptions = Intl.DateTimeFormat().resolvedOptions()

  return (
    <Box w='full'>
    <SlideFade in={transition} offsetX="500px">
    <Box border='1px solid'
      borderColor={notifClass}
      bgColor={notifClass+'50'}
      borderRadius='md' p={2}>
        <Flex justify='space-between'>
          <Tooltip label={<Box> {t('general:your-time', 'Your time')}: {new Date(datetime).toLocaleString(localesOptions.locale, {
              hour12: false, timeStyle: 'long',
              dateStyle: 'medium'
            })}</Box>}>
            <Text as='small' textDecor='underline' fontSize='xs' >
              {mind_local_datetime}
            </Text>
          </Tooltip>
           <CloseButton size='sm' onClick={() => {
            readNotification(id)
              .then(() => {
                setTransition(false)
                setTimeout(() => refetch(), 120)
              })
          }}/>
        </Flex>
        <Box fontSize='sm'>
          <HStack>
            <Text>MiND:</Text>
            <Tooltip label={MindTooltipContent}>
              <Text decoration='underline' width='25ch'
                display='block' overflow='hidden'
                whiteSpace='nowrap' textOverflow='ellipsis'
              >
                {mind.serial}
              </Text>
            </Tooltip>
          </HStack>
          <HStack>
            <Text>{t('general:machine', 'machine').toCapitalCase()}:</Text>
            <Tooltip label={MachineTooltipContent}>
              <Text decoration='underline'>{machine.serial}</Text>
            </Tooltip>
          </HStack>
          <HStack>
            <Text>{t('general:code', 'code').toCapitalCase()}:</Text>
            <Tooltip label={ChannelTooltipContent}>
            <Text decoration='underline'>
              { !channel._type || channel._type.toLowerCase() != 'connected' ? channel.name : value === 'True'? 'CONNECTED': 'DISCONNECTED'}</Text>
            </Tooltip>
          </HStack>
        </Box>
        <Box>
          {valueToShow}
        </Box>
      </Box>
    </SlideFade>
    </Box>
  )
}
