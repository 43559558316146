import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMinds } from '@/contexts/minds'
import { EditLayout } from '@/layouts'
import FullPageSpinner from '@/shared/components/FullPageSpinner'
import { MANAGER, USER, ADMIN, TOAST_POSITION } from '@/consts';
import { handleMindLogin } from "@/api/minds";

import theme from '../../shared/theme';

import {
  Box, Flex, FormControl, FormLabel, HStack, Input, Link, ModalBody, ModalFooter,
  useColorMode, useDisclosure, useToast, SimpleGrid,
  Divider, Heading, Accordion, AccordionButton, AccordionItem, AccordionIcon, AccordionPanel, VStack
} from '@chakra-ui/react'

import { useAuth } from '../../contexts/auth'
import { useUsers } from '../../contexts/users'
import { EditIcon, CircleIcon } from '@/shared/icons'
import { AccessKey, AddUserModal, ConnectionHistory, EditCompanyModal } from './elements'
import { Button } from '@/shared/components/core/Button'

import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "@/shared/components/DataTable";

import { StaticRow, SectionWithTitle } from '@/components/elements'
import EditPermissionModal from '../../components/EditPermissionModal'
import ConfirmAlert from '../../components/ConfirmDeleteAlert'
import axios from 'axios'
import FavoriteMind from '../../components/FavoriteMind'
import UpdateMind from '../../components/UpdateMind'
import ModalSkeleton from '../../shared/components/ModalSkeleton'
import { updateUser } from '../../api/users'
import { MANUFACTURER, STANDARD_ERROR_MSG } from '../../consts'
import { usersTableCommonColumnProps, machinesTableCommonColumnProps } from '../../components/elements'
import { useIsMobile, useData, useIsTablet } from '../../hooks'
import { useTranslation } from 'react-i18next'
import { map, min } from 'lodash'

const Mind = (props) => {
  const [mind, setMind] = useState()
  const [filteredMachines, setFilteredMachines] = useState([])
  const [searchWordMachines, setSearchWordMachines] = useState('')
  const [isFirstCallRefetchUpdate, setIsFirstCallRefetchUpdate] = useState(true)
  const [connectionHistoryDatasets, setConnectionHistoryDatasets] = useState([])
  const { user, fetchUser } = useAuth()
  const { minds, isFetching, refetch } = useMinds()
  const { id } = useParams()
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode } = useColorMode()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const { t } = useTranslation(['general', 'minds'])

  const { data: updateData, isFetching: isFetchingUpdate, refetch: refetchUpdate } = useData({
    url: '/api/read_update_mind_cache/',
    // params: {id:mind?.id, serial:mind?.serial}
    params: { id: mind?.id, serial: mind?.serial },
    skipFetch: !mind
  })

  useEffect(() => {
    if (isFetching === false) {
      const result = minds.filter(m => m.id == id)
      if (result.length > 0) {
        setMind(result[0])
      }
    }
  }, [isFetching]);

  useEffect(() => {
    // updateData.length because init state is []
    if (updateData.length === 0) {
      if (isFirstCallRefetchUpdate) {
        setIsFirstCallRefetchUpdate(false);
        refetchUpdate();
      }
    }
    else if (updateData.in_progress || updateData.queued) {
      const timerId = setTimeout(() => refetchUpdate(), 2000)
      return () => clearTimeout(timerId)
    }
  }, [isFetchingUpdate, mind])

  const { data: connectionData, isFetching: isFetchingConnectionUpdate, refetch: refetchConnectionUpdate } = useData({
    url: `/api/minds/${mind?.id}/connection_status/`,
    skipFetch: !mind,
    refetchInterval: 60000
  });

  useEffect(() => {
    if (isFetchingConnectionUpdate === false) {
      const onlineData = connectionData.map((item) => {
        return item.status === 2 ? 1 : 0
      })
      const disconnectedData = connectionData.map((item) => {
        return item.status === 1 ? 1 : 0
      })
      const offlineData = connectionData.map((item) => {
        return item.status === 0 ? 1 : 0
      })

      setConnectionHistoryDatasets([
        {
          label: t('minds:online', 'Online'),
          data: onlineData,
          backgroundColor: theme.colors.frigel.light.status.online,
        },
        {
          label: t('minds:disconnected', 'Disconnected'),
          data: disconnectedData,
          backgroundColor: theme.colors.frigel.light.status.warning,
        },
        {
          label: t('minds:offline', 'Offline'),
          data: offlineData,
          backgroundColor: theme.colors.frigel.light.status.alarm,
        }
      ])
    }
  }, [isFetchingConnectionUpdate, connectionData]);

  const canViewUsers = user.level.id >= MANAGER;

  const { users, refetch: refetchUsers } = useUsers()
  let userTableColumns = usersTableCommonColumnProps(isMobile, isTablet, t)
  delete userTableColumns['level.name']
  delete userTableColumns.company

  const columnsProps = {
    ...userTableColumns,
    'mind_permission.name': { text: t('general:permission', 'permission').toCapitalCase() },
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => <EditPermissionModal permission={row.mind_permission}
        limitPermission={row.level}
        onSubmit={permission => {
          return axios.post('/api/permissions/', {
            level: +permission,
            userprofile: +row.profile_id,
            mind: +id
          }).then(resp => {
            // TODO: Add some delay as refetchUsers is slow.
            toast({
              status: 'success',
              title: t('minds:user-access-changed', 'User permission changed successfully!'),
              position: TOAST_POSITION,
              isClosable: true,
            })
            refetchUsers()
          })

        }}
      />
    },
    delete: {
      text: '',
      isDummyField: true,
      formatter: (cell, row) => <ConfirmAlert
        title={t('minds:remove-user-access', 'remove user access').toCapitalCase()}
        message={t('minds:confirm-remove-user-access', 'Are you sure to remove access for this user?')}
        confirmBtnText={t('general:remove', 'remove').toCapitalCase()}
        onConfirm={() => {
          return axios.post('/api/permissions/delete/', {
            userprofile: +row.profile_id,
            mind: +id
          }).then(resp => {
            toast({
              status: 'success',
              title: t('minds:removed-user-access', 'User permission removed successfully!'),
              position: TOAST_POSITION,
              isClosable: true,
            })
            refetchUsers()
          })
        }} />
    }
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  const { data: machines } = useData({
    url: `/api/minds/${id}/machines/`,
    params: { 'sender': 'info' }
  });


  useEffect(() => {
    setFilteredMachines(machines.filter((machine) => {
      return machine.serial.toLowerCase().includes(searchWordMachines) ||
        machine.machine_model.toLowerCase().includes(searchWordMachines) ||
        machine.name.toLowerCase().includes(searchWordMachines) ||
        machine.status?.general_status?.toLowerCase().includes(searchWordMachines)
    }
    ))
  }, [machines, searchWordMachines]);
  
  let machineTableColumns = machinesTableCommonColumnProps(isMobile, isTablet, t, colorMode)
  const machineColumns = getUniformedColumns(Object.keys(machineTableColumns), machineTableColumns)

  if (!mind) {
    return <FullPageSpinner />
  }
  const {
    mid, serial, frigel_branch, mind_url,
    company_name, company_country, company_address,
    company_sector, company_latitude, company_longitude, company_serial,
    company_id, vpn, app_version
  } = mind;

  const user_mind_level = user.perms ? user.perms[mind.id] : USER
  const sectionPadding = 2;

  const mindRelatedUsers = []
  const mindNotRelatedUsers = []
  users.forEach(u => {
    // `id` is mind id
    if (u.perms[+id] && u.perms[+id].id === ADMIN) {
      return
    }

    if (u.minds_related.includes(+id)) {
      u.mind_permission = u.perms[+id]
      mindRelatedUsers.push(u)
    } else {
      mindNotRelatedUsers.push(u)
    }
  })

  const { preferences: { customizeMinds = {} } } = user

  const handleCustomize = (e) => {
    e.preventDefault();
    const nickname = e.target.elements.nickname.value;
    if (customizeMinds.hasOwnProperty(mind.id)) {
      customizeMinds[mind.id].nickname = nickname;
    } else {
      customizeMinds[mind.id] = { nickname };
    }
    updateUser(user.profile_id, {
      preferences: {
        ...user.preferences,
        customizeMinds,
      },
    })
      .then(() => {
        fetchUser();
        toast({
          status: "success",
          title: t('minds:nickname-changed', "Nickname changed successfully!"),
          position: TOAST_POSITION,
          isClosable: true,
        });
        onClose();
      })
      .catch((reason) => {
        toast({
          status: "error",
          description: STANDARD_ERROR_MSG,
          position: TOAST_POSITION,
          isClosable: true,
        });
        console.error(reason);
      });
  };

  const nickname = (customizeMinds[mind.id] && customizeMinds[mind.id].nickname) || ''

  const getPhysicalPort = (iface) => {
    if (iface === 'eth0' || iface === 'enp1s0') {
      return ' LAN 1';
    }
    else if (iface === 'eth1' || iface === 'eno1' || iface === 'enp0s31f6') {
      return 'LAN 2';
    }
    else {
      return '';
    };
  };

  return (
    <EditLayout>
      <SectionWithTitle
        title='MiND'
        rightHeadingChild={
          <FavoriteMind mindId={mind.id} />
        }
      >
        <Box px={sectionPadding}>
          <StaticRow label={t('general:serial', 'serial').toCapitalCase()} content={serial} />
          <StaticRow label={t('general:nickname', "nickname").toCapitalCase()} content={
            <HStack spacing={3}> <div>{nickname ? nickname : '-'}</div> <EditIcon cursor="pointer" onClick={onOpen} />
            </HStack>}
          />
          <StaticRow label={t('general:app-version', "app version").toCapitalCase()} content={
            <VStack spacing={0} align='stretch'>
              <HStack spacing={3}>
                <div>{app_version}</div>
                {
                  user.level.id >= MANUFACTURER &&
                  <UpdateMind mind={mind} colorMode={colorMode} updateData={updateData} refetchUpdateData={refetchUpdate} logOnly={true} />
                }
              </HStack>
              {
                user.level.id >= MANUFACTURER &&
                <UpdateMind mind={mind} colorMode={colorMode} updateData={updateData} refetchUpdateData={refetchUpdate} logOnly={false} />
              }
            </VStack>

          } />
          <StaticRow label={t('general:branch', "branch").toCapitalCase()} content={frigel_branch} />
          {canViewUsers && <>
            <StaticRow label={'VPN'} content={
              <VStack align="flex-start">
                {mind.vpn_info.map((vpn_c) => {
                  return (
                    <Link key={mind.id} color="blue" target="_blank" onClick={() => handleMindLogin(mind.mid, mind.serial, vpn_c.host_name, toast, t)}>
                      {vpn_c.name}
                    </Link>
                  )
                }
                )}
              </VStack>
            } />
            <StaticRow label={'hardware'.toCapitalCase()} content={mind.hw_info} />
          </>}
          <StaticRow
            label="MID"
            content={
              <Box as="span">
                <Link color="blue" target="_blank" onClick={() => {
                  navigator.clipboard.writeText(mid)
                  toast({
                    title: t('minds:mid-copied', 'MID copied to clipboard').toCapitalCase(),
                    position: TOAST_POSITION,
                    isClosable: true,
                  })
                }
                }>
                  {mid}
                </Link>
                {/* Add icon */}
                <i
                  style={{ marginLeft: "2%" }}
                  className="fa fa-external-link"
                ></i>
              </Box>
            }
          />
          <StaticRow label={t('general:access-token', 'access token').toCapitalCase()} content={
            <AccessKey mid={mid} />
          }
          />
          <HStack justifyContent='center'>
            <Box width='100%' maxWidth='600px' height='100px'>
              <ConnectionHistory datasets={connectionHistoryDatasets}
              />
            </Box>
          </HStack>

        </Box>
      </SectionWithTitle>

      <SectionWithTitle
        title={t('general:machines', 'machines').toCapitalCase().toCapitalCase()}
        rightHeadingChild={
          <Box justifyContent="flex-end">
            <SearchBar searchProps={{
              onSearch: (searchWord) => {
                setSearchWordMachines(searchWord.toLowerCase())
              }
            }} />
          </Box>
        }
      >
        <Box px={sectionPadding}>
          <DataTable
            data={filteredMachines}
            columns={machineColumns}
            noDataIndication={t('general:NoData', "No data to display")}
            noPagination={filteredMachines.length < 11}
            topPadding={false}
          />
        </Box>
      </SectionWithTitle>


      <Accordion w='full' allowMultiple >
        <AccordionItem borderTopStyle='none'>
          <AccordionButton >
            <Box as='section' w='full' >
              <Flex justify='space-between' >
                <Heading as='h2' ml={-4} size='md'>{t('general:company', "company").toCapitalCase()}</Heading>
                <AccordionIcon justify='space-between' mr={-4} />
              </Flex>
            </Box>
          </AccordionButton>
          <AccordionPanel w='full' ml={-4} justify='space-between' pb={4}>
            <VStack spacing={3} align='stretch' >
              <Heading as='h2' size='sm' mt={5} >
                <HStack spacing={3} >
                  <div>{company_name}</div>
                  {
                    canViewUsers && (
                      <EditCompanyModal
                        currentCompany={{
                          name: company_name,
                          address: company_address,
                          latitude: company_latitude,
                          longitude: company_longitude,
                          country: company_country,
                          sector: company_sector,
                          id: company_id,
                          serial: company_serial
                        }}
                        onSubmit={(company) => {
                          return axios
                            .put(`/api/minds/${id}/`, { id, company: company.url })
                            .then((resp) => {
                              toast({
                                title: t('minds:company-changed', "Company changed successfully!"),
                                position: TOAST_POSITION,
                                isClosable: true,
                              });
                              refetch();
                            });
                        }}
                      />
                    )
                  }
                </HStack>
              </Heading>
              <Box px={sectionPadding}>
                <StaticRow label={t('general:address', 'address').toCapitalCase()} content={<Box w='70%'>{company_address}</Box>} />
                <StaticRow label={t('general:latitude', 'latitude').toCapitalCase()} content={company_latitude} />
                <StaticRow label={t('general:longitude', 'longitude').toCapitalCase()} content={company_longitude} />
                <StaticRow label={t('general:country', 'country').toCapitalCase()} content={company_country.name} />
                <StaticRow label={t('general:sector', 'sector').toCapitalCase()} content={company_sector.name} />
              </Box>
            </VStack>
          </AccordionPanel>
        </AccordionItem>

        <Divider mb='6' hr='6' />

        {user.level.id === ADMIN && (<><AccordionItem borderTopStyle='none'>
          <AccordionButton >
            <Box as='section' w='full' >
              <Flex justify='space-between' >
                <Heading as='h2' ml={-4} size='md'>{t('general:networkInfo', 'network info').toCapitalCase()}</Heading>
                <AccordionIcon justify='space-between' mr={-4} />
              </Flex>
            </Box>
          </AccordionButton>

          <AccordionPanel w='full' ml={-4} justify='space-between' pb={4}>
            <Box px={sectionPadding}>
              {map(mind.network_info, (iface) => {
                const _physicalport = iface.lan ? iface.lan : getPhysicalPort(iface.iface);

                const header = `${t('general:network-interface', 'Interface')}
                                      ${iface.iface}`;
                return (
                  <div key={iface.iface}>
                    <VStack spacing={3} align='stretch' >
                      <Heading as='h2' size='sm' mt={5} >{header}</Heading>
                      <Box >
                        <StaticRow
                          label={t('general:Name', 'Name')}
                          content={iface.iface ? `${iface.iface} (${_physicalport})` : ''}
                        />
                        <StaticRow
                          label={t('general:network-conn-type', 'Connection Type')}
                          content={iface.conn_type === 'dhcp' ? 'DHCP/Automatic' : 'Static'}
                        />
                        <StaticRow
                          label={t('general:network-ip-address', 'Ip Address')}
                          content={iface.addr ? iface.addr : ''}
                        />
                        <StaticRow
                          label={t('general:network-netmask', 'Netmask')}
                          content={iface.netmask ? iface.netmask : ''}
                        />
                        <StaticRow label={t('general:network-gateway', 'Gateway')} content={iface.gw ? iface.gw : ''}
                          props={{ pb: 3 }}
                        />
                      </Box>
                    </VStack>
                  </div>

                )
              })
              }
            </Box>
          </AccordionPanel>
        </AccordionItem>
          <Divider mb='6' hr='6' />
          <AccordionItem borderTopStyle='none'>
            <AccordionButton >
              <Box as='section' w='full' >
                <Flex justify='space-between' >
                  <Heading as='h2' ml={-4} size='md'>{t('general:vpnInfo', 'vpn info').toCapitalCase()}</Heading>
                  <AccordionIcon justify='space-between' mr={-4} />
                </Flex>
              </Box>
            </AccordionButton>

            <AccordionPanel w='full' ml={-4} justify='space-between'>
              <Box px={sectionPadding}>
                {map(mind.vpn_info, vpn => {
                  const header = `${t('general:network-vpn', 'VPN')}
                                      ${vpn.name}`;

                  let mindServiceStatus = null
                  let mindVPNConnection = null
                  if (Object.keys(mind.mind_vpn_info).length !== 0) {
                    const time = mind.mind_vpn_info[vpn.name]?.time?.udp
                    const port = mind.mind_vpn_info[vpn.name]?.reachable_port
                    mindServiceStatus = `${time.day} days,  ${time.hour} hours,  ${time.minute} minutes`
                    mindVPNConnection = `Udp: ${port.udp == 'true' ? 'open' : 'close'}  |  Tcp: ${port.tcp == 'true' ? 'open' : 'close'} - ${ mind.mind_vpn_info[vpn.name]?.ping}ms`
                  }
                  return (
                    <div key={vpn.name}>
                      <VStack spacing={3} align='stretch' >
                        <Heading as='h2' size='sm' mt={5} >{header}</Heading>
                        <Box >
                          <StaticRow
                            label={t('general:host-name', 'Host name')}
                            content={vpn.host_name}
                          />
                          <StaticRow
                            label={t('general:network-conn-status', 'Connection status')}
                            content={vpn.status ? `Connected - ${vpn.ping}ms` : 'Disconnected'}
                          />
                          <StaticRow
                            label={t('general:network-ip-address', 'Ip address')}
                            content={vpn.address}
                          />
                          {Object.keys(mind.mind_vpn_info).length !== 0 && 
                          <>
                          <StaticRow
                            label={'Application service status'}
                            content={ 
                            <HStack spacing={3}>
                            <CircleIcon fontSize="xl" color={mind.mind_vpn_info[vpn.name]?.status_service?.udp == 'running' ? `frigel.${colorMode}.status.online` :`frigel.${colorMode}.status.offline`} />
                            <Box>{mindServiceStatus}</Box>
                          </HStack>}
                            />
                          <StaticRow
                            label={'Mind to VPN connection'}
                            content={mindVPNConnection }
                          />
                          </>}
                        </Box>
                      </VStack>
                    </div>
                  )
                })
                }
              </Box>
            </AccordionPanel>
          </AccordionItem></>)}
      </Accordion>
      <Box mb={16} />


      {canViewUsers && (
        <>
          <SectionWithTitle title={t('general:users', "users").toCapitalCase()}>
            <Box px={sectionPadding}>
              <DataTable
                data={mindRelatedUsers}
                columns={columns}
                toolbar={(props) => (
                  <Flex justifyContent="space-between">
                    <AddUserModal
                      users={mindNotRelatedUsers}
                      onSubmit={(data) => {
                        return axios
                          .post("/api/permissions/", {
                            level: +data.permission,
                            userprofile: +data.selectedUser.profile_id,
                            mind: +id,
                          })
                          .then((resp) => {
                            // TODO: Add some delay as refetchUsers is slow.
                            toast({
                              title: t('minds:user-added-successfully', "User added successfully!"),
                              position: TOAST_POSITION,
                              isClosable: true,
                            });
                            refetchUsers();
                          });
                      }}
                    />
                    <Box justifyContent="flex-end">
                      <SearchBar searchProps={props.searchProps} />
                    </Box>
                  </Flex>
                )}
              />
            </Box>
          </SectionWithTitle>
        </>
      )}
      <ModalSkeleton isOpen={isOpen} title={t('general:customize', "customize").toCapitalCase()} onClose={onClose}>
        <ModalBody as="form" id="customizeForm" onSubmit={handleCustomize}>
          <FormControl>
            <FormLabel htmlFor="nickname">{t('general:nickname', 'nickname').toCapitalCase()}</FormLabel>
            <Input
              id="nickname"
              placeholder={t('general:nickname', 'nickname')}
              defaultValue={nickname}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack}>
          <Button onClick={onClose} variant='secondary'>
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            type="submit"
            form="customizeForm"
            variant='primary'
          >
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </EditLayout>
  );
}

export default Mind;