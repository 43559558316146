import React from 'react'
import { useData } from "@/hooks";
import { USERS_URL } from '../api/urls';

const UsersContext = React.createContext()

function UsersProvider({children, ...props}) {

  const {data, isFetching, isError, refetch } = useData({
    url: USERS_URL
  })

  return (<UsersContext.Provider value={{
    users: data, isFetching, isError, refetch
    }} {...props}>
    {children}
  </UsersContext.Provider>
  )
}


function useUsers() {
  const context = React.useContext(UsersContext)
  if (context === undefined) {
    throw new Error(`useMinds must be used within a UsersProvider`)
  }
  return context
}

export {UsersProvider, useUsers}