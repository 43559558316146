import React from 'react'
import { Box, Flex, Heading, useColorMode, Image } from "@chakra-ui/react"
import {Button} from '@/shared/components/core/Button'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from './shared/icons'
import { useTranslation } from 'react-i18next'
import { CDN_PATH } from "./consts";


const Header = ({heading}) => {
  return (
    <Flex justifyContent='space-between'>
      <Heading as='h1' mb={12}>{heading}</Heading>
    </Flex>
  )
}
export const  MainLayout = ({heading, children, mt}) => {
  const {colorMode} = useColorMode()

  return (
    <Box as='main' px={{base: 4, md: 16}} py={10} mt={mt}>
      {heading && (
        <Flex justify='space-between'>
          <Header heading={heading}/>
          <Image mt={1} alignSelf='start' width='120px' src={`${CDN_PATH}/statics/images/frigel-full-logo-${colorMode}.svg`}/>
        </Flex>
      )}
      {children}
    </Box>
  // Add footer if needed
  )
}

export const  EditLayout = ({children, onBack}) => {

  const history = useHistory()
  const {colorMode} = useColorMode()
  const {t} = useTranslation(['general'])

  return (
    <Box as='main' px={{base: 4, md: 16, lg: 56}} py={10} >
      <Box mb={10}>
        <Button size='xs' variant='secondary'
          leftIcon={<ChevronLeftIcon fontSize='lg' mr={-2}/>}
          onClick={() => onBack ? onBack : history.goBack()}>
            {t('general:Back', 'Back')}
        </Button>
      </Box>
      {children}
    </Box>
    // Add footer if needed
  )
}