import React, { useState } from "react";
import FullPageSpinner from "@/shared/components/FullPageSpinner";
import DataTable, {
  getUniformedColumns,
  SearchBar,
} from "../../shared/components/DataTable";
import {
  EditIcon,
  PlusIcon,
  AddUserIcon,
  XIcon,
  CheckIcon,
} from "../../shared/icons";
import {
  Box,
  Button,
  Flex,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
  IconButton,
  ModalBody, ModalFooter,
  FormControl, FormLabel,
  Center,
  Alert, AlertTitle
} from "@chakra-ui/react";
import { default as ReactSelect } from "react-select";
import { useData, useIsMobile } from "../../hooks";
import { useTranslation } from "react-i18next";
import ResponsiveText from '../../shared/components/ResponsiveText'
import { TOAST_POSITION, STANDARD_ERROR_MSG, VPN_HOST_MAX, FIRST_VPN_HOST } from "../../consts";
import ModalSkeleton from '../../shared/components/ModalSkeleton'
import reactSelectDarkColors from '../../shared/theme'
import axios from "axios";
import { activeFormatter } from "../../components/elements";



export const VpnsTable = (props) => {
  const { onEditClick } = props;
  const { data: vpns, isFetching } = useData({
    url: '/api/vpns_list/'
  })

  const { t } = useTranslation(['general', 'vpn'])
  const isMobile = useIsMobile();
  const { colorMode } = useColorMode();


  if (isFetching) {
    return <FullPageSpinner />;
  }
  const columnsProps = {
    id: { hidden: true, isKey: true },
    name: {
      text: t('general:name', 'name').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    udp: {
      text: 'UDP',
      headerAlign: "left",
      align: "left",
    },
    tcp: {
      text: 'TCP',
      headerAlign: "left",
      align: "left",
      hidden: isMobile,
    },
    n_ip_free: {
      text: t('vpn:ip_free', 'free IP').toCapitalCase(),
      headerAlign: "left",
      align: "left",
      formatter: (cell, row) => {
        return <Text {...(row.n_ip_free > 0 ? { textColor: 'green' } : {})}>{cell}</Text>
      }
    },
    n_ip_busy: {
      text: t('vpn:ip_occ', 'busy IP').toCapitalCase(),
      headerAlign: "left",
      align: "left",
    },
    ping: {
      text: t('vpn:ping', 'ping').toCapitalCase(),
      headerAlign: "left",
      align: "left",
      hidden: isMobile,
    },
    reachable: {
      text: t('general:reachable', 'reachable').toCapitalCase(),
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        return activeFormatter(cell, row, colorMode)
      }
    },
    backup: {
      text: t('vpn:is_backup', 'backup').toCapitalCase(),
      headerAlign: "center",
      align: "center",
      formatter: (cell, row) => {
        if (!row) {
          return cell;
        }

        if (cell) {
          return <CheckIcon fontSize='xl' />
        } else {
          return <XIcon fontSize='xl' />
        }
      },
      hidden: isMobile
    },
    edit: {
      text: t('general:edit', 'edit').toCapitalCase(),
      isDummyField: true,
      formatter: (cell, row) => (
        <EditIcon
          cursor="pointer"
          fontSize={{ base: "xs", md: "md" }}
          onClick={() => {
            onEditClick(row);
          }}
        />
      ),
    },
  };

  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);

  return (
    <DataTable
      data={vpns}
      columns={columns}
      toolbar={(props) => (
        <Flex justify="space-between">
          <Box width="30%">
            <SearchBar searchProps={props.searchProps} />
          </Box>
        </Flex>
      )}
      dataTableProps={{
        sizePerPage: 10,
        noPagination: true,
      }}
    />
  );
};




export const AddModal = ({ selectOptions, onSubmit, icon = AddUserIcon, disabled }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [selected, setSelected] = useState();
  const toast = useToast()
  const { t } = useTranslation(['general'])


  return (
    <>
      <IconButton
        icon={icon}
        size="sm"
        fontSize="lg"
        isDisabled={disabled}
        onClick={() => onOpen()}
        variant='secondary'
      />
      <ModalSkeleton isOpen={isOpen} onClose={onClose} title={`${t('general:add', 'add').toCapitalCase()} MiND`}>
        <ModalBody>
          <Box
            as="form"
            w="full"
            id='add_mind_to_vpn'
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit({ selected })
                .then(() => onClose())
                .catch(reason => {
                  toast({
                    status: 'error',
                    description: STANDARD_ERROR_MSG,
                    position: TOAST_POSITION
                  })
                  console.error(reason)
                })
            }}
          >
            <FormControl w="full" id="minds">
              <FormLabel>MiND</FormLabel>
              <ReactSelect
                name="minds"
                theme={colorMode === 'dark' && { colors: reactSelectDarkColors }}
                isSearchable={true}
                isClearable={true}
                onChange={(s) => {
                  s ? setSelected(s.selectValue) : setSelected();
                }}
                options={selectOptions}
              />
            </FormControl>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant='secondary' mr={2}>
            {t('general:Cancel', 'Cancel')}
          </Button>
          <Button
            alignSelf="flex-end"
            variant='primary' type="submit" form='add_mind_to_vpn'>
            {t('general:Submit', 'Submit')}
          </Button>
        </ModalFooter>
      </ModalSkeleton>
    </>
  );
};


export const MindsTable = ({ minds, minds_not_vpn, vpn, user, isFetching, refetch }) => {
  const isMobile = useIsMobile()
  const { t } = useTranslation(['general'])
  const toast = useToast()
  const columnsProps = {
    id: { hidden: true },
    mid: { hidden: true, isKey: true },
    nickname: { hidden: true },
    serial: {
      sort: true,
      text: isMobile ? t('general:serial-no', 'Serial No.') : t('general:serial-number', 'Serial number'),
      align: 'left',
      headerAlign: 'left',
    },
    company: {
      sort: false, text: t('general:company', 'company').toCapitalCase(),
      align: 'left',
      headerAlign: 'left',
      formatter: (col, row) => {

        return (
          !isMobile ? (
            <ResponsiveText compress={'30ch'} tooltipProps={{ placement: 'bottom-start' }} >
              {col}
            </ResponsiveText>
          ) : (
            <ResponsiveText>
              {col}
            </ResponsiveText>
          )
        )
      }
    },
    frigel_branch: {
      text: t('general:branch', 'branch').toCapitalCase(),
      hidden: isMobile
    },
    host: {
      text: 'Host',
      headerAlign: "left",
      align: "left",
    },
  };
  const columns = getUniformedColumns(Object.keys(columnsProps), columnsProps);
  minds.sort((mind1, mind2) => mind1.host - mind2.host)
  let new_host = FIRST_VPN_HOST
  for (var index in minds) {
    if (minds[index].host <= new_host) {
      new_host += 1
    }
    else {
      break
    }
  }

  return (
    <DataTable
      data={minds}
      columns={columns}
      toolbar={(props) => (
        <>
          <Flex justify="space-between">
            <Box width="30%">
              <SearchBar searchProps={props.searchProps} />
            </Box>
            <AddModal
              icon={<PlusIcon />}
              disabled={new_host > VPN_HOST_MAX}
              selectOptions={minds_not_vpn.map(m => ({
                value: m.id,
                label: `[${m.serial}] ${m.company}`,
                selectValue: m
              }))}
              onSubmit={data => {
                return axios.post('/api/vpnclient/', {
                  mind: +data.selected.id,
                  userprofile: +user.profile_id,
                  vpn: +vpn,
                  udp_host: +new_host,
                  tcp_host: +new_host
                }).then(resp => {
                  toast({
                    title: t('users:mind-added', 'MiND added successfully!'),
                    description: t('users:hostOfMind', 'MiND added with VPN host: ') + new_host,
                    position: TOAST_POSITION
                  })
                  refetch()
                })
              }}
            />
          </Flex>
          {new_host > VPN_HOST_MAX &&
            <Alert status='info' mt={3} borderBottomRadius={5}>
              <AlertTitle>{'this VPN does not have IPs available'}</AlertTitle>
            </Alert>
          }
        </>
      )}
      noDataIndication={isFetching ? <FullPageSpinner /> : <Center>{t('vpn: not_related_minds', 'Vpn does not have access to any MiND')}</Center>}
      dataTableProps={{
        sizePerPage: 10,
      }}
    />
  );
};
